import React, { ReactNode } from 'react';
import { AuthProvider } from './AuthContext';
import { BattleProvider } from './BattleContext';
import { GlobalProvider } from './GlobalContext';

interface AppProvidersProps {
  children: ReactNode;
}

export const AppProviders: React.FC<AppProvidersProps> = ({ children }) => {
  return (
    <AuthProvider>
      <BattleProvider>
        <GlobalProvider>
          {children}
        </GlobalProvider>
      </BattleProvider>
    </AuthProvider>
  );
};