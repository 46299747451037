import React, { createContext, useContext, useState, ReactNode } from 'react';
import { User, UserProfile } from '../types';

interface AuthContextProps {
  userId: string;
  setUserId: (userId: string) => void;
  userProfile: UserProfile | null;
  setUserProfile: (profile: UserProfile | null) => void;
  user: User | null;
  setUser: (user: User | null) => void;
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Starting with null values for better security
  // User will be initialized via useTelegram hook instead of directly from localStorage
  const [userId, setUserId] = useState<string>('');
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [user, setUser] = useState<User | null>(null);

  // A user is authenticated only if we have both userId and userProfile,
  // and the user object has been properly set
  const isAuthenticated = !!userId && !!userProfile && !!user;

  return (
    <AuthContext.Provider 
      value={{ 
        userId, 
        setUserId, 
        userProfile, 
        setUserProfile, 
        user, 
        setUser, 
        isAuthenticated 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};