// WebSocket service for battle system
// Real WebSocket implementation

// Types for WebSocket messages
export interface WebSocketMessage {
  type: string;
  payload: any;
}

// Events that can be sent from the server
export type ServerEventType = 
  | 'battle_start'
  | 'opponent_action'
  | 'turn_result'
  | 'battle_end'
  | 'player_joined'
  | 'player_left'
  | 'countdown';

// Events that can be sent from the client
export type ClientEventType = 
  | 'submit_action'
  | 'join_battle'
  | 'leave_battle'
  | 'ready';

// Type guard for server events
const isServerEvent = (type: string): type is ServerEventType => {
  return [
    'battle_start',
    'opponent_action',
    'turn_result',
    'battle_end',
    'player_joined',
    'player_left',
    'countdown'
  ].includes(type);
};

// WebSocket manager for battles
class BattleWebSocketManager {
  private static instance: BattleWebSocketManager;
  private socket: WebSocket | null = null;
  private messageHandlers: { [key: string]: ((data: any) => void)[] } = {};
  private battleId: string | null = null;
  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 5;
  private reconnectDelay: number = 1000; // Start with 1-second delay
  private isReconnecting: boolean = false;
  private battleStates: Map<string, any> = new Map(); // Store battle states for reconnection

  // WebSocket server URL - using local development server
  private readonly WS_SERVER_URL = 'ws://localhost:8080';

  private constructor() {
    console.log('[WebSocketManager] Singleton instance created');
  } 

  public static getInstance(): BattleWebSocketManager {
    if (!BattleWebSocketManager.instance) {
      BattleWebSocketManager.instance = new BattleWebSocketManager();
    }
    return BattleWebSocketManager.instance;
  }

  public connect(battleId: string): void {
    // If we already have a connection to this battle, don't reconnect
    if (this.socket && this.socket.readyState === WebSocket.OPEN && this.battleId === battleId) {
      console.log(`[WebSocketManager] Already connected to battle ${battleId}`);
      return;
    }
    
    // If we have a connection to a different battle, disconnect first
    if (this.socket) {
      this.disconnect();
    }

    // Connect to the real WebSocket server
    this.battleId = battleId;
    const wsUrl = `${this.WS_SERVER_URL}/battles/${battleId}`;
    
    try {
      this.socket = new WebSocket(wsUrl);
      console.log(`[WebSocketManager] Connecting to ${wsUrl}`);
      
      // Set up event handlers
      this.socket.onopen = this.handleOpen.bind(this);
      this.socket.onmessage = this.handleMessage.bind(this);
      this.socket.onclose = this.handleClose.bind(this);
      this.socket.onerror = this.handleError.bind(this);
    } catch (error) {
      console.error('[WebSocketManager] Error creating WebSocket connection:', error);
      // Try to reconnect
      this.attemptReconnect();
    }
  }

  private attemptReconnect(): void {
    if (this.isReconnecting || this.reconnectAttempts >= this.maxReconnectAttempts) {
      if (this.reconnectAttempts >= this.maxReconnectAttempts) {
        console.error('[WebSocketManager] Max reconnect attempts reached, giving up');
      }
      return;
    }
    
    this.isReconnecting = true;
    this.reconnectAttempts++;
    
    const delay = this.reconnectDelay * Math.pow(2, this.reconnectAttempts - 1); // Exponential backoff
    console.log(`[WebSocketManager] Attempting to reconnect in ${delay}ms (attempt ${this.reconnectAttempts}/${this.maxReconnectAttempts})`);
    
    setTimeout(() => {
      if (this.battleId) {
        console.log(`[WebSocketManager] Reconnecting to battle ${this.battleId}...`);
        this.connect(this.battleId);
        this.isReconnecting = false;
      }
    }, delay);
  }

  public disconnect(): void {
    if (this.socket) {
      // Only close if not already closing or closed
      if (this.socket.readyState === WebSocket.OPEN || this.socket.readyState === WebSocket.CONNECTING) {
        this.socket.close(1000, 'Client disconnecting');
      }
      this.socket = null;
    }
    this.battleId = null;
    this.reconnectAttempts = 0;
    this.isReconnecting = false;
  }

  public send(type: ClientEventType, payload: any): void {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.error('[WebSocketManager] Cannot send message: WebSocket not open');
      return;
    }

    const message: WebSocketMessage = {
      type,
      payload
    };

    try {
      this.socket.send(JSON.stringify(message));
      console.log(`[WebSocketManager] Sent: ${JSON.stringify(message)}`);
    } catch (error) {
      console.error('[WebSocketManager] Error sending message:', error);
    }
  }

  public joinBattle(battleId: string): void {
    // If we're already in this battle, don't rejoin
    if (this.battleId === battleId && this.socket && this.socket.readyState === WebSocket.OPEN) {
      console.log(`[WebSocketManager] Already joined battle ${battleId}, skipping rejoin`);
      return;
    }
    
    this.battleId = battleId;
    
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      this.connect(battleId);
      return;
    }

    this.send('join_battle', { battleId });
  }

  public leaveBattle(): void {
    if (!this.battleId) return;
    
    this.send('leave_battle', { battleId: this.battleId });
    this.disconnect();
  }

  public submitActions(actions: any[]): void {
    if (!this.battleId) {
      console.error('[WebSocketManager] Cannot submit actions: not in a battle');
      return;
    }

    // Store actions in battle state for potential reconnection
    this.storeBattleState(this.battleId, { lastActions: actions });
    
    this.send('submit_action', {
      battleId: this.battleId,
      actions
    });
  }

  private storeBattleState(battleId: string, stateUpdate: any): void {
    const currentState = this.battleStates.get(battleId) || {};
    this.battleStates.set(battleId, { ...currentState, ...stateUpdate });
  }

  private getBattleState(battleId: string): any {
    return this.battleStates.get(battleId) || {};
  }

  public on(eventType: ServerEventType, handler: (data: any) => void): void {
    // Initialize array if it doesn't exist
    if (!this.messageHandlers[eventType]) {
      this.messageHandlers[eventType] = [];
    }
    
    // Prevent duplicate handlers by comparing function strings
    const handlerExists = this.messageHandlers[eventType].some(h => 
      h.toString() === handler.toString()
    );
    
    if (!handlerExists) {
      console.log(`[WebSocketManager] Adding handler for ${eventType}`);
      this.messageHandlers[eventType].push(handler);
    } else {
      console.log(`[WebSocketManager] Handler for ${eventType} already exists, not adding duplicate`);
    }
  }

  public off(eventType: ServerEventType, handler: (data: any) => void): void {
    if (!this.messageHandlers[eventType]) return;
    
    this.messageHandlers[eventType] = this.messageHandlers[eventType].filter(
      h => h !== handler
    );
  }

  private handleOpen(event: Event): void {
    console.log('[WebSocketManager] Connection established');
    this.reconnectAttempts = 0; // Reset reconnect counter on successful connection
    
    // Auto-join battle if we have a battleId
    if (this.battleId) {
      this.joinBattle(this.battleId);
    }
  }

  private handleMessage(event: MessageEvent): void {
    try {
      const message = JSON.parse(event.data) as WebSocketMessage;
      console.log(`[WebSocketManager] Received: ${JSON.stringify(message)}`);
      
      // Update battle state based on message
      if (message.payload && message.payload.battleId) {
        this.updateBattleStateFromMessage(message);
      }
      
      // Check if the message type is a valid server event
      if (isServerEvent(message.type)) {
        // Notify all registered handlers for this event type
        if (this.messageHandlers[message.type]) {
          this.messageHandlers[message.type].forEach(handler => {
            handler(message.payload);
          });
        }
      } else {
        console.warn(`[WebSocketManager] Unhandled message type: ${message.type}`);
      }
    } catch (error) {
      console.error('[WebSocketManager] Error parsing message:', error);
    }
  }

  private updateBattleStateFromMessage(message: WebSocketMessage): void {
    const { type, payload } = message;
    const battleId = payload.battleId;
    
    if (!battleId) return;
    
    const currentState = this.getBattleState(battleId) || {};
    
    switch (type) {
      case 'battle_start':
        this.storeBattleState(battleId, { 
          started: true,
          startTime: payload.startTime,
          turnTimeLimit: payload.turnTimeLimit || 60,
          players: payload.players
        });
        break;
      case 'countdown':
        // Store the turn start timestamp instead of remaining seconds
        this.storeBattleState(battleId, { 
          turnNumber: payload.turnNumber,
          turnStartTimestamp: payload.turnStartTimestamp || Date.now(),
          turnTimeLimit: payload.turnTimeLimit || 60
        });
        break;
      case 'turn_result':
        this.storeBattleState(battleId, { 
          lastTurnResult: payload,
          lastActions: [] // Clear pending actions after turn result
        });
        break;
      case 'opponent_action':
        this.storeBattleState(battleId, { 
          opponentActions: payload.actions
        });
        break;
      case 'battle_end':
        this.storeBattleState(battleId, { 
          ended: true,
          winner: payload.winner
        });
        break;
    }
  }

  private handleClose(event: CloseEvent): void {
    console.log(`[WebSocketManager] Connection closed: ${event.code} ${event.reason}`);
    
    // Attempt to reconnect if the close wasn't initiated by the client (code 1000)
    if (event.code !== 1000 && this.battleId) {
      this.attemptReconnect();
    } else {
      this.socket = null;
    }
  }

  private handleError(event: Event): void {
    console.error('[WebSocketManager] WebSocket error:', event);
    // The error event is usually followed by a close event,
    // which will handle reconnection attempts
  }
}

// Export a function to get the singleton instance
export const getBattleWebSocket = (): BattleWebSocketManager => {
  return BattleWebSocketManager.getInstance();
};