import React, { useState, useEffect, useRef, memo } from 'react';
import styles from '../../../style/CountdownBar.module.css';

interface CountdownBarProps {
  duration?: number; // Default duration in seconds
  startTimestamp?: number; // Timestamp in milliseconds of when the turn started
  turnTimeLimit?: number; // The time limit for the turn in seconds
  onComplete?: () => void;
}

export const CountdownBar: React.FC<CountdownBarProps> = memo(({
  duration = 60, // Default to a 60 second duration
  startTimestamp, // Start timestamp (can be undefined)
  turnTimeLimit, // Optional explicit time limit that overrides duration
  onComplete
}) => {
  // Calculate the effective duration and end time
  const effectiveDuration = useRef(turnTimeLimit || duration);
  const initialStartTime = useRef<number>(startTimestamp || Date.now());
  const endTimeRef = useRef<number>(initialStartTime.current + (effectiveDuration.current * 1000));
  
  // State for displayed time
  const [displayedTime, setDisplayedTime] = useState(effectiveDuration.current);
  const hasCalledOnCompleteRef = useRef(false);
  const animationFrameRef = useRef<number | null>(null);
  
  // Using requestAnimationFrame for smoother updates
  const updateTimer = () => {
    const now = Date.now();
    const remaining = Math.max(0, Math.ceil((endTimeRef.current - now) / 1000));
    
    // Only update state if time has changed to avoid unnecessary re-renders
    if (remaining !== displayedTime) {
      setDisplayedTime(remaining);
      
      // Call onComplete only once when timer reaches zero
      if (remaining <= 0 && onComplete && !hasCalledOnCompleteRef.current) {
        hasCalledOnCompleteRef.current = true;
        onComplete();
      }
    }
    
    // Continue animation if time remains
    if (remaining > 0) {
      animationFrameRef.current = requestAnimationFrame(updateTimer);
    }
  };

  // Recalculate timer when startTimestamp changes
  useEffect(() => {
    console.log('CountdownBar: startTimestamp changed or component mounted');
    
    // If we have a new startTimestamp, use it to recalculate
    if (startTimestamp) {
      initialStartTime.current = startTimestamp;
      effectiveDuration.current = turnTimeLimit || duration;
      endTimeRef.current = initialStartTime.current + (effectiveDuration.current * 1000);
      
      // Reset completion flag
      hasCalledOnCompleteRef.current = false;
    }
    
    // Always update displayed time
    const now = Date.now();
    const remaining = Math.max(0, Math.ceil((endTimeRef.current - now) / 1000));
    setDisplayedTime(remaining);
    
    // Start or restart animation loop
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    
    animationFrameRef.current = requestAnimationFrame(updateTimer);
    
    // Clean up on unmount or before startTimestamp changes
    return () => {
      console.log('CountdownBar: cleaning up timer');
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = null;
      }
    };
  // Note: We now include startTimestamp in dependencies to recalculate when it changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimestamp]);

  // Calculate progress percentage based on time left
  const progressPercentage = (displayedTime / effectiveDuration.current) * 100;
  
  // Determine the color of the progress bar based on time left
  let barColor = 'green';
  if (progressPercentage <= 33) {
    barColor = 'red';
  } else if (progressPercentage <= 66) {
    barColor = 'yellow';
  }

  return (
    <div className={styles.countdownContainer}>
      <div className={styles.countdownLabel}>
        Time left: {displayedTime}s
      </div>
      <div className={styles.countdownBarOuter}>
        <div 
          className={styles.countdownBarInner}
          style={{ 
            width: `${progressPercentage}%`, 
            backgroundColor: barColor 
          }}
        />
      </div>
    </div>
  );
});

export default memo(CountdownBar);