// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CountdownBar_countdownContainer__HCXRI {
  margin-bottom: 16px;
}

.CountdownBar_countdownLabel__bM8Dh {
  font-size: 14px;
  margin-bottom: 4px;
}

.CountdownBar_countdownBarOuter__WtijT {
  width: 100%;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.CountdownBar_countdownBarInner__wj7mP {
  height: 100%;
  transition: width 1s linear, background-color 0.3s ease;
}`, "",{"version":3,"sources":["webpack://./src/style/CountdownBar.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,0CAA0C;EAC1C,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,uDAAuD;AACzD","sourcesContent":[".countdownContainer {\n  margin-bottom: 16px;\n}\n\n.countdownLabel {\n  font-size: 14px;\n  margin-bottom: 4px;\n}\n\n.countdownBarOuter {\n  width: 100%;\n  height: 8px;\n  background-color: rgba(255, 255, 255, 0.1);\n  border-radius: 4px;\n  overflow: hidden;\n}\n\n.countdownBarInner {\n  height: 100%;\n  transition: width 1s linear, background-color 0.3s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"countdownContainer": `CountdownBar_countdownContainer__HCXRI`,
	"countdownLabel": `CountdownBar_countdownLabel__bM8Dh`,
	"countdownBarOuter": `CountdownBar_countdownBarOuter__WtijT`,
	"countdownBarInner": `CountdownBar_countdownBarInner__wj7mP`
};
export default ___CSS_LOADER_EXPORT___;
