import React from 'react';
import styled from 'styled-components';
import theme from '../../../style/theme';
import Button from '../../../components/ui/Button';
import { UserProfile } from '../../../types';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${theme.zIndex.modal};
`;

const ModalContainer = styled.div`
  background-color: ${theme.colors.background.secondary};
  border-radius: ${theme.borderRadius.lg};
  width: 90%;
  max-width: 400px;
  max-height: 90vh;
  overflow-y: auto;
  padding: ${theme.spacing.lg};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ModalHeader = styled.div`
  margin-bottom: ${theme.spacing.md};
`;

const Title = styled.h2`
  margin: 0;
  font-size: ${theme.fontSizes.lg};
  color: ${theme.colors.text.primary};
`;

const Description = styled.p`
  margin: ${theme.spacing.md} 0;
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.text.secondary};
`;

const Points = styled.div`
  font-size: ${theme.fontSizes.md};
  font-weight: bold;
  color: ${theme.colors.success};
  margin-bottom: ${theme.spacing.md};
`;

const AttributesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
  margin-bottom: ${theme.spacing.lg};
`;

const AttributeRow = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.sm};
  border-radius: ${theme.borderRadius.md};
  background-color: ${theme.colors.background.tertiary};
  
  &:hover {
    background-color: #395980; /* Slightly lighter than tertiary background */
  }
`;

const AttributeName = styled.span`
  flex: 1;
  font-size: ${theme.fontSizes.md};
`;

const AttributeValue = styled.span`
  font-weight: bold;
  width: 30px;
  text-align: center;
  margin-right: ${theme.spacing.md};
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing.sm};
  margin-top: ${theme.spacing.md};
`;

interface AttributeUpgradeModalProps {
  userProfile: UserProfile;
  onClose: () => void;
  onUpgrade: (attributeName: string) => void;
  isLoading: boolean;
}

const AttributeUpgradeModal: React.FC<AttributeUpgradeModalProps> = ({
  userProfile,
  onClose,
  onUpgrade,
  isLoading
}) => {
  const { attributes, updatePoints } = userProfile;
  
  const attributeNames = {
    str: 'Strength',
    dur: 'Durability',
    power: 'Power',
    speed: 'Speed'
  };

  return (
    <Overlay onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <Title>Upgrade Attributes</Title>
          <Description>
            Spend your attribute points to improve your character's abilities.
            Each point spent will increase the attribute by 1.
          </Description>
          <Points>Available Points: {updatePoints}</Points>
        </ModalHeader>
        
        <AttributesList>
          <AttributeRow>
            <AttributeName>{attributeNames.str}</AttributeName>
            <AttributeValue>{attributes.str}</AttributeValue>
            <Button 
              variant="success" 
              size="sm" 
              onClick={() => onUpgrade('str')}
              disabled={updatePoints <= 0 || isLoading}
            >
              Upgrade
            </Button>
          </AttributeRow>
          
          <AttributeRow>
            <AttributeName>{attributeNames.dur}</AttributeName>
            <AttributeValue>{attributes.dur}</AttributeValue>
            <Button 
              variant="success" 
              size="sm" 
              onClick={() => onUpgrade('dur')}
              disabled={updatePoints <= 0 || isLoading}
            >
              Upgrade
            </Button>
          </AttributeRow>
          
          <AttributeRow>
            <AttributeName>{attributeNames.power}</AttributeName>
            <AttributeValue>{attributes.power}</AttributeValue>
            <Button 
              variant="success" 
              size="sm" 
              onClick={() => onUpgrade('power')}
              disabled={updatePoints <= 0 || isLoading}
            >
              Upgrade
            </Button>
          </AttributeRow>
          
          <AttributeRow>
            <AttributeName>{attributeNames.speed}</AttributeName>
            <AttributeValue>{attributes.speed}</AttributeValue>
            <Button 
              variant="success" 
              size="sm" 
              onClick={() => onUpgrade('speed')}
              disabled={updatePoints <= 0 || isLoading}
            >
              Upgrade
            </Button>
          </AttributeRow>
        </AttributesList>
        
        <ButtonsContainer>
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
        </ButtonsContainer>
      </ModalContainer>
    </Overlay>
  );
};

export default AttributeUpgradeModal;