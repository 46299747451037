import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Home, Users, Zap, Award, User, ShoppingBag, Tool } from 'react-feather';

const NavContainer = styled.nav`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 480px;
  background-color: ${props => props.theme.colors.background.secondary};
  border-top: 1px solid ${props => props.theme.colors.border.default};
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${props => props.theme.spacing.xs} 0;
  z-index: ${props => props.theme.zIndex.fixed};
  padding-bottom: ${props => props.theme.spacing.xl};
`;

const NavItemsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`;

const NavItem = styled(Link)<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.spacing.xs} 0;
  color: ${props => props.active ? props.theme.colors.primary : props.theme.colors.text.secondary};
  transition: color ${props => props.theme.animation.fast};
  position: relative;
  min-width: 60px;
  
  svg {
    margin-bottom: ${props => props.theme.spacing.xs};
    transition: all ${props => props.theme.animation.fast};
  }
  
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
  
  &:active {
    transform: scale(0.9);
  }
`;

const NavLabel = styled.span<{ active: boolean }>`
  font-size: ${props => props.theme.fontSizes.xs};
  font-weight: ${props => props.active ? '500' : '400'};
  margin-top: 2px;
  white-space: nowrap;
`;

const ActiveIndicator = styled.div`
  position: absolute;
  top: -4px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.primary};
`;

const BottomNavigation: React.FC = () => {
  const location = useLocation();
  const pathname = location.pathname;
  
  const isActive = (path: string): boolean => {
    if (path === '/') {
      return pathname === path;
    }
    return pathname.startsWith(path);
  };
  
  const navItems = [
    { path: '/', label: 'Home', icon: <Home size={22} /> },
    { path: '/battles', label: 'Battles', icon: <Zap size={22} /> },
    { path: '/robots', label: 'Robots', icon: <Users size={22} /> },
    { path: '/market', label: 'Market', icon: <ShoppingBag size={22} /> },
    { path: '/workshop', label: 'Workshop', icon: <Tool size={22} /> },
    { path: '/leaderboard', label: 'Rankings', icon: <Award size={22} /> },
    { path: '/hero', label: 'Profile', icon: <User size={22} /> },
  ];
  
  return (
    <NavContainer>
      <NavItemsContainer>
        {navItems.map(item => (
          <NavItem 
            key={item.path} 
            to={item.path} 
            active={isActive(item.path)}
          >
            {isActive(item.path) && <ActiveIndicator />}
            {item.icon}
            <NavLabel active={isActive(item.path)}>{item.label}</NavLabel>
          </NavItem>
        ))}
      </NavItemsContainer>
    </NavContainer>
  );
};

export default BottomNavigation;