import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../../context/AuthContext';
import styles from '../../style/Hero.module.css';
import { LoadingIndicator } from '../../components/ui/LoadingScreen';
import HealthBar from '../../components/ui/HealthBar';
import AttributeUpgradeModal from './components/AttributeUpgradeModal';
import { api } from '../../services/api';

interface HeroProps {
  profileId: string;
}

export const Hero: React.FC<HeroProps> = ({ profileId }) => {
  const { userProfile, setUserProfile } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [isUpgrading, setIsUpgrading] = useState(false);

  useEffect(() => {
    // Simulate loading profile data
    const loadProfile = () => {
      setLoading(true);
      setTimeout(() => {
        if (!userProfile) {
          setError('User profile not found');
        }
        setLoading(false);
      }, 500);
    };

    loadProfile();
  }, [profileId, userProfile]);

  const handleUpgradeAttribute = async (attributeName: string) => {
    if (!userProfile || userProfile.updatePoints <= 0) return;
    
    setIsUpgrading(true);
    try {
      const response = await api.updateUserAttributes(attributeName);
      
      if (response.error) {
        setError(response.error);
      } else if (response.data) {
        // Update the user profile in the context
        setUserProfile(response.data);
      }
    } catch (err) {
      setError('Failed to upgrade attribute. Please try again.');
      console.error('Error upgrading attribute:', err);
    } finally {
      setIsUpgrading(false);
    }
  };

  const toggleUpgradeModal = () => {
    setShowUpgradeModal(prev => !prev);
  };

  // Use the shared LoadingIndicator component
  if (error || !userProfile) {
    return <div className={styles.error}>Error: {error || 'User profile not found'}</div>;
  }

  const { attributes, level, exp, win, lost, draw, HP, maxHP, updatePoints } = userProfile;

  return (
    <div className={styles.heroContainer}>
      {loading && <LoadingIndicator message="Loading hero data..." />}
      
      <div className={styles.heroHeader}>
        <h1 className={styles.heroTitle}>Your Hero</h1>
        <div className={styles.levelInfo}>
          <span className={styles.level}>Level {level}</span>
          <div className={styles.expBar}>
            <div 
              className={styles.expFill} 
              style={{ width: `${(exp / 100) * 100}%` }}
            ></div>
          </div>
          <span className={styles.expText}>{exp}/100 XP</span>
        </div>
      </div>

      <div className={styles.statsSection}>
        <div className={styles.healthSection}>
          <HealthBar currentHealth={HP} maxHealth={maxHP} label="Health" />
        </div>

        <div className={styles.attributesSection}>
          <h2 className={styles.sectionTitle}>Attributes</h2>
          <div className={styles.attributesList}>
            <div className={styles.attributeItem}>
              <span className={styles.attributeName}>Strength</span>
              <div className={styles.attributeBar}>
                <div 
                  className={styles.attributeFill}
                  style={{ width: `${(attributes.str / 20) * 100}%` }}
                ></div>
              </div>
              <span className={styles.attributeValue}>{attributes.str}</span>
            </div>
            <div className={styles.attributeItem}>
              <span className={styles.attributeName}>Durability</span>
              <div className={styles.attributeBar}>
                <div 
                  className={styles.attributeFill}
                  style={{ width: `${(attributes.dur / 20) * 100}%` }}
                ></div>
              </div>
              <span className={styles.attributeValue}>{attributes.dur}</span>
            </div>
            <div className={styles.attributeItem}>
              <span className={styles.attributeName}>Power</span>
              <div className={styles.attributeBar}>
                <div 
                  className={styles.attributeFill}
                  style={{ width: `${(attributes.power / 20) * 100}%` }}
                ></div>
              </div>
              <span className={styles.attributeValue}>{attributes.power}</span>
            </div>
            <div className={styles.attributeItem}>
              <span className={styles.attributeName}>Speed</span>
              <div className={styles.attributeBar}>
                <div 
                  className={styles.attributeFill}
                  style={{ width: `${(attributes.speed / 20) * 100}%` }}
                ></div>
              </div>
              <span className={styles.attributeValue}>{attributes.speed}</span>
            </div>
          </div>
          
          {updatePoints > 0 && (
            <div className={styles.updatePoints}>
              <p>You have {updatePoints} attribute points to spend!</p>
              <button 
                className={styles.upgradeButton}
                onClick={toggleUpgradeModal}
              >
                Upgrade Attributes
              </button>
            </div>
          )}
        </div>
      </div>
      
      <div className={styles.statsBox}>
        <h2 className={styles.sectionTitle}>Battle Statistics</h2>
        <div className={styles.statsGrid}>
          <div className={styles.statItem}>
            <span className={styles.statLabel}>Wins</span>
            <span className={styles.statValue}>{win}</span>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statLabel}>Losses</span>
            <span className={styles.statValue}>{lost}</span>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statLabel}>Draws</span>
            <span className={styles.statValue}>{draw}</span>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statLabel}>Win Rate</span>
            <span className={styles.statValue}>
              {win + lost + draw > 0 
                ? `${Math.round((win / (win + lost + draw)) * 100)}%` 
                : 'N/A'}
            </span>
          </div>
        </div>
      </div>
      
      {/* Attribute Upgrade Modal */}
      {showUpgradeModal && userProfile && (
        <AttributeUpgradeModal
          userProfile={userProfile}
          onClose={toggleUpgradeModal}
          onUpgrade={handleUpgradeAttribute}
          isLoading={isUpgrading}
        />
      )}
    </div>
  );
};

export default Hero;