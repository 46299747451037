import React, { createContext, useContext, useState, ReactNode } from 'react';
import { UserProfile } from '../types';

// This is a legacy context provider that's being phased out
// New components should use AuthContext and BattleContext instead
// This is maintained for backward compatibility with older components
interface GlobalContextProps {
  setUserId: (userId: string) => void;
  setUserProfile: (profile: any) => void;
  setUser: (user: any) => void;
  user: { id: number, name: string };
  userId: string;
  userProfile: any;
  inBattle: boolean;
  setInBattle: (inBattle: boolean) => void;
}

// Create the context
const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

// Custom hook to use the context
export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
};

// Provider component
export const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const storedUserProfile = localStorage.getItem("userProfile");
  const initialUserProfile = storedUserProfile 
    ? JSON.parse(storedUserProfile) 
    : { id: 0, level: 0, avatar: 'avatar1' };

  const [userId, setUserId] = useState<string>('');
  const [userProfile, setUserProfile] = useState<any>(initialUserProfile);
  const [user, setUser] = useState<{ id: number, name: string }>({ id: 0, name: '' });
  const [inBattle, setInBattle] = useState(false);

  return (
    <GlobalContext.Provider 
      value={{ 
        userId, 
        setUserId, 
        userProfile, 
        setUserProfile, 
        user, 
        setUser, 
        inBattle, 
        setInBattle 
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

// Re-export the types that were previously defined in the old GlobalContext
export interface LegacyUserProfileProps {
  id: number;
  level: number;
  avatar: string;
  win: number;
  lost: number;
  draw: number;
  attributes: {
    str: number;
    dur: number;
    power: number;
    speed: number;
  };
  updatePoints: number;
  exp: number;
  lastHpAction: number;
  maxHP: number;
  HP: number;
}

export interface HPProfileProps {
  lastHpAction: number;
  maxHP: number;
  HP: number;
}