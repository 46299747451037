import React, { useState, useEffect } from 'react';

interface TimeLeftProps {
    expirationTime: number; // Expiration time in seconds
}

const TimeLeft: React.FC<TimeLeftProps> = ({ expirationTime }) => {
    const [timeLeft, setTimeLeft] = useState(expirationTime - Math.floor(Date.now() / 1000));

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = Math.floor(Date.now() / 1000);
            setTimeLeft(expirationTime - now);
        }, 1000);

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, [expirationTime]);

    const formatTimeLeft = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}m ${secs}s`;
    };

    return <span>{formatTimeLeft(timeLeft)}</span>;
};

export default TimeLeft;