import React from 'react';
import { useGlobalContext } from './GlobalContext';
import styles from "./style/Battle.module.css";

interface HeroProps {
    profileId: string;
}

const Hero: React.FC<HeroProps> = ({ profileId }) => {
    const { userId } = useGlobalContext();
    let allowEditing = false;

    if (profileId === userId) {
        allowEditing = true;
    }

    if (allowEditing) {
        return (
            <div className={styles.battleContainer}>
                editing
            </div>
        )
    }

    return (
        <div className={styles.battleContainer}>
        </div>
    )
}

export default Hero;