import React, { useState, useEffect } from 'react';
import TimeLeft from "./TimeLeft";
import { useGlobalContext } from './GlobalContext';

const API_URL = process.env.REACT_APP_API_URL;
const Battles: React.FC = () => {
    const [battles, setBattles] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [refresh, setRefresh] = useState(false);
    const { userId } = useGlobalContext();

    useEffect(() => {
        const fetchBattles = async () => {
            const storedToken = localStorage.getItem('token');
            if (storedToken) {
                try {
                    const response = await fetch(API_URL + "/battles", {
                        method: 'GET',
                        headers: {
                            'Authorization': storedToken // Include the token in the Authorization header
                        }
                    });
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setBattles(data);
                } catch (error: any) {
                    setError(error.message || 'An error occurred');
                }
            }
        };

        fetchBattles();
    }, [refresh]);

    const acceptBattle = async (battleId:any) => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            const response = await fetch(API_URL + "/start-battle", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': storedToken // Include the token in the Authorization header
                },
                body: JSON.stringify({ 'battleId': battleId })
            });
            const data = await response.json();
            if (response.ok) {
                alert(`Battle ${data.battleId} started by user ${data.userId}`);
                // Optionally, you could update the state to remove the accepted battle
                setBattles(battles.filter(battle => battle.id !== battleId));
            } else {
                console.error('Failed to start battle:', data.message);
            }
        } else {
            console.error('No token found');
        }
    };

    const handleSubmitBattleRequest = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(API_URL + '/submit-battle', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token || ''
                },
                body: JSON.stringify({ })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setRefresh(prev => !prev);
        } catch (error) {
            console.error('Error submitting battle request:', error);
        }
    };


    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <h1>Battles</h1>
            <button onClick={handleSubmitBattleRequest}>Submit Battle Request</button>
            <ul>
                {battles.map((battle, index) => (
                    <li key={index}>
                        Battle ID: {battle.id} - Status: {battle.status} - TimeLeft: <TimeLeft expirationTime={battle.timeLeft} />
                        {battle.id !== userId && (
                            <button onClick={() => acceptBattle(battle.id)}>Accept</button>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Battles;