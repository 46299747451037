import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import theme from '../../style/theme';

interface LoadingScreenProps {
  message?: string;
  timeout?: number; // timeout in ms before showing "Taking longer than expected" message
  maxTimeout?: number; // max timeout in ms before showing "Continue anyway" option
}

// Inline loading indicator for use within components
interface LoadingIndicatorProps {
  message?: string;
}

// Define styles outside component to prevent re-creation on each render
// which was causing the blinking issue
const LoadingContainer = styled.div`
  position: fixed;
  top: 10px;
  right: calc(50% - 230px); /* Half of (max-width - 20px) to align with max-width container */
  background-color: ${theme.colors.background.secondary};
  color: ${theme.colors.text.primary};
  padding: 5px 10px;
  border-radius: ${theme.borderRadius.md};
  font-size: ${theme.fontSizes.xs};
  z-index: 10;
  opacity: 0.8;
  display: flex;
  align-items: center;
  gap: 5px;
  
  @media (max-width: 480px) {
    right: 10px; /* Default positioning on smaller screens */
  }
`;

const pulse = keyframes`
  0% { transform: scale(0.8); opacity: 0.6; }
  50% { transform: scale(1); opacity: 1; }
  100% { transform: scale(0.8); opacity: 0.6; }
`;

const PulsingDot = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${theme.colors.primary};
  border-radius: 50%;
  animation: ${pulse} 1.5s infinite ease-in-out;
`;

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ 
  message = 'Loading...' 
}) => {
  return (
    <LoadingContainer>
      <PulsingDot />
      {message}
    </LoadingContainer>
  );
};

const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.background.primary};
  color: ${theme.colors.text.primary};
  z-index: ${theme.zIndex.modal};
`;

const LoadingMessage = styled.h2`
  font-size: ${theme.fontSizes.lg};
  margin-bottom: ${theme.spacing.lg};
  text-align: center;
  max-width: 80%;
`;

const SubMessage = styled.p`
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.text.secondary};
  margin-top: ${theme.spacing.md};
  text-align: center;
  max-width: 80%;
`;

const spin = keyframes`
  to { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid ${theme.colors.background.tertiary};
  border-radius: 50%;
  border-top-color: ${theme.colors.primary};
  animation: ${spin} 1s ease-in-out infinite;
`;

const ContinueButton = styled.button`
  margin-top: ${theme.spacing.lg};
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  background-color: ${theme.colors.primary};
  color: ${theme.colors.text.primary};
  border: none;
  border-radius: ${theme.borderRadius.md};
  font-size: ${theme.fontSizes.sm};
  cursor: pointer;
  
  &:hover {
    background-color: ${theme.colors.secondary};
  }
`;

export const LoadingScreen: React.FC<LoadingScreenProps> = ({ 
  message = 'Loading user data...',
  timeout = 10000, // 10 seconds before showing "Taking longer than expected"
  maxTimeout = 30000 // 30 seconds before showing "Continue anyway"
}) => {
  const [showDelayMessage, setShowDelayMessage] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [loadingDone, setLoadingDone] = useState(false);
  
  useEffect(() => {
    // Set timer for "Taking longer than expected" message
    const delayTimer = setTimeout(() => {
      setShowDelayMessage(true);
    }, timeout);
    
    // Set timer for "Continue anyway" button
    const maxTimer = setTimeout(() => {
      setShowContinueButton(true);
    }, maxTimeout);
    
    return () => {
      clearTimeout(delayTimer);
      clearTimeout(maxTimer);
    };
  }, [timeout, maxTimeout]);
  
  // If user chooses to continue anyway
  if (loadingDone) {
    return null;
  }
  
  return (
    <FullScreenContainer>
      <LoadingMessage>{message}</LoadingMessage>
      <Spinner />
      
      {showDelayMessage && (
        <SubMessage>
          This is taking longer than expected. Please wait...
        </SubMessage>
      )}
      
      {showContinueButton && (
        <>
          <SubMessage>
            There might be a problem with the connection.
          </SubMessage>
          <ContinueButton onClick={() => setLoadingDone(true)}>
            Continue anyway
          </ContinueButton>
        </>
      )}
    </FullScreenContainer>
  );
};

export default LoadingScreen;