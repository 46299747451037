import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../style/theme';
import Button from '../../../components/ui/Button';
import { useAuthContext } from '../../../context/AuthContext';
import { api } from '../../../services/api';

interface BattleRequestSystemProps {
  onBattleStart?: (battleId: string) => void;
}

const Container = styled.div`
  background-color: ${theme.colors.background.secondary};
  border-radius: ${theme.borderRadius.md};
  padding: ${theme.spacing.md};
  margin-bottom: ${theme.spacing.md};
`;

const Title = styled.h3`
  margin-top: 0;
  margin-bottom: ${theme.spacing.md};
  font-size: ${theme.fontSizes.md};
  color: ${theme.colors.text.primary};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.md};
  margin-bottom: ${theme.spacing.md};
`;

const StatusMessage = styled.div<{ isError?: boolean }>`
  padding: ${theme.spacing.sm};
  border-radius: ${theme.borderRadius.sm};
  background-color: ${props => props.isError 
    ? 'rgba(255, 59, 48, 0.1)' 
    : theme.colors.background.tertiary};
  color: ${props => props.isError 
    ? theme.colors.danger 
    : theme.colors.text.secondary};
  margin-top: ${theme.spacing.sm};
`;

const RequestList = styled.div`
  margin-top: ${theme.spacing.md};
`;

const RequestItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing.sm};
  background-color: ${theme.colors.background.tertiary};
  border-radius: ${theme.borderRadius.sm};
  margin-bottom: ${theme.spacing.sm};
`;

const RequestInfo = styled.div`
  flex: 1;
`;

export const BattleRequestSystem: React.FC<BattleRequestSystemProps> = ({
  onBattleStart
}) => {
  const { user } = useAuthContext();
  const [status, setStatus] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [availableRequests, setAvailableRequests] = useState<Array<{ id: string, requester: string, level: number }>>([]);
  const [showRequests, setShowRequests] = useState(false);

  const handleCreateRequest = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // This would call the API to create a battle request
      // Mock implementation for now
      const response = await api.createBattleRequest();
      
      if (response.error) {
        setError(response.error);
      } else {
        setStatus('Battle request created. Waiting for opponents...');
        // In a real implementation, we'd set up a poll or websocket to check for matches
      }
    } catch (err) {
      setError('Failed to create battle request');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFindRequests = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // This would call the API to find available battle requests
      // Mock implementation for now
      const response = await api.findBattleRequests();
      
      if (response.error) {
        setError(response.error);
      } else if (response.data) {
        setAvailableRequests(response.data);
        setShowRequests(true);
      }
    } catch (err) {
      setError('Failed to find battle requests');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptRequest = async (requestId: string) => {
    setLoading(true);
    setError(null);
    
    try {
      // Call the mocked API to accept a battle request
      const response = await api.acceptBattleRequest(requestId);
      
      if (response.error) {
        setError(response.error);
      } else if (response.data && response.data.battleId) {
        setStatus('Battle request accepted!');
        
        // Add a brief delay before redirecting to battle
        setTimeout(() => {
          // Notify parent component about battle start
          if (onBattleStart) {
            // Use the new battle UI instead of the old one
            onBattleStart(response.data!.battleId);
          }
        }, 500);
      }
    } catch (err) {
      setError('Failed to accept battle request');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Title>Battle Matchmaking</Title>
      
      <ButtonsContainer>
        <Button 
          onClick={handleCreateRequest}
          disabled={loading}
          variant="primary"
        >
          Create Battle Request
        </Button>
        
        <Button 
          onClick={handleFindRequests}
          disabled={loading}
          variant="secondary"
        >
          Find Opponents
        </Button>
      </ButtonsContainer>
      
      {status && <StatusMessage>{status}</StatusMessage>}
      {error && <StatusMessage isError>{error}</StatusMessage>}
      
      {showRequests && (
        <RequestList>
          <h4>Available Battle Requests</h4>
          
          {availableRequests.length === 0 ? (
            <StatusMessage>No battle requests available for your level</StatusMessage>
          ) : (
            availableRequests.map(request => (
              <RequestItem key={request.id}>
                <RequestInfo>
                  <div>Requester: {request.requester}</div>
                  <div>Level: {request.level}</div>
                </RequestInfo>
                <Button 
                  onClick={() => handleAcceptRequest(request.id)}
                  disabled={loading}
                  variant="success"
                  size="sm"
                >
                  Accept
                </Button>
              </RequestItem>
            ))
          )}
        </RequestList>
      )}
    </Container>
  );
};

export default BattleRequestSystem;