import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Robot, RobotSlot } from "../../types";
import styles from "../../style/RobotDetails.module.css";
import HealthBar from "../../components/ui/HealthBar";
import { api } from "../../services/api";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Button from "../../components/ui/Button";
import { useTelegram } from "../../hooks/useTelegram";

export const RobotDetails: React.FC = () => {
  const { robotId } = useParams<{ robotId: string }>();
  const navigate = useNavigate();
  const [robot, setRobot] = useState<Robot | null>(null);
  const [slots, setSlots] = useState<RobotSlot[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [upgrading, setUpgrading] = useState(false);
  const [repairingArmor, setRepairingArmor] = useState(false);
  const [slotActionLoading, setSlotActionLoading] = useState<string | null>(null);
  const { haptic } = useTelegram();

  useEffect(() => {
    const fetchRobotDetails = async () => {
      if (!robotId) {
        setError("Robot ID is required");
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await api.getRobot(robotId);
        
        if (response.error) {
          setError(response.error);
          setLoading(false);
          return;
        }
        
        if (response.data) {
          setRobot(response.data);
          
          // Fetch slots for the robot
          const slotsResponse = await api.getRobotSlots(robotId);
          if (slotsResponse.data) {
            setSlots(slotsResponse.data);
          }
        } else {
          setError("Robot not found");
        }
      } catch (error) {
        setError(error instanceof Error ? error.message : "Failed to load robot details");
      } finally {
        setLoading(false);
      }
    };

    fetchRobotDetails();
  }, [robotId]);

  const repairArmor = async () => {
    if (!robot || !robotId) return;
    
    try {
      setRepairingArmor(true);
      const response = await api.repairRobot(robotId);
      
      if (response.error) {
        setError(response.error);
        haptic.notificationOccurred('error');
        return;
      }
      
      if (response.data && response.data.robot) {
        setRobot(response.data.robot);
        haptic.notificationOccurred('success');
        setError(null);
      }
    } catch (error) {
      console.error("Error repairing robot:", error);
      setError(error instanceof Error ? error.message : "An error occurred");
      haptic.notificationOccurred('error');
    } finally {
      setRepairingArmor(false);
    }
  };

  const upgradeCharacteristic = async (characteristic: string) => {
    if (!robot || !robotId) return;
    
    try {
      setUpgrading(true);
      const response = await api.upgradeRobot(robotId, characteristic);
      
      if (response.error) {
        setError(response.error);
        haptic.notificationOccurred('error');
        return;
      }
      
      if (response.data && response.data.robot) {
        setRobot(response.data.robot);
        haptic.notificationOccurred('success');
        setError(null);
      }
    } catch (error) {
      console.error("Error upgrading robot:", error);
      setError(error instanceof Error ? error.message : "An error occurred");
      haptic.notificationOccurred('error');
    } finally {
      setUpgrading(false);
    }
  };

  const handleBack = () => {
    navigate("/robots");
  };

  const handleSlotAction = async (slot: RobotSlot) => {
    if (!robotId || !slot.id) return;
    
    setSlotActionLoading(slot.id);
    
    try {
      if (slot.locked) {
        // Unlock slot
        const response = await api.unlockRobotSlot(robotId, slot.id);
        
        if (response.error) {
          setError(response.error);
          haptic.notificationOccurred('error');
          return;
        }
        
        // Update slots after unlocking
        const updatedSlotsResponse = await api.getRobotSlots(robotId);
        if (updatedSlotsResponse.data) {
          setSlots(updatedSlotsResponse.data);
          haptic.notificationOccurred('success');
          setError(null);
        }
      } else if (slot.item) {
        // Unequip item
        const response = await api.unequipItem(robotId, slot.id);
        
        if (response.error) {
          setError(response.error);
          haptic.notificationOccurred('error');
          return;
        }
        
        // Update slots after unequipping
        const updatedSlotsResponse = await api.getRobotSlots(robotId);
        if (updatedSlotsResponse.data) {
          setSlots(updatedSlotsResponse.data);
          haptic.notificationOccurred('success');
          setError(null);
        }
      } else {
        // For adding items, we'll need an item selection UI
        // For now, just show an error message
        setError("Item equipping is not implemented yet");
        haptic.notificationOccurred('warning');
      }
    } catch (error) {
      console.error("Error handling slot action:", error);
      setError(error instanceof Error ? error.message : "An error occurred");
      haptic.notificationOccurred('error');
    } finally {
      setSlotActionLoading(null);
    }
  };

  if (loading) {
    return <div className={styles.loading}>Loading robot details...</div>;
  }

  if (!robot) {
    return <div className={styles.error}>{error || "Robot not found"}</div>;
  }

  return (
    <div className={styles.container}>
      <button className={styles.backButton} onClick={handleBack}>
        &larr; Back to Robots
      </button>
      
      {error && <div className={styles.errorMessage}>{error}</div>}
      
      <div className={styles.header}>
        <h1>{robot.name}</h1>
        <div className={styles.robotMeta}>
          <span className={styles.robotType}>{robot.type}</span>
          <span className={styles.robotLevel}>Level {robot.level}</span>
        </div>
      </div>
      
      <div className={styles.stats}>
        <div className={styles.statItem}>
          <HealthBar
            currentHealth={robot.energy}
            maxHealth={robot.maxEnergy}
            label="Energy"
          />
          {/* Energy recharging - for future implementation */}
        </div>

        <div className={styles.statItem}>
          <HealthBar
            currentHealth={robot.armor}
            maxHealth={robot.maxArmor}
            label="Armor"
          />
          <button 
            className={styles.actionButton}
            onClick={repairArmor}
            disabled={repairingArmor || robot.armor >= robot.maxArmor}
          >
            {repairingArmor ? "Repairing..." : "Repair Armor"}
          </button>
        </div>
      </div>
      
      <div className={styles.characteristics}>
        <h3>Characteristics</h3>
        <div className={styles.characteristicsList}>
          {Object.entries(robot.characteristics).map(([key, value]) => (
            <div key={key} className={styles.characteristicItem}>
              <span className={styles.characteristicName}>
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </span>
              <div className={styles.characteristicBar}>
                <div 
                  className={styles.characteristicFill}
                  style={{ width: `${(value / 20) * 100}%` }}
                />
              </div>
              <div className={styles.characteristicActions}>
                <span className={styles.characteristicValue}>{value}</span>
                <button 
                  className={styles.upgradeButton}
                  onClick={() => upgradeCharacteristic(key)}
                  disabled={upgrading}
                >
                  {upgrading ? "⏳" : "⬆️"}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <div className={styles.equipmentSection}>
        <h3>Equipment Slots</h3>
        <div className={styles.slots}>
          {slots.map((slot) => (
            <div 
              key={slot.id} 
              className={`${styles.slot} ${slot.locked ? styles.locked : ''} ${slotActionLoading === slot.id ? styles.loading : ''}`}
              onClick={() => !slotActionLoading && handleSlotAction(slot)}
            >
              <div className={styles.slotType}>{slot.slotType || 'Generic'}</div>
              {slotActionLoading === slot.id ? (
                <div className={styles.slotLoading}>
                  <span>Processing...</span>
                </div>
              ) : slot.locked ? (
                <div className={styles.slotLocked}>
                  <span className={styles.lockIcon}>🔒</span>
                  <span>Unlock</span>
                </div>
              ) : slot.item ? (
                <div className={styles.slotItem}>
                  <span className={styles.itemName}>{slot.item}</span>
                  <span className={styles.itemAction}>Remove</span>
                </div>
              ) : (
                <div className={styles.slotEmpty}>
                  <span className={styles.emptyIcon}>+</span>
                  <span>Add Item</span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RobotDetails;