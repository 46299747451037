import React, { useState, useEffect } from 'react';

export default function CountdownBar() {
    const [secondsLeft, setSecondsLeft] = useState(15);
    const totalTime = 15; // total duration in seconds

    useEffect(() => {
        if (secondsLeft > 0) {
            const interval = setInterval(() => {
                setSecondsLeft(prev => prev - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [secondsLeft]);

    // Calculate the width of the bar based on the time left
    const barWidth = (secondsLeft / totalTime) * 100;

    return (
        <div style={styles.container}>
            <div style={{ ...styles.bar, width: `${barWidth}%` }}></div>
        </div>
    );
}

const styles = {
    container: {
        width: '100%',
        height: '30px',
        backgroundColor: '#ddd',
        borderRadius: '5px',
        overflow: 'hidden',
        border: '1px solid #ccc',
    },
    bar: {
        height: '100%',
        backgroundColor: '#4caf50',
        transition: 'width 1s linear',
    },
};