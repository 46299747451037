import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import BottomNavigation from './BottomNavigation';
import theme from '../../style/theme';
import { useBattleContext } from '../../context/BattleContext';

interface MainLayoutProps {
  children: React.ReactNode;
  title?: string;
  showBackButton?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  fullHeight?: boolean;
}

const LayoutContainer = styled.div<{ fullHeight: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: ${props => props.fullHeight ? '100vh' : 'calc(100vh - 56px)'};
  background-color: ${theme.colors.background.primary};
  max-width: 480px; /* Set max width for mobile devices */
  margin: 0 auto; /* Center the container */
  width: 100%;
  position: relative; /* Needed for fixed position children */
`;

const Main = styled.main<{ hasHeader: boolean; hasFooter: boolean }>`
  flex: 1;
  padding: ${theme.spacing.md};
  padding-top: ${props => props.hasHeader ? theme.spacing.md : '0'};
  padding-bottom: ${props => props.hasFooter ? '100px' : theme.spacing.md}; /* Space for bottom navigation */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  title,
  showBackButton = false,
  showHeader = true,
  showFooter = true,
  fullHeight = false,
}) => {
  const location = useLocation();
  const { inBattle } = useBattleContext();
  const [menuOpen, setMenuOpen] = useState(false);
  
  // Hide bottom navigation on battle pages
  const shouldShowFooter = showFooter && !inBattle && !location.pathname.includes('/battle/') && !location.pathname.includes('/new-battle/');
  
  // Get the pathname to determine if we're on a detail page that needs a back button
  const pathname = location.pathname;
  const needsBackButton = showBackButton || 
    pathname.includes('/battle/') || 
    pathname.includes('/new-battle/') || 
    pathname.includes('/robots/') ||
    pathname === '/leaderboard';
  
  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <LayoutContainer fullHeight={fullHeight}>
      {showHeader && (
        <Header 
          title={title} 
          showBackButton={needsBackButton}
          onMenuClick={handleMenuClick}
        />
      )}
      
      <Main hasHeader={showHeader} hasFooter={shouldShowFooter}>
        {children}
      </Main>
      
      {shouldShowFooter && <BottomNavigation />}
    </LayoutContainer>
  );
};

export default MainLayout;