import React, { useState, useEffect } from 'react';
import './style/Profile.css';
import { useGlobalContext } from './GlobalContext';
import Avatar from "./Avatar";
import BattleHistory from "./user/BattleHistory";


const Profile : React.FC = () => {
    const { userId } = useGlobalContext();
    const { userProfile } = useGlobalContext();

    useEffect(() => {

    });

    const extractNumberFromAvatar = (avatar: string) => {
        return avatar.replace(/\D/g, ''); // Replace all non-digit characters with an empty string
    };

    const avatarNumber = extractNumberFromAvatar(userProfile.avatar);
    const battlesNumber = userProfile.win + userProfile.lost + userProfile.draw;

    return (
        <div className="profile-page">
            <div className="user-info">
                <Avatar avatarNumber={avatarNumber} />
                    <h1 className="username">{userProfile.id}</h1>
            </div>
            <div className="stats">
                <div className="stat">
                    <p className="stat-number">{battlesNumber}</p>
                    <p className="stat-label">Battles</p>
                </div>
                <div className="stat">
                    <p className="stat-number">{userProfile.win}/{userProfile.lost}</p>
                    <p className="stat-label">Wins/Losses</p>
                </div>
                <div className="stat">
                    <p className="stat-number">1</p>
                    <p className="stat-label">Ranking</p>
                </div>
            </div>
            <div className="battle-history">
                <BattleHistory userId={+userId} />
            </div>
        </div>
    );
}
export default Profile;