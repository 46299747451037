import React from 'react';
import styled, { css } from 'styled-components';
import theme from '../../style/theme';

type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type AvatarVariant = 'circle' | 'rounded' | 'square';

interface AvatarProps {
  src?: string | null;
  alt?: string;
  size?: AvatarSize;
  variant?: AvatarVariant;
  status?: 'online' | 'offline' | 'away' | 'busy';
  text?: string;
  className?: string;
  onClick?: () => void;
}

const getSize = (size: AvatarSize): string => {
  switch (size) {
    case 'xs': return '24px';
    case 'sm': return '32px';
    case 'md': return '40px';
    case 'lg': return '56px';
    case 'xl': return '72px';
    default: return '40px';
  }
};

const getBorderRadius = (variant: AvatarVariant): string => {
  switch (variant) {
    case 'circle': return '50%';
    case 'rounded': return theme.borderRadius.md;
    case 'square': return '0';
    default: return '50%';
  }
};

const getStatusColor = (status?: string): string => {
  switch (status) {
    case 'online': return theme.colors.success;
    case 'offline': return theme.colors.text.disabled;
    case 'away': return theme.colors.warning;
    case 'busy': return theme.colors.danger;
    default: return 'transparent';
  }
};

const AvatarContainer = styled.div<{
  size: AvatarSize;
  variant: AvatarVariant;
  hasClickHandler: boolean;
}>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${props => getSize(props.size)};
  height: ${props => getSize(props.size)};
  border-radius: ${props => getBorderRadius(props.variant)};
  background-color: ${theme.colors.background.tertiary};
  overflow: hidden;
  
  ${props => props.hasClickHandler && css`
    cursor: pointer;
    transition: transform ${theme.animation.fast}, opacity ${theme.animation.fast};
    
    &:hover {
      opacity: 0.9;
      transform: scale(1.05);
    }
    
    &:active {
      transform: scale(0.95);
    }
  `}
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AvatarText = styled.div<{ size: AvatarSize }>`
  color: ${theme.colors.text.primary};
  font-weight: 500;
  user-select: none;
  
  ${props => {
    switch (props.size) {
      case 'xs': return css`font-size: ${theme.fontSizes.xs};`;
      case 'sm': return css`font-size: ${theme.fontSizes.xs};`;
      case 'md': return css`font-size: ${theme.fontSizes.sm};`;
      case 'lg': return css`font-size: ${theme.fontSizes.md};`;
      case 'xl': return css`font-size: ${theme.fontSizes.lg};`;
      default: return css`font-size: ${theme.fontSizes.sm};`;
    }
  }}
`;

const StatusIndicator = styled.div<{ status?: string; size: AvatarSize }>`
  position: absolute;
  background-color: ${props => getStatusColor(props.status)};
  border-radius: 50%;
  border: 2px solid ${theme.colors.background.primary};
  
  ${props => {
    const sizePx = parseInt(getSize(props.size).replace('px', ''), 10);
    const statusSize = Math.max(8, sizePx * 0.2);
    
    return css`
      width: ${statusSize}px;
      height: ${statusSize}px;
      bottom: 0;
      right: 0;
    `;
  }}
`;

// Static functions to prevent re-creation on each render
const getInitials = (text?: string): string => {
  if (!text) return '';
  return text
    .split(' ')
    .map(word => word.charAt(0))
    .join('')
    .toUpperCase()
    .substring(0, 2);
};

// Check if the src is a legacy avatar name (like "avatar4")
const isLegacyAvatar = (src?: string | null): boolean => {
  if (!src || typeof src !== 'string') return false;
  
  // Check if it's a legacy avatar pattern or a placeholder URL
  return (src.startsWith('avatar') && /avatar\d+/.test(src)) || 
         (src === "https://via.placeholder.com/150");
};

export const Avatar: React.FC<AvatarProps> = React.memo(({
  src,
  alt = 'Avatar',
  size = 'md',
  variant = 'circle',
  status,
  text,
  className,
  onClick,
}) => {
  // Memoize the avatar content to prevent re-renders
  const avatarContent = React.useMemo(() => {
    if (src && !isLegacyAvatar(src)) {
      return <AvatarImage src={src} alt={alt} />;
    } else {
      return <AvatarText size={size}>{getInitials(text)}</AvatarText>;
    }
  }, [src, alt, size, text]);

  return (
    <AvatarContainer 
      size={size} 
      variant={variant} 
      className={className}
      onClick={onClick}
      hasClickHandler={!!onClick}
    >
      {avatarContent}
      {status && <StatusIndicator status={status} size={size} />}
    </AvatarContainer>
  );
});

export default Avatar;