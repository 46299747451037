import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../style/theme';

interface StyledButtonProps {
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'outline' | 'ghost';
  size?: 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
  $iconPosition?: 'left' | 'right';
}

// Regular button component
const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.sm};
  border-radius: ${theme.borderRadius.full};
  font-weight: 500;
  transition: all ${theme.animation.normal};
  cursor: pointer;
  border: none;
  text-decoration: none;
  
  ${props => props.fullWidth && `width: 100%;`}
  
  ${props => {
    switch(props.size) {
      case 'sm':
        return `
          font-size: ${theme.fontSizes.xs};
          padding: ${theme.spacing.xs} ${theme.spacing.md};
          height: 32px;
        `;
      case 'lg':
        return `
          font-size: ${theme.fontSizes.md};
          padding: ${theme.spacing.md} ${theme.spacing.lg};
          height: 48px;
        `;
      case 'md':
      default:
        return `
          font-size: ${theme.fontSizes.sm};
          padding: ${theme.spacing.sm} ${theme.spacing.md};
          height: 40px;
        `;
    }
  }}
  
  ${props => {
    switch(props.variant) {
      case 'secondary':
        return `
          background-color: ${theme.colors.secondary};
          color: ${theme.colors.text.primary};
          &:hover {
            background-color: #7460d1;
          }
          &:active {
            background-color: #6852c2;
          }
        `;
      case 'success':
        return `
          background-color: ${theme.colors.success};
          color: ${theme.colors.text.primary};
          &:hover {
            background-color: #43a047;
          }
          &:active {
            background-color: #388e3c;
          }
        `;
      case 'danger':
        return `
          background-color: ${theme.colors.danger};
          color: ${theme.colors.text.primary};
          &:hover {
            background-color: #e53935;
          }
          &:active {
            background-color: #d32f2f;
          }
        `;
      case 'outline':
        return `
          background-color: transparent;
          border: 1px solid ${theme.colors.border.default};
          color: ${theme.colors.text.primary};
          &:hover {
            border-color: ${theme.colors.border.active};
            background-color: rgba(255, 255, 255, 0.05);
          }
          &:active {
            background-color: rgba(255, 255, 255, 0.1);
          }
        `;
      case 'ghost':
        return `
          background-color: transparent;
          color: ${theme.colors.text.primary};
          &:hover {
            background-color: rgba(255, 255, 255, 0.05);
          }
          &:active {
            background-color: rgba(255, 255, 255, 0.1);
          }
        `;
      case 'primary':
      default:
        return `
          background-color: ${theme.colors.primary};
          color: ${theme.colors.text.primary};
          &:hover {
            background-color: #2a84d8;
          }
          &:active {
            background-color: #1a76c6;
          }
        `;
    }
  }}
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
  
  ${props => props.$iconPosition === 'right' && `flex-direction: row-reverse;`}
`;

// Link button component
const StyledLinkButton = styled(Link)<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.sm};
  border-radius: ${theme.borderRadius.full};
  font-weight: 500;
  transition: all ${theme.animation.normal};
  cursor: pointer;
  border: none;
  text-decoration: none;
  
  ${props => props.fullWidth && `width: 100%;`}
  
  ${props => {
    switch(props.size) {
      case 'sm':
        return `
          font-size: ${theme.fontSizes.xs};
          padding: ${theme.spacing.xs} ${theme.spacing.md};
          height: 32px;
        `;
      case 'lg':
        return `
          font-size: ${theme.fontSizes.md};
          padding: ${theme.spacing.md} ${theme.spacing.lg};
          height: 48px;
        `;
      case 'md':
      default:
        return `
          font-size: ${theme.fontSizes.sm};
          padding: ${theme.spacing.sm} ${theme.spacing.md};
          height: 40px;
        `;
    }
  }}
  
  ${props => {
    switch(props.variant) {
      case 'secondary':
        return `
          background-color: ${theme.colors.secondary};
          color: ${theme.colors.text.primary};
          &:hover {
            background-color: #7460d1;
          }
          &:active {
            background-color: #6852c2;
          }
        `;
      case 'success':
        return `
          background-color: ${theme.colors.success};
          color: ${theme.colors.text.primary};
          &:hover {
            background-color: #43a047;
          }
          &:active {
            background-color: #388e3c;
          }
        `;
      case 'danger':
        return `
          background-color: ${theme.colors.danger};
          color: ${theme.colors.text.primary};
          &:hover {
            background-color: #e53935;
          }
          &:active {
            background-color: #d32f2f;
          }
        `;
      case 'outline':
        return `
          background-color: transparent;
          border: 1px solid ${theme.colors.border.default};
          color: ${theme.colors.text.primary};
          &:hover {
            border-color: ${theme.colors.border.active};
            background-color: rgba(255, 255, 255, 0.05);
          }
          &:active {
            background-color: rgba(255, 255, 255, 0.1);
          }
        `;
      case 'ghost':
        return `
          background-color: transparent;
          color: ${theme.colors.text.primary};
          &:hover {
            background-color: rgba(255, 255, 255, 0.05);
          }
          &:active {
            background-color: rgba(255, 255, 255, 0.1);
          }
        `;
      case 'primary':
      default:
        return `
          background-color: ${theme.colors.primary};
          color: ${theme.colors.text.primary};
          &:hover {
            background-color: #2a84d8;
          }
          &:active {
            background-color: #1a76c6;
          }
        `;
    }
  }}
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
  
  ${props => props.$iconPosition === 'right' && `flex-direction: row-reverse;`}
`;

interface ButtonBaseProps {
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'outline' | 'ghost';
  size?: 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  className?: string;
}

interface NormalButtonProps extends Omit<ButtonBaseProps, 'children'>, React.ButtonHTMLAttributes<HTMLButtonElement> {
  to?: never;
}

interface LinkButtonProps extends ButtonBaseProps {
  to: string;
  children: React.ReactNode;
}

type ButtonProps = NormalButtonProps | LinkButtonProps;

export const Button: React.FC<ButtonProps> = (props) => {
  const { 
    children, 
    variant = 'primary', 
    size = 'md', 
    icon, 
    iconPosition = 'left',
    fullWidth = false,
    ...rest 
  } = props;
  
  // Check if it's a link button
  if ('to' in props && props.to !== undefined) {
    return (
      <StyledLinkButton 
        to={props.to}
        variant={variant}
        size={size}
        fullWidth={fullWidth}
        $iconPosition={icon ? iconPosition : undefined}
        {...rest as any}
      >
        {icon}
        {children}
      </StyledLinkButton>
    );
  }
  
  // Regular button
  return (
    <StyledButton
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      $iconPosition={icon ? iconPosition : undefined}
      {...rest}
    >
      {icon}
      {children}
    </StyledButton>
  );
};

export default Button;