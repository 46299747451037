import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuthContext } from '../../context/AuthContext';

// Types for market items
interface MarketItem {
  id: string;
  name: string;
  type: 'weapon' | 'armor' | 'utility';
  description: string;
  price: number;
  stats: {
    power?: number;
    defense?: number;
    energy?: number;
    speed?: number;
  };
  image?: string;
  rarity: 'common' | 'rare' | 'epic' | 'legendary';
}

// Mock data for market items
const mockMarketItems: MarketItem[] = [
  {
    id: 'weapon1',
    name: 'Plasma Blaster',
    type: 'weapon',
    description: 'A powerful energy weapon that deals high damage',
    price: 250,
    stats: {
      power: 15,
    },
    rarity: 'common'
  },
  {
    id: 'weapon2',
    name: 'Quantum Disruptor',
    type: 'weapon',
    description: 'Disrupts enemy shields and armor',
    price: 500,
    stats: {
      power: 25,
    },
    rarity: 'rare'
  },
  {
    id: 'armor1',
    name: 'Energy Shield',
    type: 'armor',
    description: 'Absorbs energy attacks, reducing damage',
    price: 300,
    stats: {
      defense: 20,
    },
    rarity: 'common'
  },
  {
    id: 'armor2',
    name: 'Titanium Plating',
    type: 'armor',
    description: 'Heavy armor that provides excellent protection',
    price: 450,
    stats: {
      defense: 30,
      speed: -5,
    },
    rarity: 'rare'
  },
  {
    id: 'utility1',
    name: 'Energy Cell',
    type: 'utility',
    description: 'Increases robot energy capacity',
    price: 200,
    stats: {
      energy: 25,
    },
    rarity: 'common'
  },
  {
    id: 'utility2',
    name: 'Speed Booster',
    type: 'utility',
    description: 'Increases robot speed significantly',
    price: 350,
    stats: {
      speed: 20,
    },
    rarity: 'rare'
  },
  {
    id: 'weapon3',
    name: 'Fusion Cannon',
    type: 'weapon',
    description: 'Devastating weapon that can pierce multiple targets',
    price: 1200,
    stats: {
      power: 45,
      energy: -15,
    },
    rarity: 'epic'
  },
  {
    id: 'armor3',
    name: 'Adaptive Nano-Shield',
    type: 'armor',
    description: 'Advanced shield that adapts to incoming damage types',
    price: 1500,
    stats: {
      defense: 40,
    },
    rarity: 'epic'
  },
  {
    id: 'utility3',
    name: 'Quantum Capacitor',
    type: 'utility',
    description: 'Supercharges all robot systems temporarily',
    price: 2000,
    stats: {
      power: 15,
      defense: 15,
      speed: 15,
      energy: 30,
    },
    rarity: 'legendary'
  },
];

// Styled components
const MarketContainer = styled.div`
  padding: ${props => props.theme.spacing.md};
  max-width: 1000px;
  margin: 0 auto;
`;

const MarketHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.lg};
`;

const MarketTitle = styled.h1`
  font-size: ${props => props.theme.fontSizes['2xl']};
  margin: 0;
`;

const CategoryTabs = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing.xs};
  margin-bottom: ${props => props.theme.spacing.md};
  border-bottom: 1px solid ${props => props.theme.colors.border.default};
  padding-bottom: ${props => props.theme.spacing.sm};
`;

const CategoryTab = styled.button<{ active: boolean }>`
  padding: ${props => props.theme.spacing.sm} ${props => props.theme.spacing.md};
  border-radius: ${props => props.theme.borderRadius.md};
  background-color: ${props => props.active ? props.theme.colors.primary : 'transparent'};
  color: ${props => props.active ? '#fff' : props.theme.colors.text.primary};
  border: none;
  cursor: pointer;
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  transition: all ${props => props.theme.animation.fast};

  &:hover {
    background-color: ${props => props.active ? props.theme.colors.primary : props.theme.colors.background.tertiary};
  }
`;

const ItemsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: ${props => props.theme.spacing.md};
`;

const ItemCard = styled.div<{ rarity: string }>`
  background-color: ${props => props.theme.colors.background.secondary};
  border-radius: ${props => props.theme.borderRadius.lg};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => {
    switch (props.rarity) {
      case 'legendary': return '#FFD700'; // Gold
      case 'epic': return '#9C27B0'; // Purple
      case 'rare': return '#2196F3'; // Blue
      default: return props.theme.colors.border.default; // Common/default
    }
  }};
  box-shadow: ${props => {
    switch (props.rarity) {
      case 'legendary': return `0 0 10px rgba(255, 215, 0, 0.5)`;
      case 'epic': return `0 0 8px rgba(156, 39, 176, 0.4)`;
      case 'rare': return `0 0 6px rgba(33, 150, 243, 0.3)`;
      default: return props.theme.shadows.md;
    }
  }};
  transition: transform ${props => props.theme.animation.fast};

  &:hover {
    transform: translateY(-5px);
  }
`;

const ItemImage = styled.div`
  height: 120px;
  background-color: ${props => props.theme.colors.background.tertiary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.text.secondary};
  font-size: ${props => props.theme.fontSizes.xl};
`;

const ItemContent = styled.div`
  padding: ${props => props.theme.spacing.md};
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ItemName = styled.h3`
  margin: 0 0 ${props => props.theme.spacing.xs};
  font-size: ${props => props.theme.fontSizes.lg};
`;

const ItemDescription = styled.p`
  color: ${props => props.theme.colors.text.secondary};
  font-size: ${props => props.theme.fontSizes.sm};
  margin: 0 0 ${props => props.theme.spacing.sm};
  flex: 1;
`;

const ItemStats = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing.xs};
  margin-bottom: ${props => props.theme.spacing.sm};
  font-family: ${props => props.theme.fonts?.monospace};
  font-size: ${props => props.theme.fontSizes.xs};
`;

const StatBadge = styled.span<{ positive: boolean }>`
  background-color: ${props => props.positive ? props.theme.colors.success + '33' : props.theme.colors.danger + '33'};
  color: ${props => props.positive ? props.theme.colors.success : props.theme.colors.danger};
  padding: 2px 6px;
  border-radius: ${props => props.theme.borderRadius.sm};
  display: inline-flex;
  align-items: center;
`;

const ItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${props => props.theme.spacing.sm};
`;

const Price = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.secondary};
`;

const BuyButton = styled.button<{ disabled: boolean }>`
  background-color: ${props => props.disabled ? props.theme.colors.disabled : props.theme.colors.primary};
  color: ${props => props.theme.colors.text.primary};
  border: none;
  border-radius: ${props => props.theme.borderRadius.md};
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.md};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all ${props => props.theme.animation.fast};

  &:hover {
    background-color: ${props => props.disabled ? props.theme.colors.disabled : props.theme.colors.primary + 'CC'};
  }
`;

const UserCredits = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.xs};
  font-weight: bold;
`;

const IconWrapper = styled.span`
  margin-right: 4px;
`;

type ItemCategory = 'all' | 'weapon' | 'armor' | 'utility';

const Market: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<ItemCategory>('all');
  const [items, setItems] = useState<MarketItem[]>(mockMarketItems);
  const { user } = useAuthContext();
  
  // Filter items when category changes
  useEffect(() => {
    if (selectedCategory === 'all') {
      setItems(mockMarketItems);
    } else {
      setItems(mockMarketItems.filter(item => item.type === selectedCategory));
    }
  }, [selectedCategory]);

  // Helper function to render stat badges
  const renderStats = (stats: MarketItem['stats']) => {
    return Object.entries(stats).map(([key, value]) => {
      const isPositive = value >= 0;
      const prefix = isPositive ? '+' : '';
      let statName = key;
      
      // Capitalize first letter
      if (statName) {
        statName = statName.charAt(0).toUpperCase() + statName.slice(1);
      }
      
      return (
        <StatBadge key={key} positive={isPositive}>
          {statName}: {prefix}{value}
        </StatBadge>
      );
    });
  };
  
  // Helper function to get icon for item type
  const getItemTypeIcon = (type: string) => {
    switch (type) {
      case 'weapon':
        return '⚔️';
      case 'armor':
        return '🛡️';
      case 'utility':
        return '⚙️';
      default:
        return '📦';
    }
  };

  // Helper function to handle purchase
  const handlePurchase = (item: MarketItem) => {
    alert(`Purchased ${item.name} for ${item.price} credits!`);
    // In a real app, you would call an API to process the purchase
  };

  // Check if user can afford item
  const canAfford = (price: number) => {
    return user && user.credits >= price;
  };

  return (
    <MarketContainer>
      <MarketHeader>
        <MarketTitle>Marketplace</MarketTitle>
        <UserCredits>
          <IconWrapper>💰</IconWrapper>
          Credits: {user?.credits || 0}
        </UserCredits>
      </MarketHeader>
      
      <CategoryTabs>
        <CategoryTab 
          active={selectedCategory === 'all'} 
          onClick={() => setSelectedCategory('all')}
        >
          All Items
        </CategoryTab>
        <CategoryTab 
          active={selectedCategory === 'weapon'} 
          onClick={() => setSelectedCategory('weapon')}
        >
          Weapons
        </CategoryTab>
        <CategoryTab 
          active={selectedCategory === 'armor'} 
          onClick={() => setSelectedCategory('armor')}
        >
          Armor
        </CategoryTab>
        <CategoryTab 
          active={selectedCategory === 'utility'} 
          onClick={() => setSelectedCategory('utility')}
        >
          Utility
        </CategoryTab>
      </CategoryTabs>
      
      <ItemsGrid>
        {items.map(item => (
          <ItemCard key={item.id} rarity={item.rarity}>
            <ItemImage>
              {item.image ? <img src={item.image} alt={item.name} /> : getItemTypeIcon(item.type)}
            </ItemImage>
            <ItemContent>
              <ItemName>{item.name}</ItemName>
              <ItemDescription>{item.description}</ItemDescription>
              <ItemStats>
                {renderStats(item.stats)}
              </ItemStats>
              <ItemFooter>
                <Price>
                  <IconWrapper>💰</IconWrapper>
                  {item.price}
                </Price>
                <BuyButton 
                  disabled={!canAfford(item.price)}
                  onClick={() => handlePurchase(item)}
                >
                  {canAfford(item.price) ? 'Buy' : 'Not Enough'}
                </BuyButton>
              </ItemFooter>
            </ItemContent>
          </ItemCard>
        ))}
      </ItemsGrid>
    </MarketContainer>
  );
};

export default Market;