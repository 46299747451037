// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HealthBar_healthBarContainer__DyCkn {
  width: 100%;
  margin: 10px 0;
}

.HealthBar_healthLabel__P4wdZ {
  font-weight: bold;
  margin-bottom: 5px;
}

.HealthBar_healthBarOuter__CEfTp {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.HealthBar_healthBarInner__rEZAe {
  height: 100%;
  border-radius: 10px;
  transition: width 0.3s ease, background-color 0.3s ease;
}

.HealthBar_healthText__hb88M {
  font-size: 0.8rem;
  margin-top: 5px;
  text-align: right;
}

/* Tiny size variant for robots */
.HealthBar_tiny__unIXG {
  margin: 2px 0;
  width: 100%;
}

.HealthBar_tiny__unIXG .HealthBar_healthBarOuter__CEfTp {
  height: 6px;
  border-radius: 3px;
}

.HealthBar_tiny__unIXG .HealthBar_healthBarInner__rEZAe {
  border-radius: 3px;
}

.HealthBar_tiny__unIXG .HealthBar_healthLabel__P4wdZ {
  font-size: 0.7rem;
  margin-bottom: 2px;
}

.HealthBar_tinyHealthText__AH7Xq {
  font-size: 0.7rem;
  margin-top: 2px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/style/HealthBar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,uDAAuD;AACzD;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,iBAAiB;AACnB;;AAEA,iCAAiC;AACjC;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".healthBarContainer {\n  width: 100%;\n  margin: 10px 0;\n}\n\n.healthLabel {\n  font-weight: bold;\n  margin-bottom: 5px;\n}\n\n.healthBarOuter {\n  width: 100%;\n  height: 20px;\n  background-color: #e0e0e0;\n  border-radius: 10px;\n  overflow: hidden;\n  position: relative;\n}\n\n.healthBarInner {\n  height: 100%;\n  border-radius: 10px;\n  transition: width 0.3s ease, background-color 0.3s ease;\n}\n\n.healthText {\n  font-size: 0.8rem;\n  margin-top: 5px;\n  text-align: right;\n}\n\n/* Tiny size variant for robots */\n.tiny {\n  margin: 2px 0;\n  width: 100%;\n}\n\n.tiny .healthBarOuter {\n  height: 6px;\n  border-radius: 3px;\n}\n\n.tiny .healthBarInner {\n  border-radius: 3px;\n}\n\n.tiny .healthLabel {\n  font-size: 0.7rem;\n  margin-bottom: 2px;\n}\n\n.tinyHealthText {\n  font-size: 0.7rem;\n  margin-top: 2px;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"healthBarContainer": `HealthBar_healthBarContainer__DyCkn`,
	"healthLabel": `HealthBar_healthLabel__P4wdZ`,
	"healthBarOuter": `HealthBar_healthBarOuter__CEfTp`,
	"healthBarInner": `HealthBar_healthBarInner__rEZAe`,
	"healthText": `HealthBar_healthText__hb88M`,
	"tiny": `HealthBar_tiny__unIXG`,
	"tinyHealthText": `HealthBar_tinyHealthText__AH7Xq`
};
export default ___CSS_LOADER_EXPORT___;
