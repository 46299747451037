import React from 'react';
import styles from './../style/Battle.module.css'; // Import the CSS module

interface OpponentSelectorProps {
    opponents: number[];
    selectedOpponentIndex: number;
    onPrevious: () => void;
    onNext: () => void;
}

const OpponentSelector: React.FC<OpponentSelectorProps> = ({
                                                               opponents,
                                                               selectedOpponentIndex,
                                                               onPrevious,
                                                               onNext,
                                                           }) => {
    const selectedOpponent = opponents[selectedOpponentIndex];
    const showArrows = opponents.length > 1;

    return (
        <div className={styles.opponentSelector}>
            {showArrows && <button onClick={onPrevious}>&larr;</button>}
            {selectedOpponent !== undefined ? (
                <span>{selectedOpponent}</span>
            ) : (
                <span>No opponents available</span>
            )}
            {showArrows && <button onClick={onNext}>&rarr;</button>}
        </div>
    );
};

export default OpponentSelector;