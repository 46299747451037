import React, { useState, useEffect, useCallback, useRef, memo, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../style/theme';
import { api } from '../../services/api';
import { useAuthContext } from '../../context/AuthContext';
import { Battle as BattleType, Robot } from '../../types';
import { useTelegram } from '../../hooks/useTelegram';
import { getBattleWebSocket } from '../../services/websocket';

// Components
import BattleArena from './components/BattleArena';
import BattleLogs from './components/BattleLogs';
import CountdownBar from './components/CountdownBar';
import BattleRequestSystem from './components/BattleRequestSystem';
import Button from '../../components/ui/Button';
import HealthBar from '../../components/ui/HealthBar';
import { LoadingIndicator } from '../../components/ui/LoadingScreen';

// Container for the entire battle page
const BattleContainer = styled.div`
  padding: ${theme.spacing.md};
  padding-bottom: 74px; /* Add padding to account for the fixed bottom submit button */
`;

// Section with a title
const Section = styled.div`
  margin-bottom: ${theme.spacing.lg};
`;

const SectionTitle = styled.h2`
  font-size: ${theme.fontSizes.lg};
  margin-bottom: ${theme.spacing.md};
  color: ${theme.colors.text.primary};
`;

// Card-like container for different sections
const Card = styled.div`
  background-color: ${theme.colors.background.secondary};
  border-radius: ${theme.borderRadius.lg};
  padding: ${theme.spacing.md};
  margin-bottom: ${theme.spacing.md};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
`;

// Container for the battle controls
const BattleControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.spacing.md};
`;

// Battle arena with reduced space
const CompactBattleArea = styled.div`
  padding: ${theme.spacing.sm};
  margin-bottom: ${theme.spacing.md};
  max-width: 100%;
  overflow: hidden;
`;

// Error message display - styled as a popup
const ErrorMessage = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 59, 48, 0.9);
  color: white;
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  border-radius: ${theme.borderRadius.md};
  z-index: 9999;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  animation: fadeInOut 2.5s forwards;
  max-width: 90%;
  text-align: center;
  
  @keyframes fadeInOut {
    0% { opacity: 0; transform: translate(-50%, -20px); }
    10% { opacity: 1; transform: translate(-50%, 0); }
    80% { opacity: 1; transform: translate(-50%, 0); }
    100% { opacity: 0; transform: translate(-50%, -20px); }
  }
`;

// Success message display - styled as a popup
const SuccessMessage = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(52, 199, 89, 0.9);
  color: white;
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  border-radius: ${theme.borderRadius.md};
  z-index: 9999;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  animation: fadeInOut 2s forwards;
  
  @keyframes fadeInOut {
    0% { opacity: 0; transform: translate(-50%, -20px); }
    10% { opacity: 1; transform: translate(-50%, 0); }
    80% { opacity: 1; transform: translate(-50%, 0); }
    100% { opacity: 0; transform: translate(-50%, -20px); }
  }
`;

export const NewBattle: React.FC = memo(function NewBattle() {
  const navigate = useNavigate();
  const { battleId } = useParams<{ battleId: string }>();
  const { user } = useAuthContext();
  const { haptic } = useTelegram();
  
  // Battle state
  const [battleData, setBattleData] = useState<BattleType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  
  // Robots state
  const [playerRobots, setPlayerRobots] = useState<Robot[]>([]);
  const [opponentRobots, setOpponentRobots] = useState<Robot[]>([]);
  
  // Battle actions state
  const [pendingActions, setPendingActions] = useState<Array<{
    sourceRobotId: string;
    targetRobotId: string;
    actionType: 'attack' | 'defend' | 'heal';
  }>>([]);
  
  // State for tab management
  const [activeTab, setActiveTab] = useState('arena');
  
  // WebSocket state
  const [opponentAction, setOpponentAction] = useState<any | null>(null);
  const [battleStarted, setBattleStarted] = useState<boolean>(false);
  const [turnNumber, setTurnNumber] = useState<number>(1);
  
  // Submission state
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submissionComplete, setSubmissionComplete] = useState<boolean>(false);
  
  // Create refs for ALL data that should not trigger re-renders
  const errorMessageElementRef = useRef<HTMLDivElement>(null);
  const successMessageElementRef = useRef<HTMLDivElement>(null);
  const errorMessageRef = useRef<string | null>(null);
  const successMessageRef = useRef<string | null>(null);
  const countdown = useRef<number>(60);
  const battleSocketRef = useRef<any>(null);
  const pendingActionsRef = useRef<Array<{
    sourceRobotId: string;
    targetRobotId: string;
    actionType: 'attack' | 'defend' | 'heal';
  }>>([]);
  const wsDataRef = useRef({
    opponentAction: null as any,
    turnNumber: 1,
    battleStarted: false,
    turnStartTimestamp: Date.now(),
    turnTimeLimit: 60
  });
  // Add ref to track navigation scheduling
  const hasScheduledNavigationRef = useRef<boolean>(false);
  
  // Load battle data
  const fetchBattleData = useCallback(async () => {
    if (!battleId) return;
    
    try {
      setLoading(true);
      
      const response = await api.getBattle(battleId);
      
      if (response.data) {
        // Load player and opponent robots in the real implementation
        // Mock data with detailed robot configurations - only 2 robots for the player
        const mockPlayerRobots = [
          {
            id: 'player-robot-1',
            name: 'Steelcrusher',
            type: 'attack',
            level: 3,
            energy: 85,
            maxEnergy: 100,
            armor: 65,
            maxArmor: 100,
            characteristics: {
              power: 85,
              speed: 65,
              durability: 50,
              memory: 40
            },
            slots: [
              { id: 'slot-1', item: 'power-core', locked: false },
              { id: 'slot-2', item: 'plasma-cannon', locked: false },
              { id: 'slot-3', item: null, locked: true }
            ]
          },
          {
            id: 'player-robot-2',
            name: 'Fortify MK3',
            type: 'defense',
            level: 4,
            energy: 92,
            maxEnergy: 100,
            armor: 88,
            maxArmor: 100,
            characteristics: {
              power: 45,
              speed: 35,
              durability: 95,
              memory: 55
            },
            slots: [
              { id: 'slot-1', item: 'shield-generator', locked: false },
              { id: 'slot-2', item: 'armor-plating', locked: false },
              { id: 'slot-3', item: 'force-field', locked: false }
            ]
          }
        ];
        
        const mockOpponentRobots = [
          {
            id: 'opponent-robot-1',
            name: 'Devastator X9',
            type: 'attack',
            level: 3,
            energy: 90,
            maxEnergy: 100,
            armor: 55,
            maxArmor: 100,
            characteristics: {
              power: 90,
              speed: 70,
              durability: 45,
              memory: 30
            },
            slots: [
              { id: 'slot-1', item: 'fusion-core', locked: false },
              { id: 'slot-2', item: 'missile-launcher', locked: false },
              { id: 'slot-3', item: 'targeting-system', locked: false }
            ]
          },
          {
            id: 'opponent-robot-2',
            name: 'Guardian Sentinel',
            type: 'defense',
            level: 4,
            energy: 65,
            maxEnergy: 100,
            armor: 95,
            maxArmor: 100,
            characteristics: {
              power: 40,
              speed: 30,
              durability: 95,
              memory: 50
            },
            slots: [
              { id: 'slot-1', item: 'reinforced-armor', locked: false },
              { id: 'slot-2', item: 'energy-shield', locked: false },
              { id: 'slot-3', item: 'reflective-coating', locked: false }
            ]
          },
          {
            id: 'opponent-robot-3',
            name: 'Technomancer',
            type: 'utility',
            level: 3,
            energy: 85,
            maxEnergy: 100,
            armor: 45,
            maxArmor: 100,
            characteristics: {
              power: 35,
              speed: 80,
              durability: 40,
              memory: 95
            },
            slots: [
              { id: 'slot-1', item: 'nano-repair', locked: false },
              { id: 'slot-2', item: 'energy-siphon', locked: false },
              { id: 'slot-3', item: 'hack-module', locked: false }
            ]
          }
        ];
        
        // Update all state values
        setBattleData(response.data);
        setPlayerRobots(mockPlayerRobots);
        setOpponentRobots(mockOpponentRobots);
        setLoading(false);
        setError(null);
      } else if (response.error) {
        setError(response.error);
        setLoading(false);
      }
    } catch (err) {
      // Handle error state
      const errorMessage = err instanceof Error ? err.message : String(err);
      setError(errorMessage);
      setLoading(false);
    }
  }, [battleId]);
  
  // Refs were moved to the top of the component
  
  // WebSocket event handlers - declare before useEffect and minimize state updates
  const handleWsBattleStart = useCallback((data: any) => {
    console.log('Battle started:', data);
    
    // Update refs directly without causing re-renders
    countdown.current = data.turnTimeLimit || 60;
    wsDataRef.current.battleStarted = true;
    
    // Update robot data if provided
    if (data.playerRobots && data.opponentRobots) {
      setPlayerRobots(data.playerRobots);
      setOpponentRobots(data.opponentRobots);
    }
    
    // Display success message using DOM manipulation
    if ((window as any).showBattleSuccess) {
      (window as any).showBattleSuccess('Battle started!');
    }
    
    // Only update state if it's changing to prevent unnecessary re-renders
    setBattleStarted((prevState: boolean) => {
      if (prevState === false) {
        return true;
      }
      return prevState; // Don't update if already true
    });
  }, [setPlayerRobots, setOpponentRobots]);
  
  const handleWsOpponentAction = useCallback((data: any) => {
    console.log('Opponent action:', data);
    
    // Update refs directly for non-visual data
    const actionData = data.actions[0]; // Just use the first action for simplicity
    const actionType = actionData.actionType;
    const sourceRobot = actionData.sourceRobotId.split('-').pop();
    const targetRobot = actionData.targetRobotId.split('-').pop();
    const message = `Opponent ${actionType}s: ${sourceRobot} → ${targetRobot}`;
    
    // Store in ref to avoid re-renders
    wsDataRef.current.opponentAction = actionData;
    
    // Update robot data if provided
    if (data.playerRobots && data.opponentRobots) {
      setPlayerRobots(data.playerRobots);
      setOpponentRobots(data.opponentRobots);
    }
    
    // Show success message using DOM manipulation
    if ((window as any).showBattleSuccess) {
      (window as any).showBattleSuccess(message);
    }
    
    // Only update state that actually affects UI rendering - avoid identical state updates
    setOpponentAction((prevAction: any) => {
      // Only update if it's a different action
      if (!prevAction || 
          prevAction.sourceRobotId !== actionData.sourceRobotId || 
          prevAction.targetRobotId !== actionData.targetRobotId ||
          prevAction.actionType !== actionData.actionType) {
        return actionData;
      }
      return prevAction;
    });
    
    // Exit waiting state after a short delay - but don't cause re-renders
    setTimeout(() => {
      haptic.impactOccurred('medium'); // Alert the player visually
      
      // Update submission status only if needed
      setSubmissionComplete((prevState: boolean) => prevState ? false : prevState);
    }, 2000);
  }, [haptic, setPlayerRobots, setOpponentRobots]);
  
  const handleWsTurnResult = useCallback((data: any) => {
    console.log('Turn result:', data);
    if (data.success) {
      // Always reset pending actions, even if there aren't any
      setPendingActions([]);
      pendingActionsRef.current = [];
      
      // Update robot data if provided
      if (data.playerRobots && data.opponentRobots) {
        setPlayerRobots(data.playerRobots);
        setOpponentRobots(data.opponentRobots);
        
        // Display health change information based on action results
        if (data.actionResults && data.actionResults.length > 0) {
          // Find a significant action to display
          const actionToShow = data.actionResults.find(
            (action: any) => action.result && 
            (action.result.type === 'damage' || action.result.type === 'heal')
          );
          
          if (actionToShow) {
            const { sourceRobotId, targetRobotId, actionType, result } = actionToShow;
            const sourceName = sourceRobotId.split('-').pop();
            const targetName = targetRobotId.split('-').pop();
            
            // Show more detailed message based on result
            let message = '';
            if (result.type === 'damage') {
              message = `${sourceName} damaged ${targetName} for ${result.amount} points!`;
            } else if (result.type === 'heal') {
              message = `${sourceName} healed ${targetName} for ${result.amount} points!`;
            }
            
            // Show the message if available
            if (message && (window as any).showBattleSuccess) {
              (window as any).showBattleSuccess(message);
            } else {
              // Fallback to basic message
              if ((window as any).showBattleSuccess) {
                (window as any).showBattleSuccess('Actions completed successfully');
              }
            }
          } else {
            // Fallback for no significant action
            if ((window as any).showBattleSuccess) {
              (window as any).showBattleSuccess('Turn completed');
            }
          }
        } else {
          // Generic success message if no action results
          if ((window as any).showBattleSuccess) {
            (window as any).showBattleSuccess('Actions submitted successfully');
          }
        }
      } else {
        // Generic success without robot updates
        if ((window as any).showBattleSuccess) {
          (window as any).showBattleSuccess('Actions submitted successfully');
        }
      }
      
      // Set turn submission complete
      setSubmissionComplete(true);
      
      // Vibrate to notify user
      haptic.impactOccurred('medium');
    } else {
      // Show error message using DOM manipulation
      if ((window as any).showBattleError) {
        (window as any).showBattleError(data.message || 'Failed to process turn');
      }
      
      // Reset submission state
      setSubmissionComplete(false);
      
      haptic.notificationOccurred('error');
    }
  }, [haptic, setPlayerRobots, setOpponentRobots]);
  
  const handleWsCountdown = useCallback((data: any) => {
    console.log('Countdown update:', data);
    
    // Update turn timestamp and limit in refs without causing re-render
    const turnStartTimestamp = data.turnStartTimestamp || Date.now();
    const turnTimeLimit = data.turnTimeLimit || 60;
    
    // Store the data in refs
    countdown.current = turnTimeLimit;
    wsDataRef.current.turnNumber = data.turnNumber;
    wsDataRef.current.turnStartTimestamp = turnStartTimestamp;
    wsDataRef.current.turnTimeLimit = turnTimeLimit;
    
    // Only update turn number in state if it changed
    if (data.turnNumber !== turnNumber) {
      // Reset actions for the new turn
      setPendingActions([]); 
      pendingActionsRef.current = [];
      
      // Update turn number
      setTurnNumber(data.turnNumber);
      
      // Set submission states
      setSubmitting(false);
      setSubmissionComplete(false);
      
      // Show turn start notification
      if ((window as any).showBattleSuccess) {
        (window as any).showBattleSuccess(`Turn ${data.turnNumber} started!`);
      }
    }
  }, [turnNumber]);
  
  const handleWsBattleEnd = useCallback((data: any) => {
    console.log('Battle ended:', data);
    
    // Update robot data if provided
    if (data.playerRobots && data.opponentRobots) {
      setPlayerRobots(data.playerRobots);
      setOpponentRobots(data.opponentRobots);
    }
    
    // Show more detailed battle end message
    if ((window as any).showBattleSuccess) {
      const message = data.winner === 'player-id' 
        ? 'Victory! You defeated all opponent robots!' 
        : 'Defeat! Your robots were destroyed!';
      
      (window as any).showBattleSuccess(message);
    }
    
    // Only schedule navigation once using our ref from above
    if (!hasScheduledNavigationRef.current) {
      hasScheduledNavigationRef.current = true;
      
      // Redirect to battles list after a delay
      setTimeout(() => {
        navigate('/battles');
      }, 5000); // Slightly longer delay to show final state
    }
  }, [navigate, setPlayerRobots, setOpponentRobots]);
  
  // Load data on component mount or when battleId changes
  useEffect(() => {
    if (battleId) {
      fetchBattleData();
    } else {
      setLoading(false);
    }
  }, [battleId, fetchBattleData]);
  
  // Setup WebSocket connection and handlers - separate from data loading
  useEffect(() => {
    if (!battleId) return;
    
    // Only set up WebSocket once per battleId
    if (!battleSocketRef.current) {
      console.log(`Setting up WebSocket connection for battle ${battleId}`);
      const battleSocket = getBattleWebSocket();
      
      // Setup WebSocket event listeners - this should happen only once
      battleSocket.on('battle_start', handleWsBattleStart);
      battleSocket.on('opponent_action', handleWsOpponentAction);
      battleSocket.on('turn_result', handleWsTurnResult);
      battleSocket.on('countdown', handleWsCountdown);
      battleSocket.on('battle_end', handleWsBattleEnd);
      
      // Connect and join the battle room
      battleSocket.connect(battleId);
      battleSocket.joinBattle(battleId);
      
      // Store in ref
      battleSocketRef.current = battleSocket;
      
      // Cleanup function
      return () => {
        console.log('Cleaning up WebSocket connection');
        if (battleSocketRef.current) {
          // Remove all event listeners first
          battleSocket.off('battle_start', handleWsBattleStart);
          battleSocket.off('opponent_action', handleWsOpponentAction);
          battleSocket.off('turn_result', handleWsTurnResult);
          battleSocket.off('countdown', handleWsCountdown);
          battleSocket.off('battle_end', handleWsBattleEnd);
          
          // Then disconnect
          battleSocketRef.current.leaveBattle();
          battleSocketRef.current = null;
        }
      };
    }
  }, [
    battleId,
    // Only include the handler functions in dependencies, not everything else
    handleWsBattleStart, 
    handleWsOpponentAction,
    handleWsTurnResult, 
    handleWsCountdown, 
    handleWsBattleEnd
  ]);
  
  // Handle robot action selection
  const handleRobotAction = useCallback((sourceRobotId: string, targetRobotId: string, actionType: 'attack' | 'defend' | 'heal') => {
    // Update using functional state update to avoid capturing stale pendingActions
    setPendingActions(currentActions => {
      // First, remove any existing action for this source robot (only one action per robot)
      const filteredActions = currentActions.filter(action => action.sourceRobotId !== sourceRobotId);
      
      // Add the new action
      return [
        ...filteredActions,
        { sourceRobotId, targetRobotId, actionType }
      ];
    });
    
    // Format source and target names to be shorter (just the robot names without IDs)
    const sourceName = sourceRobotId.split('-').pop() || sourceRobotId;
    const targetName = targetRobotId.split('-').pop() || targetRobotId;
    
    // Show success message with concise format via DOM manipulation
    if ((window as any).showBattleSuccess) {
      (window as any).showBattleSuccess(`${actionType.charAt(0).toUpperCase() + actionType.slice(1)}: ${sourceName} → ${targetName}`);
    }
    
    // Keep on arena tab since actions are now shown below
    setActiveTab('arena'); 
    
    // Update pendingActionsRef directly to avoid re-renders
    pendingActionsRef.current = [
      ...pendingActionsRef.current.filter(action => action.sourceRobotId !== sourceRobotId),
      { sourceRobotId, targetRobotId, actionType }
    ];
    
    // Give haptic feedback
    haptic.impactOccurred('medium');
  }, [haptic]);
  
  // Handle submitting all actions
  const handleSubmitActions = useCallback(async () => {
    // For timeout-triggered submissions, empty actions are allowed
    const isEmptySubmission = pendingActions.length === 0;
    
    // Only show error for manual submissions with no actions
    if (isEmptySubmission) {
      if ((window as any).showBattleError) {
        (window as any).showBattleError('No actions to submit');
      }
      return;
    }
    
    // Update submission status
    setSubmitting(true);
    
    try {
      // Use the stored WebSocket instance from our ref
      if (battleSocketRef.current) {
        // Send actions through WebSocket instead of API
        battleSocketRef.current.submitActions(pendingActions);
        
        // Show success message (only for non-empty submissions)
        if (!isEmptySubmission && (window as any).showBattleSuccess) {
          (window as any).showBattleSuccess('Turn submitted successfully!');
        }
        
        // Update state
        setSubmitting(false);
        setSubmissionComplete(true);
        
        // Give haptic feedback (only for non-empty submissions)
        if (!isEmptySubmission) {
          haptic.notificationOccurred('success');
        }
      } else {
        throw new Error('WebSocket not connected');
      }
    } catch (err) {
      // Show error message
      if ((window as any).showBattleError) {
        (window as any).showBattleError('Failed to submit actions');
      }
      haptic.notificationOccurred('error');
      
      // Update state
      setSubmitting(false);
    }
  }, [pendingActions, haptic]);
  
  // Handle turn timeout - must be declared after handleSubmitActions
  const handleTurnTimeout = useCallback(() => {
    console.log('Turn timeout triggered - auto-submitting actions');
    
    // Show timeout notification
    if ((window as any).showBattleError) {
      (window as any).showBattleError('Time ran out! Auto-submitting actions...');
    }
    haptic.notificationOccurred('error');
    
    // Submit directly to the server when timer runs out
    // Bypassing the normal submission function which has empty-check validation
    try {
      if (battleSocketRef.current) {
        // Submit whatever actions we have (even if empty)
        battleSocketRef.current.submitActions(pendingActions);
        
        // Update state
        setSubmitting(false);
        setSubmissionComplete(true);
      }
    } catch (err) {
      console.error('Failed to auto-submit on timeout:', err);
      setSubmitting(false);
    }
  }, [pendingActions, haptic]);
  
  // Handle battle start from request system
  const handleBattleStart = (newBattleId: string) => {
    navigate(`/new-battle/${newBattleId}`);
  };
  
  // Reset for next turn
  const handleNextTurn = () => {
    // Update state for next turn
    setSubmissionComplete(false);
    setOpponentAction(null);
    setTurnNumber(prevTurn => prevTurn + 1);
    setPendingActions([]);
  };
  
  // Direct DOM manipulation for notifications to prevent re-renders
  useEffect(() => {
    let errorTimeout: NodeJS.Timeout | null = null;
    let successTimeout: NodeJS.Timeout | null = null;
    
    // Function to show the error message
    const showError = (message: string) => {
      if (errorMessageElementRef.current) {
        // Clear any existing timeout
        if (errorTimeout) clearTimeout(errorTimeout);
        
        // Update and show the message
        errorMessageElementRef.current.textContent = message;
        errorMessageElementRef.current.style.display = 'block';
        
        // Auto-hide after a timeout
        errorTimeout = setTimeout(() => {
          if (errorMessageElementRef.current) {
            errorMessageElementRef.current.style.display = 'none';
          }
          errorTimeout = null;
        }, 3000);
      }
    };
    
    // Function to show the success message
    const showSuccess = (message: string) => {
      if (successMessageElementRef.current) {
        // Clear any existing timeout
        if (successTimeout) clearTimeout(successTimeout);
        
        // Update and show the message
        successMessageElementRef.current.textContent = message;
        successMessageElementRef.current.style.display = 'block';
        
        // Auto-hide after a timeout
        successTimeout = setTimeout(() => {
          if (successMessageElementRef.current) {
            successMessageElementRef.current.style.display = 'none';
          }
          successTimeout = null;
        }, 2000);
      }
    };
    
    // Set up event listeners for our custom notification events
    const handleErrorMessage = (e: CustomEvent) => showError(e.detail);
    const handleSuccessMessage = (e: CustomEvent) => showSuccess(e.detail);
    
    // Add event listeners
    window.addEventListener('showError' as any, handleErrorMessage);
    window.addEventListener('showSuccess' as any, handleSuccessMessage);
    
    // Expose functions to window for internal use
    (window as any).showBattleError = showError;
    (window as any).showBattleSuccess = showSuccess;
    
    // Clean up
    return () => {
      if (errorTimeout) clearTimeout(errorTimeout);
      if (successTimeout) clearTimeout(successTimeout);
      window.removeEventListener('showError' as any, handleErrorMessage);
      window.removeEventListener('showSuccess' as any, handleSuccessMessage);
      delete (window as any).showBattleError;
      delete (window as any).showBattleSuccess;
    };
  }, []);
  
  // Create DOM elements for messages without triggering re-renders
  const renderMessages = useCallback(() => {
    return (
      <>
        <ErrorMessage ref={errorMessageElementRef} style={{ display: 'none' }} />
        <SuccessMessage ref={successMessageElementRef} style={{ display: 'none' }} />
      </>
    );
  }, []);
  
  // No early returns - we'll handle different states in the main return
  
  // Memoize handlers for child components to prevent re-renders
  const memoizedHandlers = useMemo(() => ({
    onTurnTimeout: handleTurnTimeout,
    onNextTurn: handleNextTurn,
    onAction: handleRobotAction,
    onSubmitActions: handleSubmitActions
  }), [handleTurnTimeout, handleNextTurn, handleRobotAction, handleSubmitActions]);
  
  // Memoize the countdown bar to avoid unnecessary re-renders
  const memoizedCountdownBar = useMemo(() => {
    return (
      <CountdownBar 
        key={`turn-${turnNumber}`}
        startTimestamp={wsDataRef.current.turnStartTimestamp}
        turnTimeLimit={wsDataRef.current.turnTimeLimit}
        duration={60} // Fallback duration
        onComplete={handleTurnTimeout}
      />
    );
  }, [turnNumber, handleTurnTimeout]);

  const battleInterface = useMemo(() => {
    // Show loading state
    if (loading) {
      return (
        <BattleContainer>
          <LoadingIndicator message="Loading battle data..." />
        </BattleContainer>
      );
    }
    
    // Show battle request UI if no battleId is provided
    if (!battleId) {
      return (
        <BattleContainer>
          <SectionTitle>Battle Matchmaking</SectionTitle>
          <BattleRequestSystem onBattleStart={handleBattleStart} />
        </BattleContainer>
      );
    }
    
    // Return the battle UI
    return (
      <BattleContainer>
        {/* Use the memoized countdown bar */}
        {memoizedCountdownBar}
        
        {/* WebSocket connection indicator */}
        <div style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          fontSize: '12px',
          color: theme.colors.success,
          display: 'flex',
          alignItems: 'center',
          gap: '4px'
        }}>
          <span style={{ fontSize: '10px' }}>●</span> Live
        </div>
        
        {/* Battle interface tabs with emoji icons */}
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '12px',
          gap: '2px'
        }}>
          <div 
            style={{
              padding: '8px 16px',
              backgroundColor: activeTab === 'arena' ? theme.colors.primary : theme.colors.background.secondary,
              borderRadius: '4px 0 0 0',
              fontSize: '14px',
              fontWeight: activeTab === 'arena' ? 'bold' : 'normal',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '4px'
            }}
            onClick={() => setActiveTab('arena')}
          >
            <span role="img" aria-label="arena">⚔️</span> Arena
          </div>
          <div
            style={{
              padding: '8px 16px',
              backgroundColor: activeTab === 'actions' ? theme.colors.primary : theme.colors.background.secondary,
              borderRadius: '0',
              fontSize: '14px',
              fontWeight: activeTab === 'actions' ? 'bold' : 'normal',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '4px'
            }}
            onClick={() => setActiveTab('actions')}
          >
            <span role="img" aria-label="battle log">📜</span> Battle Log
          </div>
          <div
            style={{
              padding: '8px 16px',
              backgroundColor: activeTab === 'settings' ? theme.colors.primary : theme.colors.background.secondary,
              borderRadius: '0 4px 0 0',
              fontSize: '14px',
              fontWeight: activeTab === 'settings' ? 'bold' : 'normal',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '4px'
            }}
            onClick={() => setActiveTab('settings')}
          >
            <span role="img" aria-label="settings">⚙️</span> Settings
          </div>
        </div>
        
        {/* Battle arena or actions based on active tab */}
        <Section>
          {activeTab === 'arena' ? (
            <>
              <BattleArena 
                playerRobots={playerRobots}
                opponentRobots={opponentRobots}
                onAction={handleRobotAction}
                key="battle-arena" // Keep stable key to avoid re-mounting
              />
              
              {/* Special abilities menu inside the battle UI - grayed out since not supported */}
              <div style={{
                padding: '12px',
                backgroundColor: theme.colors.background.secondary,
                borderRadius: theme.borderRadius.lg,
                marginTop: '16px',
                opacity: 0.6, // Gray out the entire section
                position: 'relative'
              }}>
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  borderRadius: theme.borderRadius.lg,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 2
                }}>
                  <div style={{
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    padding: '6px 12px',
                    borderRadius: '4px',
                    fontSize: '12px'
                  }}>
                    Coming soon
                  </div>
                </div>
                
                <h3 style={{ 
                  margin: '0 0 12px 0', 
                  fontSize: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px',
                  position: 'relative',
                  zIndex: 1
                }}>
                  <span role="img" aria-label="special">✨</span> Special Abilities
                </h3>
                
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  padding: '4px 0 8px',
                  position: 'relative',
                  zIndex: 1
                }}>
                  {/* Energy Boost */}
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '4px'
                  }}>
                    <div style={{
                      width: '44px',
                      height: '44px',
                      backgroundColor: '#e74c3c',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '20px'
                    }}>
                      ⚡
                    </div>
                    <span style={{ fontSize: '10px' }}>Boost</span>
                  </div>
                  
                  {/* Shield */}
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '4px'
                  }}>
                    <div style={{
                      width: '44px',
                      height: '44px',
                      backgroundColor: '#3498db',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '20px'
                    }}>
                      🛡️
                    </div>
                    <span style={{ fontSize: '10px' }}>Shield</span>
                  </div>
                  
                  {/* EMP */}
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '4px'
                  }}>
                    <div style={{
                      width: '44px',
                      height: '44px',
                      backgroundColor: '#9b59b6',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '20px'
                    }}>
                      💥
                    </div>
                    <span style={{ fontSize: '10px' }}>EMP</span>
                  </div>
                  
                  {/* Heal */}
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '4px'
                  }}>
                    <div style={{
                      width: '44px',
                      height: '44px',
                      backgroundColor: '#2ecc71',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '20px'
                    }}>
                      💞
                    </div>
                    <span style={{ fontSize: '10px' }}>Heal</span>
                  </div>
                  
                  {/* Hack */}
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '4px'
                  }}>
                    <div style={{
                      width: '44px',
                      height: '44px',
                      backgroundColor: '#f39c12',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '20px'
                    }}>
                      🔓
                    </div>
                    <span style={{ fontSize: '10px' }}>Hack</span>
                  </div>
                  
                  {/* Overcharge */}
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '4px'
                  }}>
                    <div style={{
                      width: '44px',
                      height: '44px',
                      backgroundColor: '#1abc9c',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '20px'
                    }}>
                      🔋
                    </div>
                    <span style={{ fontSize: '10px' }}>Charge</span>
                  </div>
                </div>
              </div>
              
              {/* Current turn actions displayed below the special abilities */}
              <div style={{
                padding: '16px',
                backgroundColor: theme.colors.background.secondary,
                borderRadius: theme.borderRadius.lg,
                marginTop: '16px'
              }}>
                <h3 style={{ 
                  margin: '0 0 12px 0', 
                  fontSize: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px'
                }}>
                  <span role="img" aria-label="current">⚡</span> Current Turn Actions
                </h3>
                
                {pendingActions.length > 0 ? (
                  <div>
                    {pendingActions.map((action, index) => {
                      const sourceName = action.sourceRobotId.split('-').pop() || action.sourceRobotId;
                      const targetName = action.targetRobotId.split('-').pop() || action.targetRobotId;
                      
                      return (
                        <div key={index} style={{
                          padding: '8px',
                          margin: '4px 0',
                          backgroundColor: 'rgba(0,0,0,0.2)',
                          borderRadius: '4px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px'
                          }}>
                            {action.actionType === 'attack' ? '⚔️' : 
                             action.actionType === 'defend' ? '🛡️' : '🧪'}
                            <span>{action.actionType.charAt(0).toUpperCase() + action.actionType.slice(1)}</span>
                          </div>
                          <span>{sourceName} → {targetName}</span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div style={{
                    textAlign: 'center',
                    padding: '16px 0',
                    color: theme.colors.text.secondary
                  }}>
                    No actions selected yet. Select robot actions above.
                  </div>
                )}
              </div>
            </>
          ) : activeTab === 'actions' ? (
            <div style={{
              backgroundColor: theme.colors.background.secondary,
              borderRadius: theme.borderRadius.lg,
              padding: 0, // Remove padding to give maximum space to battle logs
              minHeight: '400px',
              overflow: 'hidden' // Ensure no double scrollbars
            }}>
              {/* Complete Battle Log including all turns */}
              <BattleLogs 
                battleId={battleId} 
                currentTurn={turnNumber}
                key={`logs-${battleId}-${turnNumber}`} // Add key to force re-render when turnNumber changes
              />
            </div>
          ) : (
            // Settings tab content
            <div style={{
              padding: '16px',
              backgroundColor: theme.colors.background.secondary,
              borderRadius: theme.borderRadius.lg,
              minHeight: '200px'
            }}>
              <h3 style={{ margin: '0 0 12px 0', fontSize: '16px' }}>Battle Settings</h3>
              
              <div style={{ marginBottom: '16px' }}>
                <div style={{ 
                  marginBottom: '8px', 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center' 
                }}>
                  <span>Sound effects</span>
                  <div style={{ 
                    width: '44px', 
                    height: '24px', 
                    backgroundColor: theme.colors.success, 
                    borderRadius: '12px',
                    position: 'relative',
                    cursor: 'pointer'
                  }}>
                    <div style={{
                      position: 'absolute',
                      right: '4px',
                      top: '4px',
                      width: '16px',
                      height: '16px',
                      backgroundColor: 'white',
                      borderRadius: '50%'
                    }}></div>
                  </div>
                </div>
                
                <div style={{ 
                  marginBottom: '8px', 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center' 
                }}>
                  <span>Vibration</span>
                  <div style={{ 
                    width: '44px', 
                    height: '24px', 
                    backgroundColor: theme.colors.success, 
                    borderRadius: '12px',
                    position: 'relative',
                    cursor: 'pointer'
                  }}>
                    <div style={{
                      position: 'absolute',
                      right: '4px',
                      top: '4px',
                      width: '16px',
                      height: '16px',
                      backgroundColor: 'white',
                      borderRadius: '50%'
                    }}></div>
                  </div>
                </div>
                
                <div style={{ 
                  marginBottom: '8px', 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center' 
                }}>
                  <span>Auto-submit when all actions selected</span>
                  <div style={{ 
                    width: '44px', 
                    height: '24px', 
                    backgroundColor: theme.colors.background.tertiary, 
                    borderRadius: '12px',
                    position: 'relative',
                    cursor: 'pointer'
                  }}>
                    <div style={{
                      position: 'absolute',
                      left: '4px',
                      top: '4px',
                      width: '16px',
                      height: '16px',
                      backgroundColor: 'white',
                      borderRadius: '50%'
                    }}></div>
                  </div>
                </div>
              </div>
              
              <div style={{ marginTop: '24px' }}>
                <button
                  onClick={() => {
                    if (window.confirm('Are you sure you want to leave this battle? All progress will be lost.')) {
                      navigate('/battles');
                    }
                  }}
                  style={{
                    backgroundColor: theme.colors.danger,
                    color: theme.colors.text.primary,
                    border: 'none',
                    borderRadius: '4px',
                    padding: '8px 16px',
                    cursor: 'pointer',
                    width: '100%'
                  }}
                >
                  Leave Battle
                </button>
              </div>
            </div>
          )}
        </Section>
        
        {/* Fixed action button at the bottom */}
        <div style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: theme.colors.background.primary,
          padding: '12px',
          display: 'flex',
          justifyContent: 'space-around',
          borderTop: `1px solid ${theme.colors.border.default}`,
          zIndex: 100
        }}>
          {pendingActions.length > 0 ? (
            <button
              onClick={handleSubmitActions}
              disabled={submitting || submissionComplete}
              style={{
                backgroundColor: submitting || submissionComplete ? theme.colors.disabled : theme.colors.success,
                color: theme.colors.text.primary,
                border: 'none',
                borderRadius: '4px',
                padding: '12px 24px',
                cursor: submitting || submissionComplete ? 'default' : 'pointer',
                width: '80%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                fontWeight: 'bold'
              }}
            >
              {submitting ? 'Submitting...' : submissionComplete ? 
                'Waiting for opponent...' : 
                <>
                  <span role="img" aria-label="submit">✅</span>
                  Submit {pendingActions.length} Action{pendingActions.length > 1 ? 's' : ''}
                </>
              }
            </button>
          ) : (
            <div style={{
              backgroundColor: 'rgba(255,255,255,0.1)',
              color: theme.colors.text.secondary,
              border: 'none',
              borderRadius: '4px',
              padding: '12px 24px',
              width: '80%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px'
            }}>
              <span role="img" aria-label="info">ℹ️</span>
              Select robot actions in Arena tab
            </div>
          )}
        </div>
      </BattleContainer>
    );
  }, [
    loading,
    battleId,
    handleBattleStart,
    handleTurnTimeout,
    handleRobotAction,
    handleSubmitActions,
    playerRobots,
    opponentRobots,
    activeTab,
    turnNumber,
    pendingActions,
    submitting,
    submissionComplete,
    navigate
  ]);
  
  return (
    <>
      {/* Show popups outside the main container for fixed positioning */}
      {renderMessages()}
      {battleInterface}
    </>
  );
});

export default NewBattle;