import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { DefaultTheme } from 'styled-components';
import { originalTheme, darkTheme } from '../style/theme';

export type ThemeType = 'original' | 'dark';

interface ThemeContextType {
  currentTheme: ThemeType;
  setCurrentTheme: (theme: ThemeType) => void;
  toggleTheme: () => void;
}

const defaultContextValue: ThemeContextType = {
  currentTheme: 'original',
  setCurrentTheme: () => {},
  toggleTheme: () => {},
};

export const ThemeContext = createContext<ThemeContextType>(defaultContextValue);

export const useTheme = () => useContext(ThemeContext);

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<ThemeType>('original');

  // Load saved theme from localStorage on initial load
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') as ThemeType;
    if (savedTheme && (savedTheme === 'original' || savedTheme === 'dark')) {
      setCurrentTheme(savedTheme);
    }
  }, []);

  // Save theme preference when it changes
  useEffect(() => {
    localStorage.setItem('theme', currentTheme);
  }, [currentTheme]);

  // Function to toggle between themes
  const toggleTheme = () => {
    setCurrentTheme(prev => prev === 'original' ? 'dark' : 'original');
  };

  // Get the actual theme object based on the selected theme
  const getThemeObject = () => {
    return currentTheme === 'dark' ? darkTheme : originalTheme;
  };

  return (
    <ThemeContext.Provider
      value={{
        currentTheme,
        setCurrentTheme,
        toggleTheme,
      }}
    >
      <StyledThemeProvider theme={getThemeObject()}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};