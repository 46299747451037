import React, { useState, useEffect } from 'react';
import styles from "../style/Battle.module.css";
import { useGlobalContext } from '../GlobalContext';
import {Link, Route, Routes} from "react-router-dom";
import Battle from "../Batlle";

// https://impol.link/api
const API_URL = process.env.REACT_APP_API_URL;

interface BattleHistoryProps {
    userId: number;
}
const BattleHistory: React.FC<BattleHistoryProps> = ({ userId }) => {
    const [history, setHistory] = useState([]);
    const token = localStorage.getItem('token');

    const fetchBattleHistory = async () => {
        if (token) {
            const response = await fetch(API_URL + `/user/${userId}/battle-history`, {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            console.log("Fetched battle:", data.battles); // Debug log
            setHistory(data.battles);
        }
    };

    useEffect(() => {
        const loadBattleHistory = async () => {
            try {
                console.log("fetching battle history");
                await fetchBattleHistory();
            } catch (error) {
                console.error("Error fetching logs:", error);
            }
        };

        loadBattleHistory().catch(error => {
            console.error("Error in loadLogs:", error);
        });

    }, []);

    return (
        <div className={styles.battleHistoryContainer}>
            <h2>Battle history</h2>
            <Routes>
                {history.map((battle: any, index) => (
                    <Route
                        key={index}
                        path={`/battle/${battle.battle_id}`}
                        element={<Battle/>}
                    />
                ))}
            </Routes>

            <ul>
                {history.map((battle: any, index) => (
                    <li key={index}>
                        <Link to={`/battle/${battle.battle_id}`}>Battle {battle.battle_id}</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default BattleHistory;