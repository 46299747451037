import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import { api } from '../../services/api';
import styles from '../../style/Battles.module.css';
import { LoadingIndicator } from '../../components/ui/LoadingScreen';
import BattleRequestSystem from './components/BattleRequestSystem';

interface BattleItem {
  id: string;
  status: string;
  participants: string[];
  createdAt: string;
}

export const Battles: React.FC = () => {
  const [battles, setBattles] = useState<BattleItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { userId } = useAuthContext();
  const navigate = useNavigate();

  // Check for active battles on load
  useEffect(() => {
    const fetchActiveBattles = async () => {
      setLoading(true);
      try {
        // Check active battles from the mock store
        const response = await api.checkActiveBattle();
        
        if (response.data) {
          if (response.data.isInBattle && response.data.battleId) {
            // If there's an active battle, add it to the list
            setBattles([{
              id: response.data.battleId,
              status: 'active',
              participants: [userId || '', 'opponent'],
              createdAt: new Date().toISOString()
            }]);
          } else {
            // No active battles
            setBattles([]);
          }
        }
      } catch (err) {
        console.error("Error checking active battles:", err);
        setError("Failed to load active battles");
      } finally {
        setLoading(false);
      }
    };

    fetchActiveBattles();
  }, [userId]);

  // Handle starting a battle from the BattleRequestSystem
  const handleBattleStart = (battleId: string) => {
    navigate(`/new-battle/${battleId}`);
  };

  // Display a placeholder when loading instead of full-screen overlay
  if (loading) {
    return (
      <div className={styles.battlesContainer}>
        <LoadingIndicator message="Loading battles..." />
        <h1 className={styles.title}>Battles</h1>
        <div className={styles.loadingPlaceholder}>
          <p>Loading battle data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className={styles.error}>Error: {error}</div>;
  }

  return (
    <div className={styles.battlesContainer}>
      <h1 className={styles.title}>Battles</h1>
      
      {/* Battle Request System */}
      <BattleRequestSystem onBattleStart={handleBattleStart} />
    </div>
  );
};

export default Battles;