import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../style/theme';

type CardVariant = 'default' | 'elevated' | 'outlined';
type CardPadding = 'none' | 'sm' | 'md' | 'lg';

interface BaseCardProps {
  variant?: CardVariant;
  padding?: CardPadding;
  onClick?: () => void;
  className?: string;
  children: React.ReactNode;
}

interface StandardCardProps extends BaseCardProps {
  to?: never;
}

interface LinkCardProps extends BaseCardProps {
  to: string;
}

type CardProps = StandardCardProps | LinkCardProps;

// Function to determine if the props are for a Link card
const isLinkCard = (props: CardProps): props is LinkCardProps => {
  return 'to' in props && props.to !== undefined;
};

const StyledCard = styled.div<Omit<BaseCardProps, 'children'>>`
  border-radius: ${theme.borderRadius.lg};
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  
  ${props => {
    switch (props.variant) {
      case 'elevated':
        return css`
          background-color: ${theme.colors.background.secondary};
          box-shadow: ${theme.shadows.md};
          border: none;
        `;
      case 'outlined':
        return css`
          background-color: transparent;
          border: 1px solid ${theme.colors.border.default};
        `;
      case 'default':
      default:
        return css`
          background-color: ${theme.colors.background.secondary};
          border: none;
        `;
    }
  }}
  
  ${props => {
    switch (props.padding) {
      case 'none':
        return css`padding: 0;`;
      case 'sm':
        return css`padding: ${theme.spacing.sm};`;
      case 'lg':
        return css`padding: ${theme.spacing.lg};`;
      case 'md':
      default:
        return css`padding: ${theme.spacing.md};`;
    }
  }}
  
  ${props => props.onClick && css`
    cursor: pointer;
    transition: transform ${theme.animation.fast}, box-shadow ${theme.animation.fast};
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: ${theme.shadows.lg};
    }
    
    &:active {
      transform: translateY(0);
    }
  `}
`;

const StyledLinkCard = styled(Link)<Omit<BaseCardProps, 'children'>>`
  border-radius: ${theme.borderRadius.lg};
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  
  ${props => {
    switch (props.variant) {
      case 'elevated':
        return css`
          background-color: ${theme.colors.background.secondary};
          box-shadow: ${theme.shadows.md};
          border: none;
        `;
      case 'outlined':
        return css`
          background-color: transparent;
          border: 1px solid ${theme.colors.border.default};
        `;
      case 'default':
      default:
        return css`
          background-color: ${theme.colors.background.secondary};
          border: none;
        `;
    }
  }}
  
  ${props => {
    switch (props.padding) {
      case 'none':
        return css`padding: 0;`;
      case 'sm':
        return css`padding: ${theme.spacing.sm};`;
      case 'lg':
        return css`padding: ${theme.spacing.lg};`;
      case 'md':
      default:
        return css`padding: ${theme.spacing.md};`;
    }
  }}
  
  cursor: pointer;
  transition: transform ${theme.animation.fast}, box-shadow ${theme.animation.fast};
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: ${theme.shadows.lg};
  }
  
  &:active {
    transform: translateY(0);
  }
`;

export const Card: React.FC<CardProps> = (props) => {
  const { 
    children, 
    variant = 'default', 
    padding = 'md', 
    onClick,
    className,
    ...rest 
  } = props;
  
  if ('to' in props && typeof props.to === 'string') {
    const { to, ...linkRest } = rest as LinkCardProps;
    return (
      <StyledLinkCard 
        to={to}
        variant={variant}
        padding={padding}
        onClick={onClick}
        className={className}
        {...linkRest as any}
      >
        {children}
      </StyledLinkCard>
    );
  }
  
  return (
    <StyledCard
      variant={variant}
      padding={padding}
      onClick={onClick}
      className={className}
      {...rest}
    >
      {children}
    </StyledCard>
  );
};

interface CardTitleProps {
  children: React.ReactNode;
  className?: string;
}

const StyledCardTitle = styled.h3`
  font-size: ${theme.fontSizes.lg};
  font-weight: 600;
  margin: 0 0 ${theme.spacing.sm} 0;
  color: ${theme.colors.text.primary};
`;

export const CardTitle: React.FC<CardTitleProps> = ({ children, className }) => (
  <StyledCardTitle className={className}>{children}</StyledCardTitle>
);

interface CardContentProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const StyledCardContent = styled.div`
  color: ${theme.colors.text.secondary};
`;

export const CardContent: React.FC<CardContentProps> = ({ children, className, style }) => (
  <StyledCardContent className={className} style={style}>{children}</StyledCardContent>
);

interface CardActionsProps {
  children: React.ReactNode;
  className?: string;
  align?: 'start' | 'center' | 'end' | 'space-between';
}

const StyledCardActions = styled.div<{ align?: string }>`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.md};
  gap: ${theme.spacing.sm};
  
  ${props => {
    switch (props.align) {
      case 'start':
        return css`justify-content: flex-start;`;
      case 'center':
        return css`justify-content: center;`;
      case 'end':
        return css`justify-content: flex-end;`;
      case 'space-between':
      default:
        return css`justify-content: space-between;`;
    }
  }}
`;

export const CardActions: React.FC<CardActionsProps> = ({ 
  children, 
  className, 
  align = 'space-between' 
}) => (
  <StyledCardActions className={className} align={align}>{children}</StyledCardActions>
);

export default Object.assign(Card, {
  Title: CardTitle,
  Content: CardContent,
  Actions: CardActions,
});