import React from 'react';
import styled, { css } from 'styled-components';
import theme from '../../style/theme';

type BadgeVariant = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light';
type BadgeSize = 'sm' | 'md' | 'lg';

interface BadgeProps {
  variant?: BadgeVariant;
  size?: BadgeSize;
  pill?: boolean;
  outline?: boolean;
  children: React.ReactNode;
  className?: string;
}

const BadgeContainer = styled.span<{
  variant: BadgeVariant;
  size: BadgeSize;
  pill: boolean;
  outline: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  
  ${props => props.pill ? css`
    border-radius: ${theme.borderRadius.full};
  ` : css`
    border-radius: ${theme.borderRadius.sm};
  `}
  
  ${props => {
    switch (props.size) {
      case 'sm':
        return css`
          font-size: ${theme.fontSizes.xs};
          padding: 0.15rem 0.4rem;
        `;
      case 'lg':
        return css`
          font-size: ${theme.fontSizes.md};
          padding: 0.35rem 0.7rem;
        `;
      case 'md':
      default:
        return css`
          font-size: ${theme.fontSizes.sm};
          padding: 0.25rem 0.5rem;
        `;
    }
  }}
  
  ${props => {
    let color;
    let bgColor;
    let borderColor;
    
    switch (props.variant) {
      case 'secondary':
        color = props.outline ? theme.colors.secondary : theme.colors.text.primary;
        bgColor = props.outline ? 'transparent' : theme.colors.secondary;
        borderColor = theme.colors.secondary;
        break;
      case 'success':
        color = props.outline ? theme.colors.success : theme.colors.text.primary;
        bgColor = props.outline ? 'transparent' : theme.colors.success;
        borderColor = theme.colors.success;
        break;
      case 'danger':
        color = props.outline ? theme.colors.danger : theme.colors.text.primary;
        bgColor = props.outline ? 'transparent' : theme.colors.danger;
        borderColor = theme.colors.danger;
        break;
      case 'warning':
        color = props.outline ? theme.colors.warning : theme.colors.background.primary;
        bgColor = props.outline ? 'transparent' : theme.colors.warning;
        borderColor = theme.colors.warning;
        break;
      case 'info':
        color = props.outline ? theme.colors.info : theme.colors.text.primary;
        bgColor = props.outline ? 'transparent' : theme.colors.info;
        borderColor = theme.colors.info;
        break;
      case 'dark':
        color = props.outline ? theme.colors.text.primary : theme.colors.text.primary;
        bgColor = props.outline ? 'transparent' : theme.colors.background.secondary;
        borderColor = theme.colors.background.secondary;
        break;
      case 'light':
        color = props.outline ? theme.colors.text.tertiary : theme.colors.background.primary;
        bgColor = props.outline ? 'transparent' : theme.colors.text.tertiary;
        borderColor = theme.colors.text.tertiary;
        break;
      case 'primary':
      default:
        color = props.outline ? theme.colors.primary : theme.colors.text.primary;
        bgColor = props.outline ? 'transparent' : theme.colors.primary;
        borderColor = theme.colors.primary;
        break;
    }
    
    return css`
      color: ${color};
      background-color: ${bgColor};
      border: ${props.outline ? `1px solid ${borderColor}` : 'none'};
    `;
  }}
`;

export const Badge: React.FC<BadgeProps> = ({
  variant = 'primary',
  size = 'md',
  pill = false,
  outline = false,
  children,
  className,
}) => {
  return (
    <BadgeContainer
      variant={variant}
      size={size}
      pill={pill}
      outline={outline}
      className={className}
    >
      {children}
    </BadgeContainer>
  );
};

export default Badge;