import React, { memo } from 'react';
import styles from '../../style/HealthBar.module.css';

interface HealthBarProps {
  currentHealth: number;
  maxHealth: number;
  label?: string;
  showPercentage?: boolean;
  size?: 'normal' | 'tiny';
  color?: string; // Custom color override
}

export const HealthBar: React.FC<HealthBarProps> = memo(({
  currentHealth,
  maxHealth,
  label,
  showPercentage = true,
  size = 'normal',
  color
}) => {
  const healthPercentage = Math.max(0, Math.min(100, (currentHealth / maxHealth) * 100));
  
  // Use custom color if provided, otherwise use default colors based on percentage
  let barColor = color || 'green';
  if (!color) {
    if (healthPercentage <= 33) {
      barColor = 'red';
    } else if (healthPercentage <= 66) {
      barColor = 'yellow';
    }
  }
  
  const containerClass = size === 'tiny' 
    ? `${styles.healthBarContainer} ${styles.tiny}`
    : styles.healthBarContainer;
  
  return (
    <div className={containerClass}>
      {label && <div className={styles.healthLabel}>{label}</div>}
      <div className={styles.healthBarOuter}>
        <div 
          className={styles.healthBarInner}
          style={{ 
            width: `${healthPercentage}%`, 
            backgroundColor: barColor 
          }}
        />
      </div>
      {showPercentage && size !== 'tiny' && (
        <div className={styles.healthText}>
          {currentHealth}/{maxHealth} ({Math.round(healthPercentage)}%)
        </div>
      )}
      {size === 'tiny' && (
        <div className={styles.tinyHealthText}>
          {currentHealth}/{maxHealth}
        </div>
      )}
    </div>
  );
});

export default memo(HealthBar);