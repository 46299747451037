import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { api } from '../../../services/api';
import theme from '../../../style/theme';

interface BattleLogsProps {
  battleId: string;
  pollingInterval?: number; // in ms
  currentTurn?: number;
}

interface LogEntry {
  id?: string;
  result: string;
  timestamp: string;
  turn?: number;
}

const LogsContainer = styled.div`
  background-color: ${theme.colors.background.secondary};
  border-radius: ${theme.borderRadius.md};
  padding: ${theme.spacing.md};
  margin-bottom: ${theme.spacing.md};
  height: calc(65vh - 80px); /* Use viewport height instead of fixed height */
  min-height: 400px; /* Set minimum height to ensure it's always reasonably sized */
  overflow-y: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  
  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: ${theme.colors.background.secondary};
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.background.tertiary};
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: ${theme.colors.primary};
  }
`;

const LogsHeader = styled.h3`
  margin-top: 0;
  margin-bottom: ${theme.spacing.md};
  font-size: ${theme.fontSizes.lg};
  color: ${theme.colors.text.primary};
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px 0;
  border-bottom: 1px solid ${theme.colors.border.default};
  
  &::before {
    content: "📜";
    margin-right: 8px;
    font-size: 1.2em;
  }
`;

const LogsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
`;

const LogEntryItem = styled.div`
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  border-radius: ${theme.borderRadius.sm};
  background-color: ${theme.colors.background.tertiary};
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.text.secondary};
  position: relative;
  border-left: 3px solid ${theme.colors.primary};
  transition: all 0.2s ease;
  line-height: 1.4;
  
  &:hover {
    background-color: rgba(52, 152, 219, 0.1);
    transform: translateX(2px);
  }
  
  /* Different colors for different log types */
  &:nth-child(odd) {
    border-left-color: ${theme.colors.secondary};
  }
  
  &::before {
    content: '•';
    margin-right: 6px;
    opacity: 0.5;
  }
`;

const LogTimestamp = styled.small`
  color: ${theme.colors.text.tertiary};
  font-size: ${theme.fontSizes.xs};
  display: block;
  margin-top: ${theme.spacing.xs};
`;

const LogsLoading = styled.div`
  padding: ${theme.spacing.md};
  text-align: center;
  color: ${theme.colors.text.secondary};
`;

const LogsError = styled.div`
  padding: ${theme.spacing.md};
  text-align: center;
  color: ${theme.colors.danger};
`;

const EmptyLogs = styled.div`
  padding: ${theme.spacing.md};
  text-align: center;
  color: ${theme.colors.text.secondary};
  font-style: italic;
`;

const TurnSeparator = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  text-align: center;
  font-weight: bold;
  color: ${theme.colors.text.primary};
  font-size: ${theme.fontSizes.sm};
  
  &::before, &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${theme.colors.border.default};
    margin: 0 10px;
  }
`;

const TurnContainer = styled.div`
  margin-bottom: 24px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: ${theme.borderRadius.sm};
  padding: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  animation: fadeIn 0.3s ease-in-out;
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  &:last-child {
    margin-bottom: 0;
  }
  
  &:first-child {
    border-left: 4px solid ${theme.colors.primary};
  }
`;

const TurnHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

export const BattleLogs: React.FC<BattleLogsProps> = ({ 
  battleId,
  pollingInterval = 30000, // Default to 30 seconds to reduce API calls
  currentTurn = 1
}) => {
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isTabVisible, setIsTabVisible] = useState(true);
  const logsContainerRef = useRef<HTMLDivElement>(null);
  
  // Setup visibility tracking to avoid unnecessary API calls
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabVisible(!document.hidden);
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  
  // Use a ref to track previous turn to avoid unnecessary fetches
  const prevTurnRef = useRef<number>(currentTurn);
  
  // Use a ref to track initial rendering state instead of depending on logs.length
  const isInitialFetch = useRef(true);
  
  const fetchLogs = useCallback(async () => {
    if (!battleId) return;
    
    console.log(`Fetching logs for battle: ${battleId}, current turn: ${currentTurn}`);
    
    // Only show loading state on initial load
    if (isInitialFetch.current) {
      setLoading(true);
      isInitialFetch.current = false;
    }
    
    try {
      // Attempt to get real logs from the API
      const response = await api.getBattleLogs(battleId);
      
      if (response.data) {
        console.log(`Received logs:`, response.data);
        
        // Process logs and add turn numbers if not present
        const processedLogs = Array.isArray(response.data) 
          ? response.data.map((log, index, array) => {
              // Convert string logs to objects with timestamps
              const logEntry = typeof log === 'string' 
                ? { result: log, timestamp: new Date().toISOString() } 
                : log;
              
              // Infer turn numbers from log content or position
              let turn = logEntry.turn;
              if (!turn) {
                // Try to extract turn number from log text
                const turnMatch = logEntry.result.match(/Round (\d+)/i) || 
                                 logEntry.result.match(/Turn (\d+)/i);
                                 
                if (turnMatch) {
                  turn = parseInt(turnMatch[1], 10);
                } else if (logEntry.result.includes('Battle started')) {
                  turn = 1;
                } else if (index > 0) {
                  // Look at previous logs to determine turn
                  const prevLog = array[index - 1];
                  if (typeof prevLog === 'object' && prevLog.turn) {
                    // Check if this is a turn transition
                    if (logEntry.result.includes('completes turn') || 
                        logEntry.result.includes('Round') || 
                        logEntry.result.includes('Turn')) {
                      turn = (prevLog.turn as number) + 1;
                    } else {
                      turn = prevLog.turn;
                    }
                  }
                }
                
                // Default to turn 1 if we couldn't determine
                turn = turn || 1;
              }
              
              return { ...logEntry, turn };
            })
          : [];
          
        setLogs(processedLogs);
      } else if (response.error) {
        // If there's an error or this is a mock battle, use mock logs
        if (battleId.startsWith('battle-') || battleId === 'mock') {
          // Provide mock battle logs with turn information for demonstration
          const mockLogs = [
            { 
              id: '1', 
              result: 'Battle started! Player1 vs Opponent1', 
              timestamp: new Date(Date.now() - 300000).toISOString(),
              turn: 1
            },
            { 
              id: '2', 
              result: 'Steelcrusher attacks Devastator X9 for 35 damage!', 
              timestamp: new Date(Date.now() - 290000).toISOString(),
              turn: 1 
            },
            { 
              id: '3', 
              result: 'Guardian Sentinel defends against attack, reducing damage by 60%', 
              timestamp: new Date(Date.now() - 280000).toISOString(),
              turn: 1
            },
            { 
              id: '4', 
              result: 'Turn 1 complete', 
              timestamp: new Date(Date.now() - 260000).toISOString(),
              turn: 1
            },
            { 
              id: '5', 
              result: 'Round 2 begins! Make your moves', 
              timestamp: new Date(Date.now() - 240000).toISOString(),
              turn: 2 
            },
            { 
              id: '6', 
              result: 'MediTron repairs Fortify MK3, restoring 25 energy points', 
              timestamp: new Date(Date.now() - 230000).toISOString(),
              turn: 2
            },
            { 
              id: '7', 
              result: 'Technomancer hacks Steelcrusher, reducing its power by 20%', 
              timestamp: new Date(Date.now() - 220000).toISOString(),
              turn: 2
            },
            { 
              id: '8', 
              result: 'Turn 2 complete', 
              timestamp: new Date(Date.now() - 200000).toISOString(),
              turn: 2
            },
            { 
              id: '9', 
              result: 'Round 3 begins! Make your moves', 
              timestamp: new Date(Date.now() - 180000).toISOString(),
              turn: 3 
            },
            { 
              id: '10', 
              result: 'Devastator X9 launches a missile barrage at MediTron for 45 damage!', 
              timestamp: new Date(Date.now() - 170000).toISOString(),
              turn: 3
            },
            { 
              id: '11', 
              result: 'Fortify MK3 activates force field, protecting the team from next attack', 
              timestamp: new Date(Date.now() - 160000).toISOString(),
              turn: 3
            },
            { 
              id: '12', 
              result: 'Turn 3 complete', 
              timestamp: new Date(Date.now() - 140000).toISOString(),
              turn: 3
            },
            { 
              id: '13', 
              result: 'Round 4 begins! Make your moves', 
              timestamp: new Date(Date.now() - 120000).toISOString(),
              turn: 4
            }
          ];
          setLogs(mockLogs);
        } else {
          setError(response.error);
        }
      }
    } catch (err) {
      console.error("Error fetching battle logs:", err);
      setError(err instanceof Error ? err.message : 'Unknown error');
    } finally {
      setLoading(false);
    }
  }, [battleId, currentTurn]); // Include currentTurn as dependency

  useEffect(() => {
    // Initial fetch
    fetchLogs();
    
    // Refresh when turn changes
    if (prevTurnRef.current !== currentTurn) {
      console.log(`Current turn changed to ${currentTurn}, refreshing logs`);
      prevTurnRef.current = currentTurn;
      fetchLogs();
    }
    
    // Instead of polling, only refresh when tab becomes visible
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        fetchLogs();
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [fetchLogs, currentTurn]);
  
  // Since we're showing newest turns at the top, we'll scroll to the top on load
  useEffect(() => {
    if (logsContainerRef.current) {
      // Use a timeout to ensure rendering completes first
      setTimeout(() => {
        if (logsContainerRef.current) {
          logsContainerRef.current.scrollTop = 0; // Scroll to top since newest is at top
        }
      }, 100);
    }
  }, [logs]);

  if (loading && logs.length === 0) {
    return <LogsLoading>Loading battle logs...</LogsLoading>;
  }

  if (error) {
    return <LogsError>Error loading logs: {error}</LogsError>;
  }

  if (logs.length === 0) {
    return <EmptyLogs>No battle events yet.</EmptyLogs>;
  }
  
  // Group logs by turn
  const logsByTurn: Record<number, LogEntry[]> = {};
  logs.forEach(log => {
    // Make sure we have a valid turn number (default to 1)
    const turn = log.turn || 1;
    if (!logsByTurn[turn]) {
      logsByTurn[turn] = [];
    }
    logsByTurn[turn].push(log);
  });
  
  // Sort turns in reverse order (newest first)
  // This shows the most recent turn at the top
  const turns = Object.keys(logsByTurn)
    .map(Number)
    .sort((a, b) => b - a);

  return (
    <LogsContainer className="logs-container" ref={logsContainerRef}>
      <LogsHeader>Battle History</LogsHeader>
      {turns.map(turnNumber => (
        <TurnContainer key={`turn-${turnNumber}`}>
          <TurnHeader>
            <div style={{ 
              fontSize: theme.fontSizes.md,
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              gap: '6px'
            }}>
              {turnNumber === currentTurn ? (
                <>
                  <span style={{ 
                    color: theme.colors.primary,
                    backgroundColor: 'rgba(52, 152, 219, 0.2)',
                    padding: '2px 8px',
                    borderRadius: '12px',
                    fontSize: theme.fontSizes.sm
                  }}>
                    🔵 CURRENT
                  </span>
                  <span>Turn {turnNumber}</span>
                </>
              ) : (
                <>Turn {turnNumber}</>
              )}
            </div>
            {logsByTurn[turnNumber][0]?.timestamp && (
              <div style={{ 
                fontSize: theme.fontSizes.xs,
                color: theme.colors.text.tertiary,
                fontStyle: 'italic'
              }}>
                {new Date(logsByTurn[turnNumber][0].timestamp).toLocaleString()}
              </div>
            )}
          </TurnHeader>
          
          <LogsList>
            {logsByTurn[turnNumber].map((log, index) => {
              // Determine the type of log for styling
              const isAttack = log.result.toLowerCase().includes('attack');
              const isHeal = log.result.toLowerCase().includes('heal') || 
                          log.result.toLowerCase().includes('repair');
              const isDefend = log.result.toLowerCase().includes('defend');
              const isTurnChange = log.result.toLowerCase().includes('turn') && 
                                log.result.toLowerCase().includes('complete');
              const isRoundStart = log.result.toLowerCase().includes('round') && 
                                log.result.toLowerCase().includes('begin');
              
              // Skip turn completion messages as they're redundant with our turn headers
              if (isTurnChange || isRoundStart) {
                return null;
              }
              
              // Apply specific styles based on log type
              const logStyle = {
                borderLeftColor: isAttack ? theme.colors.danger : 
                                isHeal ? theme.colors.success : 
                                isDefend ? theme.colors.warning : 
                                index % 2 === 0 ? theme.colors.primary : theme.colors.secondary
              };
              
              return (
                <LogEntryItem key={log.id || index} style={logStyle}>
                  {log.result}
                  {log.timestamp && !isRoundStart && !isTurnChange && (
                    <LogTimestamp>
                      {new Date(log.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </LogTimestamp>
                  )}
                </LogEntryItem>
              );
            }).filter(Boolean)}
          </LogsList>
        </TurnContainer>
      ))}
    </LogsContainer>
  );
};

export default BattleLogs;