import React from 'react';
import styled, { keyframes, css } from 'styled-components';

const floatAnimation = keyframes`
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
`;

const glowPulse = keyframes`
  0% {
    box-shadow: 0 0 5px rgba(51, 144, 236, 0.3);
  }
  50% {
    box-shadow: 0 0 20px rgba(51, 144, 236, 0.7);
  }
  100% {
    box-shadow: 0 0 5px rgba(51, 144, 236, 0.3);
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  pointer-events: none;
`;

const Area = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
`;

const Circle = styled.li<{ size: number; delay: number; duration: number; left: string; glowing?: boolean }>`
  position: absolute;
  display: block;
  list-style: none;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background: rgba(51, 144, 236, 0.1);
  animation: ${props => css`${floatAnimation} ${props.duration}s linear infinite`};
  bottom: -150px;
  left: ${props => props.left};
  animation-delay: ${props => props.delay}s;
  
  ${props => props.glowing && css`
    animation: ${floatAnimation} ${props.duration}s linear infinite, ${glowPulse} 3s infinite;
    background: rgba(51, 144, 236, 0.3);
  `}
`;

const BackgroundEffects: React.FC = () => {
  // Create an array of circle configurations
  const circles = [
    { size: 80, left: '25%', delay: 0, duration: 20, glowing: true },
    { size: 20, left: '10%', delay: 2, duration: 12 },
    { size: 40, left: '70%', delay: 5, duration: 18 },
    { size: 10, left: '40%', delay: 0, duration: 15 },
    { size: 30, left: '65%', delay: 8, duration: 12 },
    { size: 15, left: '75%', delay: 3, duration: 10 },
    { size: 25, left: '35%', delay: 7, duration: 14, glowing: true },
    { size: 15, left: '50%', delay: 10, duration: 16 },
    { size: 20, left: '20%', delay: 4, duration: 20 },
    { size: 10, left: '85%', delay: 6, duration: 15 },
    { size: 35, left: '15%', delay: 9, duration: 12, glowing: true },
    { size: 15, left: '90%', delay: 2, duration: 18 },
    { size: 25, left: '80%', delay: 11, duration: 14 }
  ];

  return (
    <Background>
      <Area>
        {circles.map((circle, index) => (
          <Circle 
            key={index}
            size={circle.size}
            left={circle.left}
            delay={circle.delay}
            duration={circle.duration}
            glowing={circle.glowing}
          />
        ))}
      </Area>
    </Background>
  );
};

export default BackgroundEffects;