import { useState, useEffect } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const useCheckBattle = () => {
    const [isInBattle, setIsInBattle] = useState(false);
    const [battleId, setBattleId] = useState(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const location = useLocation();

    useEffect(() => {
        console.log("Current location:", location.pathname); // Debug log
        const checkIfInBattle = async () => {
            if (token) {
                const response = await fetch(API_URL + "/check-if-i-am-in-battle", {
                    method: 'GET',
                    headers: {
                        'Authorization': token // Include the token in the Authorization header
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                // Try to log the text content of the response
                const text = await response.text();
                console.log("Response text:", text); // Check what the response contains

                let data;
                try {
                    data = JSON.parse(text); // Manually parse the response text to see if it's valid JSON
                    console.log("Parsed data:", data);
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                    throw new Error("Invalid JSON response from server");
                }

                if (data.inBattle) {
                    if (battleId !== data.battleId) {
                        setBattleId(data.battleId);
                        setIsInBattle(true);
                    }
                    // Only redirect if the current path is not the battle route
                    if (!location.pathname.startsWith(`/battle/${data.battleId}`)) {
                        console.log("Redirecting to battle route"); // Debug log
                        navigate(`/battle/${data.battleId}`, { replace: true });
                    }
                } else {
                    setIsInBattle(false);
                    setBattleId(null);
                }
            } else {
                console.error('No token found');
            }
        };

        checkIfInBattle();
        const interval = setInterval(checkIfInBattle, 5000);
        return () => clearInterval(interval);
    }, [navigate, location, token]);

    return { isInBattle, battleId };
};

export default useCheckBattle;