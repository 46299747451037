import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { api } from '../services/api';

interface BattleContextProps {
  inBattle: boolean;
  setInBattle: (inBattle: boolean) => void;
  activeBattleId: string | null;
  setActiveBattleId: (battleId: string | null) => void;
  checkActiveBattle: () => Promise<void>;
}

const BattleContext = createContext<BattleContextProps | undefined>(undefined);

export const useBattleContext = () => {
  const context = useContext(BattleContext);
  if (!context) {
    throw new Error('useBattleContext must be used within a BattleProvider');
  }
  return context;
};

export const BattleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [inBattle, setInBattle] = useState<boolean>(false);
  const [activeBattleId, setActiveBattleId] = useState<string | null>(null);
  
  const checkActiveBattle = async () => {
    const token = localStorage.getItem('token');
    if (!token) return;
    
    try {
      const response = await api.checkActiveBattle();
      
      if (response.data) {
        setInBattle(response.data.isInBattle);
        if (response.data.battleId) {
          setActiveBattleId(response.data.battleId);
        } else {
          setActiveBattleId(null);
        }
      }
    } catch (error) {
      console.error("Error checking active battle:", error);
      // Don't change state on error to prevent UI flashing
    }
  };
  
  // We no longer check for active battles on component mount
  // This will be handled through useTelegram hook when user first opens the app

  return (
    <BattleContext.Provider 
      value={{ 
        inBattle, 
        setInBattle, 
        activeBattleId, 
        setActiveBattleId,
        checkActiveBattle
      }}
    >
      {children}
    </BattleContext.Provider>
  );
};