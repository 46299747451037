import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CreditCard as WalletIcon, ExternalLink, Copy, CheckCircle } from 'react-feather';
import { 
  TonConnectButton, 
  useTonWallet, 
  useTonAddress 
} from '@tonconnect/ui-react';
import Card from '../../components/ui/Card';
import Button from '../../components/ui/Button';
import theme from '../../style/theme';

const WalletHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  margin-bottom: ${theme.spacing.lg};
`;

const HeaderIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: ${theme.borderRadius.md};
  background-color: ${theme.colors.background.tertiary};
`;

const HeaderTitle = styled.div`
  flex: 1;
`;

const WalletSection = styled.div`
  margin-bottom: ${theme.spacing.xl};
`;

const WalletCard = styled(Card)`
  margin-bottom: ${theme.spacing.md};
`;

const WalletBalance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing.lg} 0;
`;

const BalanceAmount = styled.div`
  font-size: ${theme.fontSizes.xl};
  font-weight: bold;
  margin-bottom: ${theme.spacing.xs};
`;

const BalanceLabel = styled.div`
  color: ${theme.colors.text.secondary};
  font-size: ${theme.fontSizes.sm};
`;

const AddressContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.background.tertiary};
  border-radius: ${theme.borderRadius.md};
  padding: ${theme.spacing.sm};
  margin-bottom: ${theme.spacing.md};
  overflow: hidden;
`;

const Address = styled.div`
  font-family: monospace;
  color: ${theme.colors.text.secondary};
  font-size: ${theme.fontSizes.sm};
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${theme.colors.text.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${theme.spacing.sm};
  
  &:hover {
    color: ${theme.colors.primary};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.md};
  margin-top: ${theme.spacing.md};
`;

const NotConnectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing.xl} 0;
  text-align: center;
`;

const NotConnectedText = styled.p`
  margin-bottom: ${theme.spacing.lg};
  color: ${theme.colors.text.secondary};
`;

export const Wallet: React.FC = () => {
  const wallet = useTonWallet();
  const userFriendlyAddress = useTonAddress();
  const [balance, setBalance] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBalance = async () => {
      if (wallet && userFriendlyAddress) {
        try {
          setLoading(true);
          // This would be replaced with a real API call to get the balance
          // For now we'll simulate it with a random amount
          // In a production app, you would use TonClient or an indexer API
          setTimeout(() => {
            const randomBalance = (Math.random() * 10).toFixed(4);
            setBalance(randomBalance);
            setLoading(false);
          }, 1000);
        } catch (error) {
          console.error('Error fetching balance:', error);
          setLoading(false);
        }
      } else {
        setBalance(null);
      }
    };

    fetchBalance();
  }, [wallet, userFriendlyAddress]);

  const copyToClipboard = () => {
    if (userFriendlyAddress) {
      navigator.clipboard.writeText(userFriendlyAddress);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const openInExplorer = () => {
    if (userFriendlyAddress) {
      window.open(`https://tonscan.org/address/${userFriendlyAddress}`, '_blank');
    }
  };

  const formatAddress = (address: string) => {
    if (address.length > 20) {
      return `${address.substring(0, 8)}...${address.substring(address.length - 8)}`;
    }
    return address;
  };

  return (
    <div className="fade-in">
      <WalletHeader>
        <HeaderIcon>
          <WalletIcon size={24} color={theme.colors.primary} />
        </HeaderIcon>
        <HeaderTitle>
          <h1>Wallet</h1>
          <p style={{ color: theme.colors.text.secondary, fontSize: theme.fontSizes.sm }}>
            Connect your TON wallet
          </p>
        </HeaderTitle>
      </WalletHeader>

      <WalletSection>
        {wallet ? (
          <WalletCard variant="elevated">
            <Card.Title>TON Wallet</Card.Title>
            <WalletBalance>
              {loading ? (
                <BalanceLabel>Loading balance...</BalanceLabel>
              ) : (
                <>
                  <BalanceAmount>{balance || '0'} TON</BalanceAmount>
                  <BalanceLabel>Available Balance</BalanceLabel>
                </>
              )}
            </WalletBalance>

            {userFriendlyAddress && (
              <div>
                <AddressContainer>
                  <Address>{formatAddress(userFriendlyAddress)}</Address>
                  <ActionButton onClick={copyToClipboard}>
                    {copied ? <CheckCircle size={18} color={theme.colors.success} /> : <Copy size={18} />}
                  </ActionButton>
                  <ActionButton onClick={openInExplorer}>
                    <ExternalLink size={18} />
                  </ActionButton>
                </AddressContainer>
              </div>
            )}

            <ButtonsContainer>
              <Button
                fullWidth
                variant="primary"
                onClick={() => {
                  // Implement send functionality
                  alert('Send functionality would open here');
                }}
              >
                Send
              </Button>
              <Button
                fullWidth
                variant="outline"
                onClick={() => {
                  // Implement receive functionality
                  alert('Receive functionality would open here');
                }}
              >
                Receive
              </Button>
            </ButtonsContainer>

            <div style={{ marginTop: theme.spacing.md }}>
              <TonConnectButton />
            </div>
          </WalletCard>
        ) : (
          <WalletCard variant="elevated">
            <Card.Title>Connect Wallet</Card.Title>
            <NotConnectedContainer>
              <NotConnectedText>
                Connect your TON wallet to view your balance and make transactions
              </NotConnectedText>
              <TonConnectButton />
            </NotConnectedContainer>
          </WalletCard>
        )}
      </WalletSection>

      <WalletSection>
        <Card variant="outlined">
          <Card.Title>Transaction History</Card.Title>
          <Card.Content>
            {wallet ? (
              <p>Your recent transactions will appear here</p>
            ) : (
              <p>Connect your wallet to view transaction history</p>
            )}
          </Card.Content>
        </Card>
      </WalletSection>
    </div>
  );
};

export default Wallet;