import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import theme from '../../style/theme';

type ProgressBarVariant = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';
type ProgressBarSize = 'sm' | 'md' | 'lg';

interface ProgressBarProps {
  variant?: ProgressBarVariant;
  size?: ProgressBarSize;
  value: number;
  max?: number;
  showLabel?: boolean;
  label?: string;
  animated?: boolean;
  striped?: boolean;
  className?: string;
}

const stripeAnimation = keyframes`
  from { background-position: 40px 0; }
  to { background-position: 0 0; }
`;

const pulseAnimation = keyframes`
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  margin: ${theme.spacing.xs} 0;
`;

const ProgressBarLabel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.xs};
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.text.secondary};
`;

const ProgressBarTrack = styled.div<{ size: ProgressBarSize }>`
  width: 100%;
  background-color: ${theme.colors.background.tertiary};
  border-radius: ${theme.borderRadius.full};
  overflow: hidden;
  
  ${props => {
    switch (props.size) {
      case 'sm': 
        return css`height: 6px;`;
      case 'lg': 
        return css`height: 16px;`;
      case 'md':
      default:
        return css`height: 10px;`;
    }
  }}
`;

const stripePattern = css`
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
`;

const ProgressBarFill = styled.div<{
  width: string;
  variant: ProgressBarVariant;
  animated?: boolean;
  striped?: boolean;
}>`
  height: 100%;
  width: ${props => props.width};
  border-radius: ${theme.borderRadius.full};
  transition: width ${theme.animation.normal} ease-in-out;
  
  ${props => {
    switch (props.variant) {
      case 'secondary':
        return css`background-color: ${theme.colors.secondary};`;
      case 'success':
        return css`background-color: ${theme.colors.success};`;
      case 'danger':
        return css`background-color: ${theme.colors.danger};`;
      case 'warning':
        return css`background-color: ${theme.colors.warning};`;
      case 'info':
        return css`background-color: ${theme.colors.info};`;
      case 'primary':
      default:
        return css`background-color: ${theme.colors.primary};`;
    }
  }}
  
  ${props => props.striped && stripePattern}
  
  ${props => props.animated && props.striped && css`
    animation: ${stripeAnimation} 2s linear infinite;
  `}
  
  ${props => props.animated && !props.striped && css`
    animation: ${pulseAnimation} 2s ease-in-out infinite;
  `}
`;

export const ProgressBar: React.FC<ProgressBarProps> = ({
  variant = 'primary',
  size = 'md',
  value,
  max = 100,
  showLabel = false,
  label,
  animated = false,
  striped = false,
  className,
}) => {
  const percentage = Math.min(100, Math.max(0, (value / max) * 100));
  
  return (
    <ProgressBarContainer className={className}>
      {(showLabel || label) && (
        <ProgressBarLabel>
          {label || `${Math.round(percentage)}%`}
          {label && showLabel && <span>{value}/{max}</span>}
        </ProgressBarLabel>
      )}
      <ProgressBarTrack size={size}>
        <ProgressBarFill
          width={`${percentage}%`}
          variant={variant}
          animated={animated}
          striped={striped}
        />
      </ProgressBarTrack>
    </ProgressBarContainer>
  );
};

export default ProgressBar;