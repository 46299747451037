import React from "react";
import './style/Avatar.css';


interface AvatarProps {
    avatarNumber: string;
}
const Avatar: React.FC<AvatarProps> = ({ avatarNumber = "1"}) => {
    return (
        <div className={`avatar avatar${avatarNumber}`}></div>
    );
};

export default Avatar;