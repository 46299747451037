import { Robot, User, LeaderboardPlayer, Battle, BattleProfile } from '../types';

// Mock robots data
export const mockRobots: Robot[] = [
  {
    id: "robot1",
    name: "Electric Titan",
    type: "Electric-Strong",
    level: 3,
    energy: 50,
    maxEnergy: 100,
    armor: 60,
    maxArmor: 100,
    characteristics: {
      power: 15,
      speed: 8,
      durability: 10,
      memory: 6,
    },
    slots: [
      { id: "slot1", item: "Electric Shield" },
      { id: "slot2", item: "Speed Booster" },
      { id: "slot3", item: null },
      { id: "slot4", item: null },
      { id: "slot5", item: null, locked: true },
      { id: "slot6", item: null, locked: true },
    ],
  },
  {
    id: "robot2",
    name: "Stealth Predator",
    type: "Stealth-Agile",
    level: 2,
    energy: 70,
    maxEnergy: 80,
    armor: 40,
    maxArmor: 50,
    characteristics: {
      power: 9,
      speed: 14,
      durability: 6,
      memory: 10,
    },
    slots: [
      { id: "slot1", item: "Cloaking Device" },
      { id: "slot2", item: "Energy Booster" },
      { id: "slot3", item: null },
      { id: "slot4", item: null },
      { id: "slot5", item: null, locked: true },
      { id: "slot6", item: null, locked: true },
    ],
  }
];

// Mock leaderboard data
export const mockLeaderboard: LeaderboardPlayer[] = [
  { id: '1', name: 'Ethan_Elite', score: 12500, win: 45, lose: 12, avatar: null },
  { id: '2', name: 'RoboKing', score: 10800, win: 38, lose: 15, avatar: null },
  { id: '3', name: 'BattleQueen', score: 9300, win: 32, lose: 8, avatar: null },
  { id: '4', name: 'MechWarrior', score: 8750, win: 30, lose: 10, avatar: null },
  { id: '5', name: 'IronProcessor', score: 7200, win: 25, lose: 11, avatar: null },
  { id: '6', name: 'QuantumFighter', score: 6500, win: 22, lose: 12, avatar: null },
  { id: '7', name: 'CyberBlade', score: 5800, win: 20, lose: 15, avatar: null },
  { id: '8', name: 'NanoNinja', score: 5200, win: 18, lose: 10, avatar: null },
  { id: '9', name: 'TechTitan', score: 4700, win: 16, lose: 9, avatar: null },
  { id: '10', name: 'SteelStorm', score: 4200, win: 15, lose: 12, avatar: null },
];

// Current player mock data
export const currentPlayer = {
  id: '24',
  name: 'PlayerName',
  score: 2400,
  win: 8,
  lose: 6,
  rank: 24,
  avatar: null
};

// Mock battle history data
export const mockBattleHistory = [
  {
    id: 'battle1',
    opponent: 'RoboKing',
    result: 'win',
    date: '2023-08-01T14:30:00Z',
    scoreChange: '+120',
  },
  {
    id: 'battle2',
    opponent: 'Ethan_Elite',
    result: 'lose',
    date: '2023-08-01T12:15:00Z',
    scoreChange: '-80',
  },
  {
    id: 'battle3',
    opponent: 'BattleQueen',
    result: 'win',
    date: '2023-07-31T18:45:00Z',
    scoreChange: '+150',
  },
  {
    id: 'battle4',
    opponent: 'MechWarrior',
    result: 'win',
    date: '2023-07-30T09:20:00Z',
    scoreChange: '+130',
  },
  {
    id: 'battle5',
    opponent: 'TechTitan',
    result: 'lose',
    date: '2023-07-29T16:10:00Z',
    scoreChange: '-70',
  },
];

// Mock user data
export const mockUser: User = {
  id: 'user1',
  name: 'PlayerName',
  displayName: 'Player Name',
  avatar: null,
  level: 5,
  experience: 750,
  nextLevelExperience: 1000,
  credits: 2500,
  premium: false,
  createdAt: '2023-06-15T10:30:00Z',
  lastActive: '2023-08-02T15:45:00Z',
};

// Mock battle requests data
export const mockBattleRequests = [
  { id: 'req-1', requester: 'Player123', level: 1 },
  { id: 'req-2', requester: 'BattleMaster', level: 1 },
  { id: 'req-3', requester: 'RobotFighter', level: 2 }
];

// Mock battle data store - will hold all active battles
export const mockBattleStore: { [battleId: string]: Battle } = {};

// Mock battle profiles - health and stats for each participant
export const mockBattleProfiles: { [battleId: string]: BattleProfile[] } = {};

// Mock battle logs - enhanced to include turn numbers and timestamps
export const mockBattleLogs: { [battleId: string]: Array<{ result: string, timestamp: string, turn?: number }> } = {};

// Initial mock battle data
export const createMockBattle = (battleId: string, userId: string, opponentId: string): Battle => {
  const battle: Battle = {
    battleData: {
      battleId,
      status: 'active',
      participants: [userId, opponentId],
      createdAt: new Date().toISOString(),
    },
    userId,
    opponentsTeam: [opponentId],
    // Updated to include max HP values for better logs
    battleProfiles: [
      { id: userId, hp: 5, maxHp: 5 },
      { id: opponentId, hp: 5, maxHp: 5 }
    ],
    // Add references to player and opponent robots
    playerRobots: mockRobots.slice(0, 2),
    opponentRobots: [
      {
        id: opponentId,
        name: "Challenger Bot",
        type: "attack",
        level: 3,
        energy: 80,
        maxEnergy: 100,
        armor: 70,
        maxArmor: 100,
        characteristics: {
          power: 75,
          speed: 60,
          durability: 50,
          memory: 40
        },
        slots: []
      }
    ]
  };

  // Store the battle in the mock battle store
  mockBattleStore[battleId] = battle;
  
  // Initialize battle logs with turn information
  const now = Date.now();
  mockBattleLogs[battleId] = [
    {
      result: "Battle started!",
      timestamp: new Date(now - 360000).toISOString(),
      turn: 1
    },
    {
      result: `Steelcrusher and Challenger Bot enter the arena.`,
      timestamp: new Date(now - 358000).toISOString(),
      turn: 1
    },
    // Add some previous turn history with robot names and HP info
    {
      result: `Steelcrusher attacks Challenger Bot for 15 damage! (HP: 85/100)`,
      timestamp: new Date(now - 350000).toISOString(),
      turn: 1
    },
    {
      result: `Challenger Bot defends against attack, reducing damage by 40% (HP: 91/100)`,
      timestamp: new Date(now - 340000).toISOString(),
      turn: 1
    },
    {
      result: `Turn 1 complete!`,
      timestamp: new Date(now - 330000).toISOString(),
      turn: 1
    },
    {
      result: `Round 2 begins!`,
      timestamp: new Date(now - 320000).toISOString(),
      turn: 2
    },
    {
      result: `Challenger Bot attacks Steelcrusher for 20 damage! (HP: 65/100)`,
      timestamp: new Date(now - 310000).toISOString(),
      turn: 2
    },
    {
      result: `Fortify MK3 repairs Steelcrusher, recovering 10 health points (HP: 75/100)`,
      timestamp: new Date(now - 300000).toISOString(),
      turn: 2
    },
    {
      result: `Turn 2 complete!`,
      timestamp: new Date(now - 290000).toISOString(),
      turn: 2
    },
    {
      result: `Round 3 begins!`,
      timestamp: new Date(now - 280000).toISOString(),
      turn: 3
    }
  ];
  
  return battle;
};