import { createGlobalStyle } from 'styled-components';

// Define a type for our theme properties to use in styled-components
declare module 'styled-components' {
  export interface DefaultTheme {
    colors: any;
    fontSizes: any;
    spacing: any;
    borderRadius: any;
    animation: any;
    shadows: any;
    zIndex: any;
    fonts?: any;
    fontWeights?: any;
    cards?: any;
    buttons?: any;
  }
}

const GlobalStyles = createGlobalStyle`
  /* Import Roboto for general UI and Roboto Mono for stats/technical details */
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Roboto+Mono:wght@400;500&display=swap');

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }

  body {
    font-family: ${props => props.theme.fonts?.body || "'Roboto', sans-serif"};
    font-size: ${props => props.theme.fontSizes.md};
    line-height: 1.5;
    color: ${props => props.theme.colors.text.primary};
    background-color: ${props => props.theme.colors.background.primary};
    background-image: linear-gradient(135deg, 
      ${props => props.theme.colors.background.gradient?.start || props.theme.colors.background.primary} 0%, 
      ${props => props.theme.colors.background.gradient?.mid || props.theme.colors.background.secondary} 50%,
      ${props => props.theme.colors.background.gradient?.end || props.theme.colors.background.tertiary} 100%);
    background-attachment: fixed;
    background-size: cover;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content */
    background-color: ${props => props.theme.colors.background.primary}; /* Match app background color */
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    font-family: inherit;
  }

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    transition: color ${props => props.theme.animation.fast};
    
    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: ${props => props.theme.fonts?.heading || "'Roboto', sans-serif"};
    font-weight: ${props => props.theme.fontWeights?.medium || 500};
    line-height: 1.2;
  }

  h1 {
    font-size: ${props => props.theme.fontSizes['3xl']};
  }

  h2 {
    font-size: ${props => props.theme.fontSizes['2xl']};
  }

  h3 {
    font-size: ${props => props.theme.fontSizes.xl};
  }

  h4 {
    font-size: ${props => props.theme.fontSizes.lg};
  }

  img {
    max-width: 100%;
    height: auto;
  }

  input, textarea, select {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    background-color: ${props => props.theme.colors.background.secondary};
    border: 1px solid ${props => props.theme.colors.border.default};
    border-radius: ${props => props.theme.borderRadius.md};
    padding: ${props => props.theme.spacing.sm} ${props => props.theme.spacing.md};
    transition: border-color ${props => props.theme.animation.fast}, box-shadow ${props => props.theme.animation.fast};
    
    &:focus {
      outline: none;
      border-color: ${props => props.theme.colors.primary};
      box-shadow: 0 0 0 2px ${props => props.theme.colors.primary}33;
    }
  }

  /* Class for monospace stats display */
  .monospace {
    font-family: ${props => props.theme.fonts?.monospace || "'Roboto Mono', monospace"};
    font-size: ${props => props.theme.fontSizes.sm};
    color: ${props => props.theme.colors.text.monospace || props.theme.colors.text.primary};
  }

  /* Card styles */
  .card {
    background-color: ${props => props.theme.cards?.default.bg || 'rgba(30, 30, 46, 0.7)'};
    border: ${props => props.theme.cards?.default.border || `1px solid rgba(255, 255, 255, 0.1)`};
    border-radius: ${props => props.theme.cards?.default.borderRadius || props.theme.borderRadius.lg};
    padding: ${props => props.theme.cards?.default.padding || props.theme.spacing.md};
    box-shadow: ${props => props.theme.cards?.default.boxShadow || props.theme.shadows.md};
    overflow: hidden;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: ${props => props.theme.shadows.lg};
    }
  }

  .battle-card {
    background-color: ${props => props.theme.cards?.battle.bg || 'rgba(26, 26, 46, 0.8)'};
    border: ${props => props.theme.cards?.battle.border || `1px solid rgba(51, 144, 236, 0.2)`};
    border-radius: ${props => props.theme.cards?.battle.borderRadius || props.theme.borderRadius.xl};
    box-shadow: ${props => props.theme.cards?.battle.boxShadow || '0 8px 32px rgba(0, 0, 0, 0.3)'};
    overflow: hidden;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
  }
  
  .glass-card {
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: ${props => props.theme.borderRadius.lg};
    border: 1px solid rgba(255, 255, 255, 0.08);
    transition: all 0.3s ease;
    
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.45);
    }
  }

  /* Button styles */
  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: ${props => props.theme.spacing.sm} ${props => props.theme.spacing.md};
    border-radius: ${props => props.theme.borderRadius.md};
    font-weight: ${props => props.theme.fontWeights?.medium || 500};
    transition: all ${props => props.theme.animation.fast};
    position: relative;
    overflow: hidden;
    
    &::after {
      content: '';
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 60%);
      transform: scale(0);
      opacity: 0;
      transition: transform 0.3s, opacity 0.3s;
    }
    
    &:active::after {
      transform: scale(1);
      opacity: 1;
      transition: 0s;
    }
  }

  .btn-primary {
    background-color: ${props => props.theme.buttons?.primary.bg || props.theme.colors.primary};
    color: ${props => props.theme.buttons?.primary.text || '#FFFFFF'};
    border: ${props => props.theme.buttons?.primary.border || 'none'};
    box-shadow: 0 0 15px rgba(51, 144, 236, 0.1);
    
    &:hover {
      background-color: ${props => props.theme.buttons?.primary.hoverBg || props.theme.colors.primary}E6;
      box-shadow: 0 0 20px rgba(51, 144, 236, 0.3);
      transform: translateY(-1px);
    }
    
    &:active {
      background-color: ${props => props.theme.buttons?.primary.activeBg || props.theme.colors.primary}CC;
      box-shadow: 0 0 10px rgba(51, 144, 236, 0.5);
      transform: translateY(1px);
    }
  }

  .btn-secondary {
    background-color: ${props => props.theme.buttons?.secondary.bg || 'rgba(45, 45, 60, 0.5)'};
    color: ${props => props.theme.buttons?.secondary.text || props.theme.colors.text.primary};
    border: ${props => props.theme.buttons?.secondary.border || `1px solid rgba(255, 255, 255, 0.1)`};
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    
    &:hover {
      background-color: ${props => props.theme.buttons?.secondary.hoverBg || 'rgba(60, 60, 80, 0.6)'};
      border-color: rgba(255, 255, 255, 0.2);
    }
    
    &:active {
      background-color: ${props => props.theme.buttons?.secondary.activeBg || 'rgba(70, 70, 90, 0.7)'};
    }
  }
  
  .btn-action {
    background: linear-gradient(45deg, #3390EC 0%, #4FC3F7 100%);
    color: white;
    border: none;
    box-shadow: 0 0 15px rgba(51, 144, 236, 0.3);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    
    &:hover {
      background: linear-gradient(45deg, #2980D1 0%, #3BB4E5 100%);
      box-shadow: 0 0 25px rgba(51, 144, 236, 0.5);
      transform: translateY(-2px);
    }
    
    &:active {
      background: linear-gradient(45deg, #1F6AB2 0%, #2A9BCF 100%);
      box-shadow: 0 0 10px rgba(51, 144, 236, 0.4);
      transform: translateY(1px);
    }
  }
  
  .btn-danger {
    background: linear-gradient(45deg, #FF3B30 0%, #FF6B61 100%);
    color: white;
    border: none;
    
    &:hover {
      background: linear-gradient(45deg, #E6352B 0%, #FF5242 100%);
      box-shadow: 0 0 15px rgba(255, 59, 48, 0.3);
    }
  }
  
  .btn-success {
    background: linear-gradient(45deg, #4CAF50 0%, #66BB6A 100%);
    color: white;
    border: none;
    
    &:hover {
      background: linear-gradient(45deg, #43A047 0%, #57AC5B 100%);
      box-shadow: 0 0 15px rgba(76, 175, 80, 0.3);
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.background.primary};
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.background.tertiary};
    border-radius: ${props => props.theme.borderRadius.full};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.border.active};
  }

  /* Animation utility classes */
  .fade-in {
    animation: fadeIn ${props => props.theme.animation.normal} ease-in;
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  .slide-up {
    animation: slideUp ${props => props.theme.animation.normal} ease-out;
  }

  @keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }

  .slide-down {
    animation: slideDown ${props => props.theme.animation.normal} ease-out;
  }

  @keyframes slideDown {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }

  .bounce {
    animation: bounce ${props => props.theme.animation.slow} cubic-bezier(0.28, 0.84, 0.42, 1);
  }

  @keyframes bounce {
    0% { transform: scale(1); }
    25% { transform: scale(0.95); }
    50% { transform: scale(1.05); }
    75% { transform: scale(0.98); }
    100% { transform: scale(1); }
  }

  /* Pulsing animation for energy elements */
  .pulse {
    animation: pulse ${props => props.theme.animation.pulse} infinite ease-in-out;
  }

  @keyframes pulse {
    0% { opacity: 0.8; }
    50% { opacity: 1; }
    100% { opacity: 0.8; }
  }

  /* Utility classes */
  .text-center { text-align: center; }
  .text-left { text-align: left; }
  .text-right { text-align: right; }
  
  .d-flex { display: flex; }
  .flex-column { flex-direction: column; }
  .justify-center { justify-content: center; }
  .justify-between { justify-content: space-between; }
  .align-center { align-items: center; }
  .flex-wrap { flex-wrap: wrap; }
  
  .gap-xs { gap: ${props => props.theme.spacing.xs}; }
  .gap-sm { gap: ${props => props.theme.spacing.sm}; }
  .gap-md { gap: ${props => props.theme.spacing.md}; }
  .gap-lg { gap: ${props => props.theme.spacing.lg}; }
  
  .w-100 { width: 100%; }
  .h-100 { height: 100%; }

  /* Status colors for battle */
  .color-attack { color: ${props => props.theme.colors.status?.attack || props.theme.colors.danger}; }
  .color-defend { color: ${props => props.theme.colors.status?.defend || props.theme.colors.primary}; }
  .color-heal { color: ${props => props.theme.colors.status?.heal || props.theme.colors.success}; }
`;

export default GlobalStyles;