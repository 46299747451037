import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../../context/ThemeContext';

const ThemeToggleButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: ${props => props.theme.colors.background.secondary};
  color: ${props => props.theme.colors.text.primary};
  border: 1px solid ${props => props.theme.colors.border.default};
  border-radius: ${props => props.theme.borderRadius.md};
  padding: 6px 12px;
  font-size: ${props => props.theme.fontSizes.sm};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: ${props => props.theme.zIndex.fixed};
  opacity: 0.8;
  transition: all ${props => props.theme.animation.fast};

  &:hover {
    opacity: 1;
    background-color: ${props => props.theme.colors.background.tertiary};
  }

  svg {
    margin-right: 4px;
  }
`;

const ThemeSwitcher: React.FC = () => {
  const { currentTheme, toggleTheme } = useTheme();

  return (
    <ThemeToggleButton onClick={toggleTheme}>
      {currentTheme === 'original' ? (
        <>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C12.5523 2 13 2.44772 13 3V4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4V3C11 2.44772 11.4477 2 12 2Z" fill="currentColor"/>
            <path d="M12 19C12.5523 19 13 19.4477 13 20V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V20C11 19.4477 11.4477 19 12 19Z" fill="currentColor"/>
            <path d="M21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H20C19.4477 13 19 12.5523 19 12C19 11.4477 19.4477 11 20 11H21Z" fill="currentColor"/>
            <path d="M4 11C4.55228 11 5 11.4477 5 12C5 12.5523 4.55228 13 4 13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H4Z" fill="currentColor"/>
            <path d="M17.6569 17.6569C17.2663 18.0474 16.6332 18.0474 16.2426 17.6569L15.5355 16.9497C15.145 16.5592 15.145 15.9261 15.5355 15.5355C15.9261 15.145 16.5592 15.145 16.9497 15.5355L17.6569 16.2426C18.0474 16.6332 18.0474 17.2663 17.6569 17.6569Z" fill="currentColor"/>
            <path d="M8.46447 8.46447C8.07394 8.85499 7.44078 8.85499 7.05025 8.46447L6.34315 7.75736C5.95262 7.36684 5.95262 6.73367 6.34315 6.34315C6.73367 5.95262 7.36684 5.95262 7.75736 6.34315L8.46447 7.05025C8.85499 7.44078 8.85499 8.07394 8.46447 8.46447Z" fill="currentColor"/>
            <path d="M6.34315 17.6569C5.95262 17.2663 5.95262 16.6332 6.34315 16.2426L7.05025 15.5355C7.44078 15.145 8.07394 15.145 8.46447 15.5355C8.85499 15.9261 8.85499 16.5592 8.46447 16.9497L7.75736 17.6569C7.36684 18.0474 6.73367 18.0474 6.34315 17.6569Z" fill="currentColor"/>
            <path d="M15.5355 8.46447C15.145 8.07394 15.145 7.44078 15.5355 7.05025L16.2426 6.34315C16.6332 5.95262 17.2663 5.95262 17.6569 6.34315C18.0474 6.73367 18.0474 7.36684 17.6569 7.75736L16.9497 8.46447C16.5592 8.85499 15.9261 8.85499 15.5355 8.46447Z" fill="currentColor"/>
            <path d="M12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7Z" fill="currentColor"/>
          </svg>
          Light
        </>
      ) : (
        <>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.2256 2.00253C9.59172 1.94346 6.93894 2.9189 4.92893 4.92891C1.02369 8.83415 1.02369 15.1658 4.92893 19.071C8.83418 22.9763 15.1658 22.9763 19.0711 19.071C21.0811 17.061 22.0566 14.4082 21.9975 11.7743C21.9796 10.9772 21.8669 10.1818 21.6595 9.40643C21.0933 9.9488 20.5078 10.4276 19.9163 10.8425C18.5649 11.7906 17.1826 12.4053 15.9301 12.6837C14.0241 13.1072 12.7156 12.7156 12 12C11.2844 11.2844 10.8928 9.97588 11.3163 8.0699C11.5947 6.81738 12.2094 5.43511 13.1575 4.08368C13.5724 3.49221 14.0512 2.90664 14.5935 2.34046C13.8182 2.13305 13.0228 2.02041 12.2256 2.00253ZM17.6569 17.6568C18.9081 16.4056 19.6582 14.8431 19.9072 13.2186C16.3611 15.618 11.3145 15.6180 7.75735 13.1675C5.43511 11.5578 4.04678 9.04998 3.65328 6.34292C2.02895 7.59422 1.0009 9.40402 1.00011 11.4268C0.99885 13.4496 2.02472 15.5635 4.22182 17.7606C6.41892 19.9577 8.53282 20.9836 10.5556 20.9823C12.5784 20.9815 14.3882 19.9535 15.6395 18.3292C16.3685 17.3925 17.0522 16.8511 17.6569 17.6568Z" fill="currentColor"/>
          </svg>
          Dark
        </>
      )}
    </ThemeToggleButton>
  );
};

export default ThemeSwitcher;