import React, { useState, useEffect } from 'react';
import './App.css';
import {useHapticFeedback, useShowPopup} from "@vkruglikov/react-telegram-web-app";
import {BrowserRouter as Router, Link, Route, Routes, Navigate} from 'react-router-dom';
import Hero from "./Hero";
import Battles from "./Battles";
import Battle from "./Batlle";
import useCheckBattle from './useCheckBattle';
import { useGlobalContext } from './GlobalContext';
import Profile from "./Profile";

declare global {
    interface Window {
        Telegram:any;
    }
}

const server_url = process.env.REACT_APP_ENV === "local" ? "http://localhost:3001/set-session" : "https://impol.link/api/set-session";

const App: React.FC = () => {

    const [initDataUnsafe, setInitDataUnsafe] = useState<any>(null);
    const showPopup = useShowPopup()
    const [impactOccurred, notificationOccurred, selectionChanged] = useHapticFeedback()
    const [isInvalidVersion, setIsInvalidVersion] = useState(false);
    const { userId, setUserId } = useGlobalContext();
    const { userProfile, setUserProfile } = useGlobalContext();
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);  // Loading state


    const setSessionData = async (data: any) => {

        try {
            const response = await fetch(server_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ initData: data }),
                credentials: 'include' // Include credentials (cookies) in the request
            });
            setLoading(false);
            if (!response.ok) {

                let errorMessage = 'Something went wrong while setting session data';
                try {
                    const errorData = await response.json();
                    errorMessage = errorData.message || errorMessage;
                } catch (jsonError) {
                    console.error("Error parsing JSON response:", jsonError);
                }
                throw new Error(errorMessage);
            }

            const token_data: any = await response.json();
            setToken(token_data.token);
            setUserId(token_data.userId);
            setUserProfile(token_data.userProfile);
            localStorage.setItem('userProfile', JSON.stringify(token_data.userProfile));
            localStorage.setItem('token', token_data.token);
            console.log("Session data set successfully");

        } catch (error) {
            if (error instanceof TypeError) {
                // Network or other Fetch-related errors
                console.error("Network or Fetch error:", error.message);
            } else {
                // Custom error messages
                console.error("Error setting session data:", error);
            }
        }
    };

    const ProtectedRoutes = () => {
        const { isInBattle, battleId } = useCheckBattle();

        console.log("Rendering ProtectedRoutes, isInBattle:", isInBattle, "battleId:", battleId); // Debug log

        return (
            <Routes>
                {isInBattle ? (
                    <>
                        <Route path="/battle/:battleId" element={<Battle />} />
                        <Route path="*" element={<Navigate to={`/battle/${battleId}`} replace />} />
                    </>
                ) : (
                    <>
                        <Route path="/battles" element={<Battles />} />
                        <Route path="/battle/:battleId" element={<Battle />} />
                        <Route path="/hero" element={<Hero profileId={userId} />} />
                        <Route path="/" element={<Profile />} />
                    </>
                )}
            </Routes>
        );
    };


    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            if (!window.Telegram.WebApp.isVersionAtLeast("6.9")) {
                notificationOccurred("error")
                if (window.Telegram.WebApp.isVersionAtLeast("6.2")) {
                    showPopup({message: "Please update your Telegram app to the latest version to use this app."})
                } else {
                    console.log("the version is not supported")
                    setIsInvalidVersion(true)
                }
            }

            const unsafeData = window.Telegram.WebApp.initDataUnsafe;
            setInitDataUnsafe(unsafeData);

            setSessionData(window.Telegram.WebApp.initData);
        }
    }, []);

    if (loading) {
        // Render loading screen while data is fetching
        return (
            <div className="App loading-screen">
                <h1>Loading...</h1>
                <div className="spinner"></div> {/* You can add a spinner here */}
            </div>
        );
    }

    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    {/*<img src={logo} className="App-logo" alt="logo" />*/}
                    {/* <a
              className="App-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
          >
            Super battle
          </a>*/}
                    <nav>
                        <Link to="/">Home</Link> | <Link to="/battles">Battles</Link> | <Link to="/hero">Hero</Link>

                    </nav>
                </header>
                <div className="content">
                    <ProtectedRoutes />
                </div>
            </div>
        </Router>
    );
};

export default App;
