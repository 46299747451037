import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { User, Award, Zap, Activity, Shield, BarChart2 } from 'react-feather';

import { useAuthContext } from '../../context/AuthContext';
import Avatar from '../../components/ui/Avatar';
import Card from '../../components/ui/Card';
import Button from '../../components/ui/Button';
import ProgressBar from '../../components/ui/ProgressBar';
import Badge from '../../components/ui/Badge';
import LoadingScreen, { LoadingIndicator } from '../../components/ui/LoadingScreen';
import theme from '../../style/theme';

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing.lg};
  background: linear-gradient(135deg, ${theme.colors.background.tertiary} 0%, ${theme.colors.background.secondary} 100%);
  border-radius: ${theme.borderRadius.lg};
  padding: ${theme.spacing.md};
  position: relative;
`;

const AvatarWrapper = styled.div`
  position: relative;
  margin-right: ${theme.spacing.md};
`;

const LevelBadge = styled.div`
  position: absolute;
  bottom: -2px;
  right: -2px;
  background-color: ${theme.colors.secondary};
  color: ${theme.colors.text.primary};
  font-size: ${theme.fontSizes.xs};
  font-weight: 500;
  padding: 2px 6px;
  border-radius: ${theme.borderRadius.full};
  border: 2px solid ${theme.colors.background.primary};
`;

const UserInfo = styled.div`
  flex: 1;
`;

const UserName = styled.h2`
  margin: 0 0 ${theme.spacing.xs} 0;
  font-size: ${theme.fontSizes.xl};
`;

const StatsWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.md};
  margin-top: ${theme.spacing.sm};
`;

const StatItem = styled.div`
  text-align: center;
`;

const StatValue = styled.div`
  font-size: ${theme.fontSizes.lg};
  font-weight: 600;
  color: ${theme.colors.text.primary};
`;

const StatLabel = styled.div`
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.text.secondary};
`;

const ExpBar = styled.div`
  margin-top: ${theme.spacing.xs};
`;

const ActionButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${theme.spacing.md};
  margin: ${theme.spacing.lg} 0;
`;

const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${theme.spacing.md};
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  margin-bottom: ${theme.spacing.sm};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.background.tertiary};
  width: 36px;
  height: 36px;
  border-radius: ${theme.borderRadius.md};
`;

export const Profile: React.FC = () => {
  const { userProfile, user } = useAuthContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading profile data in background
    // We'll still track loading state but won't show full screen overlay
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  // If no profile data at all, show minimal placeholder instead of full screen
  if (!userProfile) {
    return (
      <div className="fade-in">
        <div style={{ padding: theme.spacing.lg, textAlign: 'center' }}>
          <p>Loading profile data...</p>
        </div>
      </div>
    );
  }
  
  // Calculate health percentage
  const healthPercentage = Math.min(100, (userProfile.HP / userProfile.maxHP) * 100);
  
  return (
    <div className="fade-in">
      {loading && <LoadingIndicator message="Refreshing data..." />}
      
      <ProfileHeader>
        <AvatarWrapper>
          <Avatar 
            src={userProfile.avatar && !userProfile.avatar.startsWith('avatar') 
              ? userProfile.avatar 
              : null} 
            text={user?.displayName || user?.name || 'Player'}
            size="xl"
            status="online"
          />
          <LevelBadge>Lvl {userProfile.level}</LevelBadge>
        </AvatarWrapper>
        
        <UserInfo>
          <UserName>{user?.displayName || user?.name || 'Player'}</UserName>
          
          <ExpBar>
            <ProgressBar 
              value={userProfile.exp} 
              max={100}
              variant="secondary"
              size="sm"
              showLabel
              label="EXP"
              striped
            />
          </ExpBar>
          
          <StatsWrapper>
            <StatItem>
              <StatValue>{userProfile.win}</StatValue>
              <StatLabel>Wins</StatLabel>
            </StatItem>
            <StatItem>
              <StatValue>{userProfile.lost}</StatValue>
              <StatLabel>Losses</StatLabel>
            </StatItem>
            <StatItem>
              <StatValue>{userProfile.draw}</StatValue>
              <StatLabel>Draws</StatLabel>
            </StatItem>
          </StatsWrapper>
        </UserInfo>
      </ProfileHeader>
      
      <Card>
        <Card.Title>Health Status</Card.Title>
        <Card.Content>
          <ProgressBar 
            value={userProfile.HP} 
            max={userProfile.maxHP}
            variant={healthPercentage < 30 ? 'danger' : 'success'}
            size="md"
            showLabel
            label="Health"
            animated
          />
          <p style={{ fontSize: theme.fontSizes.sm, color: theme.colors.text.secondary, marginTop: theme.spacing.xs }}>
            Health regenerates over time
          </p>
        </Card.Content>
      </Card>
      
      <ActionButtons>
        <Button 
          to="/battles" 
          variant="primary"
          size="lg"
          fullWidth
          icon={<Zap size={20} />}
        >
          Find Battles
        </Button>
        <Button 
          to="/robots" 
          variant="secondary"
          size="lg"
          fullWidth
          icon={<Shield size={20} />}
        >
          My Robots
        </Button>
      </ActionButtons>
      
      <SectionGrid>
        <Card to="/hero">
          <SectionHeader>
            <IconWrapper>
              <User size={20} color={theme.colors.primary} />
            </IconWrapper>
            <h3>Profile Stats</h3>
          </SectionHeader>
          <p style={{ color: theme.colors.text.secondary }}>
            View and upgrade your attributes and abilities
          </p>
        </Card>
        
        <Card to="/leaderboard">
          <SectionHeader>
            <IconWrapper>
              <Award size={20} color={theme.colors.secondary} />
            </IconWrapper>
            <h3>Leaderboard</h3>
          </SectionHeader>
          <p style={{ color: theme.colors.text.secondary }}>
            Check rankings and compare your stats
          </p>
        </Card>
        
        <Card>
          <SectionHeader>
            <IconWrapper>
              <Activity size={20} color={theme.colors.success} />
            </IconWrapper>
            <h3>Battle History</h3>
          </SectionHeader>
          <p style={{ color: theme.colors.text.secondary }}>
            {userProfile.win + userProfile.lost + userProfile.draw} total battles completed
          </p>
        </Card>
        
        <Card>
          <SectionHeader>
            <IconWrapper>
              <BarChart2 size={20} color={theme.colors.warning} />
            </IconWrapper>
            <h3>Achievements</h3>
          </SectionHeader>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p style={{ color: theme.colors.text.secondary }}>
              3/10 achievements unlocked
            </p>
            <Badge variant="success" pill>New</Badge>
          </div>
        </Card>
      </SectionGrid>
    </div>
  );
};

export default Profile;