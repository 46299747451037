import React from 'react';
import styled from 'styled-components';
import { Award, User, Star } from 'react-feather';
import Card from '../../components/ui/Card';
import Avatar from '../../components/ui/Avatar';
import Badge from '../../components/ui/Badge';
import theme from '../../style/theme';
import { mockLeaderboard, currentPlayer } from '../../services/mockData';

const LeaderboardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  margin-bottom: ${theme.spacing.lg};
`;

const HeaderIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: ${theme.borderRadius.md};
  background-color: ${theme.colors.background.tertiary};
`;

const HeaderTitle = styled.div`
  flex: 1;
`;

const LeaderboardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
`;

const PlayerRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.md};
  padding: ${theme.spacing.sm};
  background-color: ${theme.colors.background.secondary};
  border-radius: ${theme.borderRadius.lg};
  position: relative;
  
  &:hover {
    background-color: ${theme.colors.background.tertiary};
  }
`;

const RankNumber = styled.div<{ top?: boolean }>`
  font-weight: bold;
  font-size: ${theme.fontSizes.lg};
  min-width: 24px;
  text-align: center;
  color: ${props => props.top ? theme.colors.warning : theme.colors.text.primary};
`;

const PlayerInfo = styled.div`
  flex: 1;
`;

const PlayerName = styled.div`
  font-weight: 500;
`;

const PlayerStats = styled.div`
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.text.secondary};
`;

const PlayerScore = styled.div`
  font-weight: bold;
  color: ${theme.colors.primary};
`;

const TopPlayersSection = styled.div`
  margin-bottom: ${theme.spacing.xl};
`;

const CurrentPlayerCard = styled(Card)`
  margin-top: ${theme.spacing.lg};
`;

export const Leaderboard: React.FC = () => {
  return (
    <div className="fade-in">
      <LeaderboardHeader>
        <HeaderIcon>
          <Award size={24} color={theme.colors.warning} />
        </HeaderIcon>
        <HeaderTitle>
          <h1>Leaderboard</h1>
          <p style={{ color: theme.colors.text.secondary, fontSize: theme.fontSizes.sm }}>
            Top players this season
          </p>
        </HeaderTitle>
      </LeaderboardHeader>
      
      <TopPlayersSection>
        <h2 style={{ marginBottom: theme.spacing.md }}>Top Players</h2>
        <LeaderboardList>
          {mockLeaderboard.slice(0, 10).map((player, index) => (
            <PlayerRow key={player.id}>
              <RankNumber top={index < 3}>
                {index === 0 && <Star size={14} color={theme.colors.warning} style={{ position: 'absolute', top: '-2px', left: '16px' }} />}
                {index + 1}
              </RankNumber>
              <Avatar 
                size="md" 
                text={player.name} 
                src={player.avatar}
                status={index < 3 ? 'online' : undefined}
              />
              <PlayerInfo>
                <PlayerName>{player.name}</PlayerName>
                <PlayerStats>W: {player.win} L: {player.lose}</PlayerStats>
              </PlayerInfo>
              <PlayerScore>{player.score.toLocaleString()}</PlayerScore>
              {index === 0 && <Badge variant="warning" pill>Champion</Badge>}
            </PlayerRow>
          ))}
        </LeaderboardList>
      </TopPlayersSection>
      
      <CurrentPlayerCard variant="elevated">
        <Card.Title>Your Ranking</Card.Title>
        <Card.Content>
          <PlayerRow>
            <RankNumber>{currentPlayer.rank}</RankNumber>
            <Avatar 
              size="md" 
              text={currentPlayer.name} 
              src={currentPlayer.avatar}
              status="online"
            />
            <PlayerInfo>
              <PlayerName>{currentPlayer.name}</PlayerName>
              <PlayerStats>W: {currentPlayer.win} L: {currentPlayer.lose}</PlayerStats>
            </PlayerInfo>
            <PlayerScore>{currentPlayer.score.toLocaleString()}</PlayerScore>
          </PlayerRow>
          <p style={{ marginTop: theme.spacing.md, textAlign: 'center', color: theme.colors.text.secondary }}>
            Win more battles to climb up the leaderboard!
          </p>
        </Card.Content>
      </CurrentPlayerCard>
    </div>
  );
};

export default Leaderboard;