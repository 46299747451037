import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './style/Battle.module.css';
import Logs from "./Logs";
import OpponentSelector from "./battle/OpponentSelector";
import CountdownBar from "./battle/CountdownBar"; // Import the CSS module

interface BattleProfile {
    id: string;
    hp: number;
}

const MAX_HP = 5;

const API_URL = process.env.REACT_APP_API_URL || "https://impol.link/api";

const Battle = () => {
    const { battleId } = useParams();
    const [battleData, setBattleData] :any= useState(null);
    const [userId, setUserId] :any= useState(null);
    const [battleProfiles, setBattleProfiles] = useState<BattleProfile[]>([]);
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(true);
    const [error, setError]:any = useState(null);
    const [selectedOpponentIndex, setSelectedOpponentIndex] = useState(0);
    const [selectedAction, setSelectedAction] = useState({ attack: null, defend: null });
    const [waitingForOpponent, setWaitingForOpponent] = useState(false);
    const navigate = useNavigate();

    const fetchBattleData = async () => {
        try {
            const response = await fetch(API_URL + `/battle/${battleId}`, {
                method: 'GET',
                headers: {
                    'Authorization': token || ''
                }
            });
            const data = await response.json();
            console.log("Fetched battle data:", data); // Debug log
            setBattleData(data);
            setUserId(data.userId);
            setBattleProfiles(data.battleProfiles);
            if (data.opponentsTeam && data.opponentsTeam.length > 0) {
                setSelectedOpponentIndex(0); // Ensure the first opponent is selected by default
            }
        } catch (error) {
            console.error("Error fetching battle data:", error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    if (token && !battleData) {
        fetchBattleData().catch(error => {
            console.error("Error in loadBattleData:", error);
        });
    }

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (waitingForOpponent && battleData) {
            intervalId = setInterval(checkOpponentMove, 5000); // Check every 5 seconds
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [waitingForOpponent, battleData]);


    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error loading battle: {error}</p>;
    }

    const handleSelectAction = (type: 'attack' | 'defend', action: string) => {
        console.log(`type: ${type}`);
        setSelectedAction((prevState) => ({
            ...prevState,
            [type]: action,
            [type === 'attack' ? 'defend' : 'attack']: null, // Reset the opposite action
        }));
    };

    const handlePreviousOpponent = () => {
        setSelectedOpponentIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : (battleData?.opponentsTeam.length ?? 1) - 1
        );
    };

    const handleNextOpponent = () => {
        setSelectedOpponentIndex((prevIndex) =>
            prevIndex < (battleData?.opponentsTeam.length ?? 1) - 1 ? prevIndex + 1 : 0
        );
    };

    const handleSubmit = () => {
        const selectedOpponent = battleData?.opponentsTeam[selectedOpponentIndex];
        // Logic for submitting the selected actions
        console.log('Selected Opponent:', selectedOpponent);
        console.log('Selected Action:', selectedAction);

        if (selectedOpponent && (selectedAction.attack || selectedAction.defend)) {
            // Perform the submission logic here
            const data = {
                battleId,
                target: selectedOpponent,
                action: selectedAction.attack || selectedAction.defend,
            };

            console.log(data);

            fetch(API_URL + "/battle/action", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token || '',
                },
                body: JSON.stringify(data),
            })
                .then(response => {
                    console.log(response);
                    if (response.status === 403) {
                        console.warn('403 Forbidden - Reloading the page');
                        window.location.reload();
                        return; // Stop further execution
                    }

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    return response.json();
                })
                .then(result => {
                    setSelectedAction({attack: null, defend: null});
                    console.log('Battle action submitted successfully:', result);
                    if (result.waiting) {
                        setWaitingForOpponent(true);
                    } else if (result.nextOpponent) {
                        setSelectedOpponentIndex(battleData?.opponentsTeam.indexOf(result.nextOpponent) || 0);
                    } else {
                        setWaitingForOpponent(false);
                    }
                })
                .catch(error => {
                    console.error('Error submitting battle action:', error);
                });
        } else {
            console.error('Please select an action and an opponent');
        }
    };

    const checkOpponentMove = async () => {
        if (battleData?.battleData?.status !== 'finished') {
            try {
                const selectedOpponent = battleData?.opponentsTeam[selectedOpponentIndex];
                console.log("opponent selected" + selectedOpponent);
                const response = await fetch(API_URL + `/battle/${battleId}/status`, {
                    method: 'POST',
                    headers: {
                        'Authorization': token || '',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({target: selectedOpponent})
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log('Checked opponent move:', result);

                if (result.finished) {
                    navigate('/battles'); // Redirect to the battles page
                }

                if (result.ready) {
                    setWaitingForOpponent(false);
                    await fetchBattleData(); // Refresh battle data
                } else {
                    console.log('Opponent has not made a move yet');
                }
            } catch (error) {
                console.error('Error checking opponent move:', error);
            }
        }
    };

    const renderHP = (profileId: string) => {
        const profile = battleProfiles.find(p => p.id === profileId);
        if (!profile) return 'N/A';

        const hpPercentage = (profile.hp / MAX_HP) * 100;
        let color = 'green';

        if (hpPercentage <= 33) {
            color = 'red';
        } else if (hpPercentage <= 66) {
            color = 'yellow';
        }

        return (
            <span style={{ color }}>
        {hpPercentage}%
      </span>
        );
    };

    if (waitingForOpponent) {
        return (
            <div className={styles.waitingContainer}>
                <div className={styles.userHP}>Your HP: {renderHP(userId)}</div>
                <p>Waiting for opponent to make a move...</p>
                <button onClick={checkOpponentMove} className={styles.refreshButton}>Refresh</button>
                {battleId && token && <Logs battleId={battleId} token={token}/>}
            </div>
        );
    }

    if (battleData.battleData.status === 'finished') {
        return (
            <div>
                <h1>Battle Finished</h1>
                <p>Battle Log:</p>
                <pre>{battleId && token && <Logs battleId={battleId} token={token}/>}</pre>
            </div>
        );
    }

    return (
        <div className={styles.battleContainer}>
            <CountdownBar></CountdownBar>
            <p>Status: {battleData.battleData.status}</p>
            <p>Status: {battleId}</p>
            <div className={styles.userHP}>Your HP: {renderHP(userId)}</div>
            {/*<div className={styles.battleHeader}>Battle {battleId}</div>
            <div className={styles.battleStatus}>Battle Status: {battleData.battleData.battleId}</div>*/}
            {battleData?.opponentsTeam && (
                <OpponentSelector
                    opponents={battleData.opponentsTeam}
                    selectedOpponentIndex={selectedOpponentIndex}
                    onPrevious={handlePreviousOpponent}
                    onNext={handleNextOpponent}
                />
            )}
            <div className={styles.opponentHP}>Opponent's
                HP: {renderHP(battleData?.opponentsTeam[selectedOpponentIndex])}
            </div>
            <div className={styles.battleTable}>
                <table>
                    <tbody>
                    <tr>
                        <td
                            className={selectedAction.attack === 'ah' ? styles.selected : ''}
                            onClick={() => handleSelectAction('attack', 'ah')}
                        >
                            Attack Head
                        </td>
                        <td
                            className={selectedAction.defend === 'dh' ? styles.selected : ''}
                            onClick={() => handleSelectAction('defend', 'dh')}
                        >
                            Defend Head
                        </td>
                    </tr>
                    <tr>
                        <td
                            className={selectedAction.attack === 'ac' ? styles.selected : ''}
                            onClick={() => handleSelectAction('attack', 'ac')}
                        >
                            Attack Chest
                        </td>
                        <td
                            className={selectedAction.defend === 'dc' ? styles.selected : ''}
                            onClick={() => handleSelectAction('defend', 'dc')}
                        >
                            Defend Chest
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <button className={styles.submitButton} onClick={handleSubmit}>Submit</button>
            {battleId && token && <Logs battleId={battleId} token={token}/>}
        </div>
    );
};

export default Battle;