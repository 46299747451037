import React, { useState, useEffect } from 'react';
import styles from './style/Battle.module.css';

// https://impol.link/api
const API_URL = process.env.REACT_APP_API_URL;
interface LogsProps {
    battleId: string;
    token: string | null;
}
const Logs: React.FC<LogsProps> = ({ battleId, token }) => {
    const [logs, setLogs] = useState([]);
    const [error, setError] = useState("");

    useEffect(() => {

        const fetchLogs = async () => {
            if (token) {
                const response = await fetch(API_URL+ `/battle/${battleId}/log`, {
                    method: 'GET',
                    headers: {
                        'Authorization': token
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();

                console.log("Fetched logs:", data.logs); // Debug log
                setLogs(data.logs);
            }
        };
        const loadLogs = async () => {
            try {
                await fetchLogs();
            } catch (error) {
                console.error("Error fetching logs:", error);
                setError("error");
            }
        };

        loadLogs().catch(error => {
            console.error("Error in loadLogs:", error);
        });

        const interval = setInterval(fetchLogs, 15000); // Update logs 15 seconds.
        return () => clearInterval(interval);
    }, [battleId, token]);

    if (error) {
        return <p>Error loading logs: {error}</p>;
    }

    return (
        <div className={styles.logsContainer}>
            <h2>Logs</h2>
            <ul>
                {logs.map((log:any, index) => (
                    <li key={index}>{log.result} - <small>{new Date(log.timestamp).toLocaleString()}</small></li>
                ))}
            </ul>
        </div>
    );
};

export default Logs;