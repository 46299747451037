// Define the original theme (current theme)
export const originalTheme = {
  colors: {
    // Main colors
    primary: '#3390EC', // Telegram blue
    secondary: '#8774E1', // Purple for accents
    success: '#4CAF50', // Green for positive actions
    danger: '#FF3B30', // Red for destructive actions
    warning: '#FF9500', // Orange for warnings
    info: '#34C759', // Another shade for information
    disabled: '#6c757d', // Gray for disabled items

    // Background colors
    background: {
      primary: '#17212B', // Dark blue (Telegram dark theme)
      secondary: '#232E3C', // Slightly lighter for cards
      tertiary: '#2B5278', // For highlights
      overlay: 'rgba(0, 0, 0, 0.5)',
    },

    // Text colors
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.7)',
      tertiary: 'rgba(255, 255, 255, 0.5)',
      disabled: 'rgba(255, 255, 255, 0.3)',
    },

    // Border colors
    border: {
      default: 'rgba(255, 255, 255, 0.1)',
      active: 'rgba(255, 255, 255, 0.2)',
    },
  },

  // Font sizes
  fontSizes: {
    xs: '0.75rem',    // 12px
    sm: '0.875rem',   // 14px
    md: '1rem',       // 16px
    lg: '1.125rem',   // 18px
    xl: '1.25rem',    // 20px
    '2xl': '1.5rem',  // 24px
    '3xl': '1.875rem' // 30px
  },

  // Spacing
  spacing: {
    xs: '0.25rem',  // 4px
    sm: '0.5rem',   // 8px
    md: '1rem',     // 16px
    lg: '1.5rem',   // 24px
    xl: '2rem',     // 32px
    '2xl': '2.5rem' // 40px
  },

  // Border radius
  borderRadius: {
    sm: '0.25rem',  // 4px
    md: '0.5rem',   // 8px
    lg: '0.75rem',  // 12px
    xl: '1rem',     // 16px
    full: '9999px', // Circular
  },

  // Animation durations
  animation: {
    fast: '0.15s',
    normal: '0.3s',
    slow: '0.5s',
  },

  // Shadows
  shadows: {
    sm: '0 1px 2px rgba(0, 0, 0, 0.1)',
    md: '0 4px 6px rgba(0, 0, 0, 0.1)',
    lg: '0 10px 15px rgba(0, 0, 0, 0.1)',
  },

  // Z-index values
  zIndex: {
    base: 1,
    dropdown: 10,
    sticky: 100,
    fixed: 200,
    modal: 300,
    popover: 400,
    toast: 500,
  },
};

// Define the new dark theme based on the design.md requirements
export const darkTheme = {
  colors: {
    // Main colors
    primary: '#3390EC', // Keep Telegram blue for consistency
    secondary: '#FFD200', // Bright yellow for energy elements
    energy: '#4FC3F7', // Electric blue for energy indicators
    success: '#4CAF50', // Green for positive actions
    danger: '#FF3B30', // Red for damage indicators
    warning: '#FF9500', // Orange for warnings
    info: '#34C759', // Green for healing
    disabled: '#6c757d', // Gray for disabled items

    // Background colors
    background: {
      primary: '#121212', // Darker background (from design.md)
      secondary: '#1E1E1E', // Slightly lighter for cards
      tertiary: '#2D2D2D', // For highlights
      overlay: 'rgba(0, 0, 0, 0.7)',
      card: '#1A1A1A',
      battle: '#0A0A0A', // Even darker for battle arena
      gradient: {
        start: '#121212',
        mid: '#1a1a2e',
        end: '#16213e',
      },
    },

    // Text colors
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.8)',
      tertiary: 'rgba(255, 255, 255, 0.6)',
      disabled: 'rgba(255, 255, 255, 0.4)',
      monospace: '#E0E0E0', // For technical/stats text
    },

    // Border colors
    border: {
      default: 'rgba(255, 255, 255, 0.1)',
      active: 'rgba(255, 255, 255, 0.3)',
      highlight: '#3390EC',
      card: '#2C2C2C',
    },

    // Status colors for battles
    status: {
      attack: '#FF5252',
      defend: '#64B5F6',
      heal: '#66BB6A',
      inactive: '#9E9E9E',
    },

    // Robot type colors
    robotTypes: {
      attack: '#F44336',
      defense: '#2196F3',
      utility: '#4CAF50',
      speed: '#FFC107',
      standard: '#9C27B0',
    },
  },

  // Font stack - add monospace for technical details
  fonts: {
    body: "'Roboto', sans-serif",
    monospace: "'Roboto Mono', monospace",
    heading: "'Roboto', sans-serif",
  },

  // Font sizes
  fontSizes: {
    xs: '0.75rem',    // 12px
    sm: '0.875rem',   // 14px
    md: '1rem',       // 16px
    lg: '1.125rem',   // 18px
    xl: '1.25rem',    // 20px
    '2xl': '1.5rem',  // 24px
    '3xl': '1.875rem' // 30px
  },

  // Font weights
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },

  // Spacing
  spacing: {
    xs: '0.25rem',  // 4px
    sm: '0.5rem',   // 8px
    md: '1rem',     // 16px
    lg: '1.5rem',   // 24px
    xl: '2rem',     // 32px
    '2xl': '2.5rem' // 40px
  },

  // Border radius
  borderRadius: {
    sm: '0.25rem',  // 4px
    md: '0.5rem',   // 8px
    lg: '0.75rem',  // 12px
    xl: '1rem',     // 16px
    '2xl': '1.5rem', // 24px
    full: '9999px', // Circular
  },

  // Animation durations
  animation: {
    fast: '0.15s',
    normal: '0.3s',
    slow: '0.5s',
    pulse: '2s',
  },

  // Shadows
  shadows: {
    sm: '0 2px 4px rgba(0, 0, 0, 0.2)',
    md: '0 4px 8px rgba(0, 0, 0, 0.25)',
    lg: '0 8px 16px rgba(0, 0, 0, 0.3)',
    inner: 'inset 0 2px 4px rgba(0, 0, 0, 0.25)',
    glow: '0 0 15px',
  },

  // Z-index values
  zIndex: {
    base: 1,
    dropdown: 10,
    sticky: 100,
    fixed: 200,
    modal: 300,
    popover: 400,
    toast: 500,
  },

  // Card styles
  cards: {
    default: {
      bg: '#1A1A1A',
      border: '1px solid #2C2C2C',
      borderRadius: '12px',
      padding: '16px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    battle: {
      bg: '#1E1E1E',
      border: '1px solid #333333',
      borderRadius: '16px',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
    },
  },

  // Button styles
  buttons: {
    primary: {
      bg: '#3390EC',
      hoverBg: '#2980D1',
      activeBg: '#1F6AB2',
      text: '#FFFFFF',
      border: 'none',
    },
    secondary: {
      bg: '#2D2D2D',
      hoverBg: '#3D3D3D',
      activeBg: '#4D4D4D',
      text: '#FFFFFF',
      border: '1px solid #3D3D3D',
    },
    danger: {
      bg: '#FF3B30',
      hoverBg: '#E6352B',
      activeBg: '#CC2F26',
      text: '#FFFFFF',
      border: 'none',
    },
    success: {
      bg: '#4CAF50',
      hoverBg: '#449D48',
      activeBg: '#3D8B40',
      text: '#FFFFFF',
      border: 'none',
    },
  },
};

// Use the dark theme as the default
export const theme = darkTheme;

export default theme;