import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuthContext } from '../../context/AuthContext';
import { Robot } from '../../types';

// Mock robots for demonstration
const mockRobots: Robot[] = [
  {
    id: "robot1",
    name: "Electric Titan",
    type: "Electric-Strong",
    level: 3,
    energy: 50,
    maxEnergy: 100,
    armor: 60,
    maxArmor: 100,
    characteristics: {
      power: 15,
      speed: 8,
      durability: 10,
      memory: 6,
    },
    slots: [
      { id: "slot1", item: "Electric Shield" },
      { id: "slot2", item: "Speed Booster" },
      { id: "slot3", item: null },
      { id: "slot4", item: null },
    ],
  },
  {
    id: "robot2",
    name: "Stealth Predator",
    type: "Stealth-Agile",
    level: 2,
    energy: 30,
    maxEnergy: 80,
    armor: 20,
    maxArmor: 50,
    characteristics: {
      power: 9,
      speed: 14,
      durability: 6,
      memory: 10,
    },
    slots: [
      { id: "slot1", item: "Cloaking Device" },
      { id: "slot2", item: null },
      { id: "slot3", item: null },
      { id: "slot4", item: null },
    ],
  }
];

// Types for crafting recipes
interface CraftingMaterial {
  id: string;
  name: string;
  quantity: number;
  icon?: string;
}

interface CraftingRecipe {
  id: string;
  name: string;
  description: string;
  resultItemId: string;
  materials: CraftingMaterial[];
  level: number;
  icon?: string;
  stats: {
    power?: number;
    defense?: number;
    energy?: number;
    speed?: number;
  };
  type: 'weapon' | 'armor' | 'utility';
  rarity: 'common' | 'rare' | 'epic' | 'legendary';
}

// Mock crafting materials and player inventory
const mockPlayerMaterials = [
  { id: 'metal_scrap', name: 'Metal Scrap', quantity: 25, icon: '🔩' },
  { id: 'energy_cell', name: 'Energy Cell', quantity: 10, icon: '⚡' },
  { id: 'circuit_board', name: 'Circuit Board', quantity: 8, icon: '🔌' },
  { id: 'nano_fiber', name: 'Nano Fiber', quantity: 5, icon: '🧵' },
  { id: 'quantum_core', name: 'Quantum Core', quantity: 2, icon: '💠' },
];

// Mock crafting recipes
const mockCraftingRecipes: CraftingRecipe[] = [
  {
    id: 'laser_blaster',
    name: 'Laser Blaster',
    description: 'A basic energy weapon with decent damage output.',
    resultItemId: 'weapon1',
    materials: [
      { id: 'metal_scrap', name: 'Metal Scrap', quantity: 10, icon: '🔩' },
      { id: 'energy_cell', name: 'Energy Cell', quantity: 5, icon: '⚡' },
      { id: 'circuit_board', name: 'Circuit Board', quantity: 2, icon: '🔌' },
    ],
    level: 1,
    icon: '🔫',
    stats: {
      power: 12,
    },
    type: 'weapon',
    rarity: 'common'
  },
  {
    id: 'energy_shield',
    name: 'Energy Shield',
    description: 'Basic defense module that absorbs incoming energy attacks.',
    resultItemId: 'armor1',
    materials: [
      { id: 'metal_scrap', name: 'Metal Scrap', quantity: 8, icon: '🔩' },
      { id: 'energy_cell', name: 'Energy Cell', quantity: 6, icon: '⚡' },
      { id: 'circuit_board', name: 'Circuit Board', quantity: 1, icon: '🔌' },
    ],
    level: 1,
    icon: '🛡️',
    stats: {
      defense: 15,
    },
    type: 'armor',
    rarity: 'common'
  },
  {
    id: 'quantum_disruptor',
    name: 'Quantum Disruptor',
    description: 'Advanced weapon that can penetrate armor effectively.',
    resultItemId: 'weapon2',
    materials: [
      { id: 'metal_scrap', name: 'Metal Scrap', quantity: 15, icon: '🔩' },
      { id: 'energy_cell', name: 'Energy Cell', quantity: 8, icon: '⚡' },
      { id: 'circuit_board', name: 'Circuit Board', quantity: 5, icon: '🔌' },
      { id: 'quantum_core', name: 'Quantum Core', quantity: 1, icon: '💠' },
    ],
    level: 3,
    icon: '⚡',
    stats: {
      power: 25,
      speed: -5,
    },
    type: 'weapon',
    rarity: 'rare'
  },
  {
    id: 'stealth_module',
    name: 'Stealth Module',
    description: 'Utility module that enhances a robot\'s speed and evasion.',
    resultItemId: 'utility1',
    materials: [
      { id: 'nano_fiber', name: 'Nano Fiber', quantity: 3, icon: '🧵' },
      { id: 'circuit_board', name: 'Circuit Board', quantity: 4, icon: '🔌' },
      { id: 'energy_cell', name: 'Energy Cell', quantity: 2, icon: '⚡' },
    ],
    level: 2,
    icon: '👁️',
    stats: {
      speed: 15,
      power: -3,
    },
    type: 'utility',
    rarity: 'rare'
  },
];

// Styled components
const WorkshopContainer = styled.div`
  padding: ${props => props.theme.spacing.md};
  max-width: 1000px;
  margin: 0 auto;
`;

const WorkshopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.lg};
`;

const WorkshopTitle = styled.h1`
  font-size: ${props => props.theme.fontSizes['2xl']};
  margin: 0;
`;

const TabsContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing.xs};
  margin-bottom: ${props => props.theme.spacing.md};
  border-bottom: 1px solid ${props => props.theme.colors.border.default};
  padding-bottom: ${props => props.theme.spacing.sm};
`;

const Tab = styled.button<{ active: boolean }>`
  padding: ${props => props.theme.spacing.sm} ${props => props.theme.spacing.md};
  border-radius: ${props => props.theme.borderRadius.md};
  background-color: ${props => props.active ? props.theme.colors.primary : 'transparent'};
  color: ${props => props.active ? '#fff' : props.theme.colors.text.primary};
  border: none;
  cursor: pointer;
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  transition: all ${props => props.theme.animation.fast};

  &:hover {
    background-color: ${props => props.active ? props.theme.colors.primary : props.theme.colors.background.tertiary};
  }
`;

const WorkshopSection = styled.div`
  margin-bottom: ${props => props.theme.spacing.xl};
`;

const SectionTitle = styled.h2`
  font-size: ${props => props.theme.fontSizes.xl};
  margin-bottom: ${props => props.theme.spacing.md};
`;

// Repair Station styles
const RepairList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: ${props => props.theme.spacing.md};
`;

const RobotCard = styled.div`
  background-color: ${props => props.theme.colors.background.secondary};
  border-radius: ${props => props.theme.borderRadius.lg};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.border.default};
  box-shadow: ${props => props.theme.shadows.md};
  transition: transform ${props => props.theme.animation.fast};

  &:hover {
    transform: translateY(-2px);
  }
`;

const RobotHeader = styled.div`
  padding: ${props => props.theme.spacing.md};
  border-bottom: 1px solid ${props => props.theme.colors.border.default};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RobotName = styled.h3`
  margin: 0;
  font-size: ${props => props.theme.fontSizes.lg};
`;

const RobotType = styled.span`
  background-color: ${props => props.theme.colors.background.tertiary};
  padding: 2px 8px;
  border-radius: ${props => props.theme.borderRadius.sm};
  font-size: ${props => props.theme.fontSizes.xs};
`;

const RobotBody = styled.div`
  padding: ${props => props.theme.spacing.md};
`;

const RobotStats = styled.div`
  margin-bottom: ${props => props.theme.spacing.md};
`;

const StatLabel = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${props => props.theme.fontSizes.sm};
  color: ${props => props.theme.colors.text.secondary};
  margin-bottom: ${props => props.theme.spacing.xs};
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${props => props.theme.colors.background.tertiary};
  border-radius: ${props => props.theme.borderRadius.full};
  overflow: hidden;
  margin-bottom: ${props => props.theme.spacing.sm};
`;

const ProgressBar = styled.div<{ value: number; color: string }>`
  height: 100%;
  width: ${props => props.value}%;
  background-color: ${props => props.color};
  transition: width ${props => props.theme.animation.normal};
`;

const RepairButton = styled.button<{ disabled: boolean }>`
  width: 100%;
  padding: ${props => props.theme.spacing.sm};
  background-color: ${props => props.disabled ? props.theme.colors.disabled : props.theme.colors.success};
  color: white;
  border: none;
  border-radius: ${props => props.theme.borderRadius.md};
  font-weight: bold;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

  &:hover {
    background-color: ${props => props.disabled ? props.theme.colors.disabled : props.theme.colors.success + 'DD'};
  }
`;

const RepairCost = styled.div`
  text-align: center;
  margin-top: ${props => props.theme.spacing.xs};
  font-size: ${props => props.theme.fontSizes.sm};
  color: ${props => props.theme.colors.text.secondary};
`;

// Crafting System styles
const CraftingContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.spacing.lg};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const RecipesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.md};
`;

const RecipeCard = styled.div<{ selected: boolean, craftable: boolean }>`
  background-color: ${props => props.theme.colors.background.secondary};
  border-radius: ${props => props.theme.borderRadius.lg};
  border: 1px solid ${props => props.selected ? props.theme.colors.primary : props.theme.colors.border.default};
  padding: ${props => props.theme.spacing.md};
  cursor: pointer;
  opacity: ${props => props.craftable ? 1 : 0.7};
  transition: all ${props => props.theme.animation.fast};
  box-shadow: ${props => props.selected ? `0 0 0 2px ${props.theme.colors.primary}` : 'none'};

  &:hover {
    border-color: ${props => props.theme.colors.primary};
    transform: translateY(-2px);
  }
`;

const RecipeHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.sm};
  margin-bottom: ${props => props.theme.spacing.sm};
`;

const RecipeIcon = styled.span`
  font-size: ${props => props.theme.fontSizes['2xl']};
  line-height: 1;
`;

const RecipeInfo = styled.div`
  flex: 1;
`;

const RecipeName = styled.h4`
  margin: 0;
  font-size: ${props => props.theme.fontSizes.lg};
`;

const RecipeType = styled.span<{ type: string }>`
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => {
    switch (props.type) {
      case 'weapon': return props.theme.colors.danger;
      case 'armor': return props.theme.colors.primary;
      case 'utility': return props.theme.colors.success;
      default: return props.theme.colors.text.secondary;
    }
  }};
`;

const RecipeDescription = styled.p`
  color: ${props => props.theme.colors.text.secondary};
  font-size: ${props => props.theme.fontSizes.sm};
  margin: ${props => props.theme.spacing.sm} 0;
`;

const RequirementsList = styled.div`
  margin-top: ${props => props.theme.spacing.sm};
`;

const Requirement = styled.div<{ sufficient?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.xs};
  font-size: ${props => props.theme.fontSizes.sm};
  color: ${props => props.sufficient !== false ? props.theme.colors.text.secondary : props.theme.colors.danger};
  margin-bottom: 2px;
`;

const CraftingDetailsSection = styled.div`
  background-color: ${props => props.theme.colors.background.secondary};
  border-radius: ${props => props.theme.borderRadius.lg};
  border: 1px solid ${props => props.theme.colors.border.default};
  padding: ${props => props.theme.spacing.md};
`;

const CraftingTitle = styled.h3`
  margin-top: 0;
  margin-bottom: ${props => props.theme.spacing.md};
  font-size: ${props => props.theme.fontSizes.xl};
`;

const DetailItem = styled.div`
  margin-bottom: ${props => props.theme.spacing.md};
`;

const DetailLabel = styled.div`
  font-size: ${props => props.theme.fontSizes.sm};
  color: ${props => props.theme.colors.text.secondary};
  margin-bottom: ${props => props.theme.spacing.xs};
`;

const MaterialsList = styled.div`
  margin: ${props => props.theme.spacing.md} 0;
`;

const MaterialItem = styled.div<{ sufficient?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.spacing.xs};
  border-bottom: 1px solid ${props => props.theme.colors.border.default};
  
  &:last-child {
    border-bottom: none;
  }
  
  color: ${props => props.sufficient !== false ? props.theme.colors.text.primary : props.theme.colors.danger};
`;

const MaterialName = styled.span`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.xs};
`;

const CraftButton = styled.button<{ disabled: boolean }>`
  width: 100%;
  padding: ${props => props.theme.spacing.md};
  background-color: ${props => props.disabled ? props.theme.colors.disabled : props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: ${props => props.theme.borderRadius.md};
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.md};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  margin-top: ${props => props.theme.spacing.md};

  &:hover {
    background-color: ${props => props.disabled ? props.theme.colors.disabled : props.theme.colors.primary + 'DD'};
  }
`;

const ItemStats = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing.xs};
  margin: ${props => props.theme.spacing.md} 0;
  font-family: ${props => props.theme.fonts?.monospace};
  font-size: ${props => props.theme.fontSizes.xs};
`;

const StatBadge = styled.span<{ positive: boolean }>`
  background-color: ${props => props.positive ? props.theme.colors.success + '33' : props.theme.colors.danger + '33'};
  color: ${props => props.positive ? props.theme.colors.success : props.theme.colors.danger};
  padding: 2px 6px;
  border-radius: ${props => props.theme.borderRadius.sm};
  display: inline-flex;
  align-items: center;
`;

// Workshop component
const Workshop: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'repair' | 'craft'>('repair');
  const [robots, setRobots] = useState<Robot[]>(mockRobots);
  const [selectedRecipe, setSelectedRecipe] = useState<CraftingRecipe | null>(null);
  const [playerMaterials, setPlayerMaterials] = useState(mockPlayerMaterials);
  const [craftingRecipes, setCraftingRecipes] = useState(mockCraftingRecipes);
  const { user } = useAuthContext();

  // Calculate repair cost
  const calculateRepairCost = (robot: Robot) => {
    const energyMissing = robot.maxEnergy - robot.energy;
    const armorMissing = robot.maxArmor - robot.armor;
    const total = Math.ceil((energyMissing + armorMissing) * 0.5);
    return Math.max(0, total);
  };

  // Handle robot repair
  const handleRepair = (robotId: string) => {
    setRobots(
      robots.map(robot => {
        if (robot.id === robotId) {
          return {
            ...robot,
            energy: robot.maxEnergy,
            armor: robot.maxArmor
          };
        }
        return robot;
      })
    );
    alert(`Robot ${robotId} has been fully repaired!`);
  };

  // Check if we have enough materials for a recipe
  const canCraftRecipe = (recipe: CraftingRecipe) => {
    return recipe.materials.every(material => {
      const playerMaterial = playerMaterials.find(m => m.id === material.id);
      return playerMaterial && playerMaterial.quantity >= material.quantity;
    });
  };

  // Handle crafting
  const handleCraft = () => {
    if (!selectedRecipe) return;
    
    // Check if we have enough materials
    if (!canCraftRecipe(selectedRecipe)) {
      alert('Not enough materials to craft this item!');
      return;
    }

    // Subtract materials
    const updatedMaterials = playerMaterials.map(material => {
      const requiredMaterial = selectedRecipe.materials.find(m => m.id === material.id);
      if (requiredMaterial) {
        return {
          ...material,
          quantity: material.quantity - requiredMaterial.quantity
        };
      }
      return material;
    });

    setPlayerMaterials(updatedMaterials);
    alert(`Successfully crafted ${selectedRecipe.name}!`);
  };

  // Helper function to render stat badges
  const renderStats = (stats: CraftingRecipe['stats']) => {
    return Object.entries(stats).map(([key, value]) => {
      const isPositive = value >= 0;
      const prefix = isPositive ? '+' : '';
      let statName = key;
      
      // Capitalize first letter
      if (statName) {
        statName = statName.charAt(0).toUpperCase() + statName.slice(1);
      }
      
      return (
        <StatBadge key={key} positive={isPositive}>
          {statName}: {prefix}{value}
        </StatBadge>
      );
    });
  };

  return (
    <WorkshopContainer>
      <WorkshopHeader>
        <WorkshopTitle>Workshop</WorkshopTitle>
      </WorkshopHeader>
      
      <TabsContainer>
        <Tab 
          active={activeTab === 'repair'} 
          onClick={() => setActiveTab('repair')}
        >
          Repair Station
        </Tab>
        <Tab 
          active={activeTab === 'craft'} 
          onClick={() => setActiveTab('craft')}
        >
          Crafting System
        </Tab>
      </TabsContainer>
      
      {activeTab === 'repair' ? (
        <WorkshopSection>
          <SectionTitle>Repair Your Robots</SectionTitle>
          <RepairList>
            {robots.map(robot => {
              const energyPercent = (robot.energy / robot.maxEnergy) * 100;
              const armorPercent = (robot.armor / robot.maxArmor) * 100;
              const needsRepair = robot.energy < robot.maxEnergy || robot.armor < robot.maxArmor;
              const repairCost = calculateRepairCost(robot);
              
              return (
                <RobotCard key={robot.id}>
                  <RobotHeader>
                    <RobotName>{robot.name}</RobotName>
                    <RobotType>{robot.type}</RobotType>
                  </RobotHeader>
                  <RobotBody>
                    <RobotStats>
                      <StatLabel>
                        <span>Energy</span>
                        <span>{robot.energy}/{robot.maxEnergy}</span>
                      </StatLabel>
                      <ProgressBarContainer>
                        <ProgressBar 
                          value={energyPercent} 
                          color={energyPercent < 30 ? '#FF3B30' : energyPercent < 70 ? '#FF9500' : '#4CAF50'} 
                        />
                      </ProgressBarContainer>
                      
                      <StatLabel>
                        <span>Armor</span>
                        <span>{robot.armor}/{robot.maxArmor}</span>
                      </StatLabel>
                      <ProgressBarContainer>
                        <ProgressBar 
                          value={armorPercent} 
                          color={armorPercent < 30 ? '#FF3B30' : armorPercent < 70 ? '#FF9500' : '#4CAF50'} 
                        />
                      </ProgressBarContainer>
                    </RobotStats>
                    
                    <RepairButton 
                      disabled={!needsRepair}
                      onClick={() => handleRepair(robot.id)}
                    >
                      {needsRepair ? 'Repair Now' : 'Fully Repaired'}
                    </RepairButton>
                    {needsRepair && (
                      <RepairCost>Cost: {repairCost} credits</RepairCost>
                    )}
                  </RobotBody>
                </RobotCard>
              );
            })}
          </RepairList>
        </WorkshopSection>
      ) : (
        <WorkshopSection>
          <SectionTitle>Craft New Equipment</SectionTitle>
          <CraftingContainer>
            <RecipesList>
              {craftingRecipes.map(recipe => {
                const canCraft = canCraftRecipe(recipe);
                return (
                  <RecipeCard 
                    key={recipe.id} 
                    selected={selectedRecipe?.id === recipe.id}
                    craftable={canCraft}
                    onClick={() => setSelectedRecipe(recipe)}
                  >
                    <RecipeHeader>
                      <RecipeIcon>{recipe.icon}</RecipeIcon>
                      <RecipeInfo>
                        <RecipeName>{recipe.name}</RecipeName>
                        <RecipeType type={recipe.type}>
                          {recipe.type.charAt(0).toUpperCase() + recipe.type.slice(1)} • Level {recipe.level}
                        </RecipeType>
                      </RecipeInfo>
                    </RecipeHeader>
                    <RecipeDescription>{recipe.description}</RecipeDescription>
                    <RequirementsList>
                      {recipe.materials.slice(0, 2).map(material => {
                        const playerMaterial = playerMaterials.find(m => m.id === material.id);
                        const sufficient = playerMaterial && playerMaterial.quantity >= material.quantity;
                        
                        return (
                          <Requirement key={material.id} sufficient={sufficient}>
                            {material.icon} {material.name}: {playerMaterial?.quantity || 0}/{material.quantity}
                          </Requirement>
                        );
                      })}
                      {recipe.materials.length > 2 && (
                        <Requirement sufficient={true}>
                          ... and {recipe.materials.length - 2} more materials
                        </Requirement>
                      )}
                    </RequirementsList>
                  </RecipeCard>
                );
              })}
            </RecipesList>
            
            <CraftingDetailsSection>
              {selectedRecipe ? (
                <>
                  <CraftingTitle>
                    <RecipeIcon>{selectedRecipe.icon}</RecipeIcon> {selectedRecipe.name}
                  </CraftingTitle>
                  
                  <DetailItem>
                    <DetailLabel>Description</DetailLabel>
                    <div>{selectedRecipe.description}</div>
                  </DetailItem>
                  
                  <DetailItem>
                    <DetailLabel>Rarity</DetailLabel>
                    <div>{selectedRecipe.rarity.charAt(0).toUpperCase() + selectedRecipe.rarity.slice(1)}</div>
                  </DetailItem>
                  
                  <DetailItem>
                    <DetailLabel>Stats</DetailLabel>
                    <ItemStats>
                      {renderStats(selectedRecipe.stats)}
                    </ItemStats>
                  </DetailItem>
                  
                  <DetailItem>
                    <DetailLabel>Required Materials</DetailLabel>
                    <MaterialsList>
                      {selectedRecipe.materials.map(material => {
                        const playerMaterial = playerMaterials.find(m => m.id === material.id);
                        const sufficient = playerMaterial && playerMaterial.quantity >= material.quantity;
                        
                        return (
                          <MaterialItem key={material.id} sufficient={sufficient}>
                            <MaterialName>
                              {material.icon} {material.name}
                            </MaterialName>
                            <span>{playerMaterial?.quantity || 0}/{material.quantity}</span>
                          </MaterialItem>
                        );
                      })}
                    </MaterialsList>
                  </DetailItem>
                  
                  <CraftButton 
                    disabled={!canCraftRecipe(selectedRecipe)}
                    onClick={handleCraft}
                  >
                    Craft Item
                  </CraftButton>
                </>
              ) : (
                <div>Select a recipe to see details</div>
              )}
            </CraftingDetailsSection>
          </CraftingContainer>
        </WorkshopSection>
      )}
    </WorkshopContainer>
  );
};

export default Workshop;