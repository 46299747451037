import { THEME } from '@tonconnect/ui-react';

// In development, use the local manifest
const isProduction = process.env.NODE_ENV === 'production';
export const manifestUrl = isProduction 
  ? 'https://impol.link/tonconnect-manifest.json'
  : `${window.location.origin}/tonconnect-manifest.json`;

export const tonConnectOptions = {
  manifestUrl,
  // For development and testing, you might want to use testnet
  uiPreferences: {
    theme: THEME.DARK
  },
  walletsList: [
    // Wallets enabled by default
    {
      name: 'Tonkeeper',
      imageUrl: 'https://tonkeeper.com/assets/tonconnect-icon.png',
      aboutUrl: 'https://tonkeeper.com',
      bridgeUrl: 'https://bridge.tonapi.io/bridge',
      universalLink: 'https://app.tonkeeper.com',
    },
    {
      name: 'Tonhub',
      imageUrl: 'https://tonhub.com/tonconnect_logo.png',
      aboutUrl: 'https://tonhub.com',
      bridgeUrl: 'https://connect.tonhub.com',
      universalLink: 'https://tonhub.com',
    },
  ],
  testnet: true // Change to false for mainnet in production
};

// Sample manifest file content for reference (store this on your server)
// {
//   "url": "https://yourdapp.com",
//   "name": "Battle Game",
//   "iconUrl": "https://yourdapp.com/logo.png",
//   "termsOfUseUrl": "https://yourdapp.com/terms",
//   "privacyPolicyUrl": "https://yourdapp.com/privacy"
// }