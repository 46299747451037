import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Robot } from "../../types";
import styles from "../../style/RobotManagement.module.css";
import { api } from "../../services/api";
import { useTelegram } from "../../hooks/useTelegram";
import Card from "../../components/ui/Card";
import Button from "../../components/ui/Button";
import { Star, Plus, Info } from "react-feather";
import styled from "styled-components";
import theme from "../../style/theme";

interface RobotManagementProps {
  robots?: Robot[];
}

const PriceTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.background.tertiary};
  color: ${theme.colors.warning};
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  border-radius: ${theme.borderRadius.md};
  font-weight: 600;
  font-size: ${theme.fontSizes.sm};
  margin-top: ${theme.spacing.xs};
`;

const ErrorMessage = styled.div`
  color: ${theme.colors.danger};
  background-color: rgba(255, 59, 48, 0.2); // Using danger color with transparency
  padding: ${theme.spacing.sm};
  border-radius: ${theme.borderRadius.md};
  margin-top: ${theme.spacing.md};
  text-align: center;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ModalContent = styled.div`
  background-color: ${theme.colors.background.primary};
  padding: ${theme.spacing.lg};
  border-radius: ${theme.borderRadius.lg};
  width: 90%;
  max-width: 500px;
`;

const FormGroup = styled.div`
  margin-bottom: ${theme.spacing.md};
`;

const Label = styled.label`
  display: block;
  margin-bottom: ${theme.spacing.xs};
  font-weight: 600;
`;

const Input = styled.input`
  width: 100%;
  padding: ${theme.spacing.sm};
  border: 1px solid ${theme.colors.border};
  border-radius: ${theme.borderRadius.md};
  background-color: ${theme.colors.background.secondary};
  color: ${theme.colors.text.primary};
`;

const Select = styled.select`
  width: 100%;
  padding: ${theme.spacing.sm};
  border: 1px solid ${theme.colors.border};
  border-radius: ${theme.borderRadius.md};
  background-color: ${theme.colors.background.secondary};
  color: ${theme.colors.text.primary};
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing.sm};
  margin-top: ${theme.spacing.lg};
`;

export const RobotManagement: React.FC<RobotManagementProps> = ({ robots: propRobots }) => {
  const [robots, setRobots] = useState<Robot[]>(propRobots || []);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false); // Separate state for refresh vs initial load
  const [error, setError] = useState<string | null>(null);
  const { requestStars, haptic } = useTelegram();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newRobotType, setNewRobotType] = useState("Standard");
  const [purchasingSlot, setPurchasingSlot] = useState(false);
  
  const maxRobots = 3;

  // Function to fetch user's robots from API
  const fetchRobots = async (isRefresh = false) => {
    if (isRefresh) {
      setRefreshing(true);
    } else {
      setLoading(true);
    }
    
    setError(null);
    
    try {
      const response = await api.getUserRobots();
      if (response.data) {
        setRobots(response.data);
        if (isRefresh) {
          haptic.notificationOccurred('success');
        }
      } else if (response.error) {
        console.error("Error fetching robots:", response.error);
        setError("Failed to load robots. Please try again.");
        if (isRefresh) {
          haptic.notificationOccurred('error');
        }
      }
    } catch (err) {
      console.error("Failed to fetch robots", err);
      setError("Failed to load robots. Please try again.");
      if (isRefresh) {
        haptic.notificationOccurred('error');
      }
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };
  
  // Fetch robots on initial load
  useEffect(() => {
    // Only fetch from API if no robots were provided as props
    if (!propRobots) {
      fetchRobots();
    } else {
      setRobots(propRobots);
    }
  }, [fetchRobots, propRobots]);

  const getSlotPrice = (slotIndex: number): number => {
    if (slotIndex === 0) return 0; // First slot is free
    if (slotIndex === 1) return 500; // Second slot costs 500 stars
    if (slotIndex === 2) return 2000; // Third slot costs 2000 stars
    return 0; // Should never reach here
  };

  const canPurchaseSlot = (slotIndex: number): boolean => {
    if (slotIndex >= maxRobots) return false;
    return true;
  };
  
  const isSlotAvailable = (slotIndex: number): boolean => {
    return robots.length > slotIndex;
  };

  const handleCreateRobot = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Robot creation data - name will be generated on the server
      const robotData = {
        type: newRobotType
      };
      
      const response = await api.createRobot(robotData);
      
      if (response.error) {
        setError(response.error);
        haptic.notificationOccurred('error');
        return;
      }
      
      if (response.data) {
        // Refresh the list to get the latest robot data from server
        await fetchRobots(false);
        setShowCreateForm(false);
        haptic.notificationOccurred('success');
        
        // Reset form
        setNewRobotType("Standard");
      }
    } catch (err) {
      setError("Failed to create robot. Please try again later.");
      console.error("Robot creation error:", err);
      haptic.notificationOccurred('error');
    } finally {
      setLoading(false);
    }
  };

  const handlePurchaseSlot = async (slotIndex: number) => {
    if (!canPurchaseSlot(slotIndex)) {
      return;
    }
    
    const price = getSlotPrice(slotIndex);
    setPurchasingSlot(true);
    setError(null);
    
    try {
      // For slots that require stars, request them from Telegram
      if (price > 0) {
        const starsPurchased = await requestStars(price);
        
        if (!starsPurchased) {
          setError("Purchase was canceled");
          setPurchasingSlot(false);
          return;
        }
        
        // Confirm with backend that we purchased a slot
        const response = await api.purchaseSlot(slotIndex, price);
        
        if (response.error) {
          setError(response.error);
          haptic.notificationOccurred('error');
          return;
        }
        
        // Update local state to show the new slot is available
        if (response.data && response.data.success) {
          haptic.notificationOccurred('success');
          // Refresh robots list to ensure we have the latest slot information
          await fetchRobots(false);
          setShowCreateForm(true); // Show create form after successful purchase
        }
      } else {
        // First slot is free, just show the form
        setShowCreateForm(true);
      }
    } catch (err) {
      setError("Failed to purchase slot. Please try again later.");
      console.error("Slot purchase error:", err);
      haptic.notificationOccurred('error');
    } finally {
      setPurchasingSlot(false);
    }
  };
  
  const toggleCreateForm = () => {
    setShowCreateForm(prev => !prev);
    setError(null);
  };

  return (
    <div className={styles.container}>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        marginBottom: theme.spacing.md
      }}>
        <h1 className={styles.title}>Your Robots</h1>
        <Button 
          variant="secondary"
          onClick={() => fetchRobots(true)}
          disabled={loading || refreshing}
          size="sm"
        >
          {refreshing ? "Refreshing..." : "Refresh"}
        </Button>
      </div>
      
      {refreshing && robots.length > 0 && (
        <div className={styles.refreshing}>Refreshing robot data...</div>
      )}
      
      {loading && robots.length === 0 ? (
        <div className={styles.loading}>Loading robots...</div>
      ) : error ? (
        <div className={styles.error}>
          {error} 
          <Button 
            variant="primary" 
            onClick={() => fetchRobots(false)} 
            size="sm" 
            style={{ marginLeft: theme.spacing.sm, marginTop: theme.spacing.xs }}
            disabled={loading || refreshing}
          >
            Try Again
          </Button>
        </div>
      ) : (
        <>
          <h3>Robot Slots ({robots.length}/{maxRobots})</h3>
          <div className={styles.robotsList}>
            {/* Show all 3 slots with their status */}
            {Array.from({ length: maxRobots }).map((_, index) => {
              const robot = robots[index];
              
              if (robot) {
                // This slot has a robot, show it
                return (
                  <div key={robot.id} className={styles.robotCard}>
                    <h2>{robot.name}</h2>
                    <div className={styles.robotInfo}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Type: {robot.type}</span>
                        <span>Lvl: {robot.level}</span>
                      </div>
                      <div className={styles.statsContainer}>
                        <div className={styles.stat}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Energy</span>
                            <span>{robot.energy}/{robot.maxEnergy}</span>
                          </div>
                          <div className={styles.progressBar}>
                            <div 
                              className={styles.progressFill} 
                              style={{ width: `${(robot.energy / robot.maxEnergy) * 100}%` }}
                            />
                          </div>
                        </div>
                        <div className={styles.stat}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Armor</span>
                            <span>{robot.armor}/{robot.maxArmor}</span>
                          </div>
                          <div className={styles.progressBar}>
                            <div 
                              className={styles.progressFill} 
                              style={{ width: `${(robot.armor / robot.maxArmor) * 100}%` }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Link to={`/robots/${robot.id}`} className={styles.manageButton}>
                      Manage
                    </Link>
                  </div>
                );
              } else {
                // This slot is empty, show a slot to purchase
                return (
                  <div key={`empty-slot-${index}`} className={`${styles.slotCard} ${!canPurchaseSlot(index) ? styles.disabledSlot : ''}`}>
                    <h2>Robot Slot {index + 1}</h2>
                    <div className={styles.slotStatus}>
                      {isSlotAvailable(index) ? (
                        <span>Available - Create your robot!</span>
                      ) : (
                        <>
                          <span>Locked</span>
                          {getSlotPrice(index) > 0 && (
                            <PriceTag>
                              <Star size={16} style={{ marginRight: '4px' }} />
                              {getSlotPrice(index)} stars
                            </PriceTag>
                          )}
                        </>
                      )}
                    </div>
                    
                    <Button 
                      variant={isSlotAvailable(index) ? "primary" : "secondary"}
                      onClick={() => isSlotAvailable(index) ? toggleCreateForm() : handlePurchaseSlot(index)}
                      disabled={purchasingSlot || !canPurchaseSlot(index)}
                      size="sm"
                      style={{ marginTop: 'auto' }}
                    >
                      {purchasingSlot && robots.length === index ? "Processing..." : (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Plus size={16} style={{ marginRight: '4px' }} />
                          {isSlotAvailable(index) 
                            ? "Create Robot" 
                            : getSlotPrice(index) === 0 
                              ? "Unlock Free Slot" 
                              : `Unlock Slot (${getSlotPrice(index)} ⭐)`}
                        </div>
                      )}
                    </Button>
                  </div>
                );
              }
            })}
          </div>
        </>
      )}
      
      {error && <ErrorMessage>{error}</ErrorMessage>}
      
      {/* Robot Creation Form - shown directly on the page, not in a modal */}
      {showCreateForm && (
        <div className={styles.createRobotForm}>
          <h2>Create New Robot</h2>
          
          <div style={{ marginBottom: theme.spacing.md, display: 'flex', alignItems: 'center' }}>
            <Info size={16} style={{ marginRight: theme.spacing.sm, color: theme.colors.info }} />
            <span style={{ fontSize: theme.fontSizes.sm, color: theme.colors.text.secondary }}>
              Robot name will be automatically generated based on type
            </span>
          </div>
          
          <FormGroup>
            <Label htmlFor="robotType">Robot Type</Label>
            <Select
              id="robotType"
              value={newRobotType}
              onChange={(e) => setNewRobotType(e.target.value)}
            >
              <option value="Standard">Standard</option>
              <option value="Attack">Attack</option>
              <option value="Defense">Defense</option>
              <option value="Utility">Utility</option>
              <option value="Speed">Speed</option>
            </Select>
          </FormGroup>
          
          <ButtonGroup>
            <Button 
              variant="secondary"
              onClick={() => setShowCreateForm(false)}
              size="sm"
            >
              Cancel
            </Button>
            <Button 
              variant="primary"
              onClick={handleCreateRobot}
              disabled={loading}
              size="sm"
            >
              {loading ? "Creating..." : "Create Robot"}
            </Button>
          </ButtonGroup>
        </div>
      )}
      
      <div style={{ marginTop: theme.spacing.md }}>
        <Card>
          <Card.Title>Robot Slot Prices</Card.Title>
          <Card.Content style={{ padding: theme.spacing.sm }}>
            <div className={styles.costsList}>
              <span>First slot: <strong>Free</strong></span>
              <span>Second slot: <strong>500 ⭐ stars</strong></span>
              <span>Third slot: <strong>2000 ⭐ stars</strong></span>
            </div>
          </Card.Content>
        </Card>
      </div>
    </div>
  );
};

export default RobotManagement;