import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ChevronLeft, Menu, Bell } from 'react-feather';
import { useNavigate, useLocation } from 'react-router-dom';
import theme from '../../style/theme';
import Avatar from '../ui/Avatar';
import { useAuthContext } from '../../context/AuthContext';
import Badge from '../ui/Badge';

interface HeaderProps {
  title?: string;
  showBackButton?: boolean;
  showUserProfile?: boolean;
  onMenuClick?: () => void;
}

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  background-color: ${theme.colors.background.secondary};
  position: sticky;
  top: 0;
  z-index: ${theme.zIndex.sticky};
  height: 56px;
  border-bottom: 1px solid ${theme.colors.border.default};
  width: 100%;
  max-width: 480px; /* Match the main container max-width */
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
`;

const HeaderCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.md};
`;

const HeaderTitle = styled.h1`
  font-size: ${theme.fontSizes.lg};
  font-weight: 500;
  margin: 0;
  color: ${theme.colors.text.primary};
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.text.primary};
  background: transparent;
  border: none;
  cursor: pointer;
  padding: ${theme.spacing.xs};
  
  &:hover {
    color: ${theme.colors.primary};
  }
  
  &:active {
    transform: scale(0.9);
  }
`;

const MenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.text.primary};
  background: transparent;
  border: none;
  cursor: pointer;
  padding: ${theme.spacing.xs};
  
  &:hover {
    color: ${theme.colors.primary};
  }
  
  &:active {
    transform: scale(0.9);
  }
`;

const NotificationsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.text.primary};
  background: transparent;
  border: none;
  cursor: pointer;
  padding: ${theme.spacing.xs};
  position: relative;
  
  &:hover {
    color: ${theme.colors.primary};
  }
  
  &:active {
    transform: scale(0.9);
  }
`;

const NotificationBadge = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
`;

const UserInfo = styled(Link)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  color: ${theme.colors.text.primary};
  text-decoration: none;
  padding: ${theme.spacing.xs};
  border-radius: ${theme.borderRadius.md};
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
`;

const UserName = styled.span`
  font-size: ${theme.fontSizes.sm};
  font-weight: 500;
`;

export const Header: React.FC<HeaderProps> = ({
  title,
  showBackButton = false,
  showUserProfile = true,
  onMenuClick,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, userProfile } = useAuthContext();
  
  const goBack = () => {
    navigate(-1);
  };
  
  // Dummy notification count for demo purposes
  const notificationCount = 2;

  // For web app title, use the pathname
  const getPageTitle = (): string => {
    if (title) return title;
    
    const path = location.pathname;
    
    switch(true) {
      case path === '/':
        return 'Robot Battles';
      case path.startsWith('/battles'):
        return 'Battles';
      case path.startsWith('/battle/'):
        return 'Active Battle';
      case path.startsWith('/robots'):
        return 'My Robots';
      case path.startsWith('/hero'):
        return 'Profile';
      case path.startsWith('/leaderboard'):
        return 'Rankings';
      default:
        return 'Robot Battles';
    }
  };

  return (
    <HeaderContainer>
      <HeaderLeft>
        {showBackButton ? (
          <BackButton onClick={goBack}>
            <ChevronLeft size={24} />
          </BackButton>
        ) : (
          <MenuButton onClick={onMenuClick}>
            <Menu size={24} />
          </MenuButton>
        )}
      </HeaderLeft>
      
      <HeaderCenter>
        <HeaderTitle>{getPageTitle()}</HeaderTitle>
      </HeaderCenter>
      
      <HeaderRight>
        <NotificationsButton>
          <Bell size={22} />
          {notificationCount > 0 && (
            <NotificationBadge>
              <Badge variant="danger" size="sm" pill>{notificationCount}</Badge>
            </NotificationBadge>
          )}
        </NotificationsButton>
        
        {showUserProfile && user && userProfile && (
          <UserInfo to="/hero">
            <Avatar 
              src={userProfile.avatar && !userProfile.avatar.startsWith('avatar') 
                ? userProfile.avatar 
                : null}
              text={user.displayName || user.name}
              size="sm"
              status="online"
            />
            <UserName>Profile</UserName>
          </UserInfo>
        )}
      </HeaderRight>
    </HeaderContainer>
  );
};

export default Header;