import {useState, useEffect, useRef, useCallback} from 'react';
import { useHapticFeedback, useShowPopup } from "@vkruglikov/react-telegram-web-app";
import { api } from '../services/api';
import { useAuthContext } from '../context/AuthContext';

declare global {
  interface Window {
    Telegram: any;
  }
}

// Key to track if the app has been initialized in the current session
const APP_INIT_KEY = 'app_initialized_timestamp';

export const useTelegram = () => {
  const [initDataUnsafe, setInitDataUnsafe] = useState<any>(null);
  const [isInvalidVersion, setIsInvalidVersion] = useState(false);
  const [loading, setLoading] = useState(true);
  const showPopup = useShowPopup();
  const [impactOccurred, notificationOccurred, selectionChanged] = useHapticFeedback();
  
  const initTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { setUserId, setUserProfile, setUser } = useAuthContext();

  // Flag to prevent multiple initializations
  const isInitializing = useRef(false);
  const hasInitialized = useRef(false);

  // Check if this is the first load in the current browser session
  const isFirstLoad = useCallback(() => {
    // Get the current timestamp
    const now = Date.now();
    
    // Get the timestamp from session storage (not localStorage for security)
    const initTimestamp = sessionStorage.getItem(APP_INIT_KEY);
    
    // If there's no timestamp, this is the first load
    if (!initTimestamp) {
      // Set the current timestamp to mark initialization
      sessionStorage.setItem(APP_INIT_KEY, now.toString());
      return true;
    }
    
    // This is not the first load
    return false;
  }, []);

  const clearUserData = useCallback(() => {
    // Clear user data from auth context
    setUserId('');
    setUserProfile(null);
    setUser(null);
    
    // Clear stored session data
    localStorage.removeItem('token');
    localStorage.removeItem('userProfile');
    localStorage.removeItem('sessionStartTime');
  }, [setUserId, setUserProfile, setUser]);

  const initialize = useCallback(async () => {
    // Always set loading to true initially to prevent UI flash
    setLoading(true);
    
    // Return early if already initializing or initialized
    if (isInitializing.current || hasInitialized.current) {
      return true;
    }
    
    // Mark as initializing to prevent repeated calls
    isInitializing.current = true;
    
    // Set a timeout to stop loading after 10 seconds no matter what
    initTimeoutRef.current = setTimeout(() => {
      setLoading(false);
      isInitializing.current = false;
    }, 10000);
    
    // Check if Telegram WebApp is available
    if (!window.Telegram || !window.Telegram.WebApp) {
      setLoading(false);
      isInitializing.current = false;
      
      if (initTimeoutRef.current) {
        clearTimeout(initTimeoutRef.current);
      }
      
      return false;
    }

    // Set initial data
    const unsafeData = window.Telegram.WebApp.initDataUnsafe;
    setInitDataUnsafe(unsafeData);

    try {
      // Always get init data from Telegram
      const initData = window.Telegram.WebApp.initData;
      
      // Check if this is the first load
      const freshLoad = isFirstLoad();
      
      // If this is the first load, always get fresh data from server
      // If not, check if we have existing user data that we can use temporarily
      if (freshLoad) {
        // Clear any existing session data for security
        clearUserData();
        
        // Set session with fresh data from Telegram
        const response = await api.setSession(initData);
        
        if (response.data) {
          const { token, userId, userProfile, user } = response.data;
          
          // Set user data in auth context
          setUserId(userId);
          setUserProfile(userProfile);
          setUser(user);
          
          // Store token and profile for future use
          localStorage.setItem('token', token);
          localStorage.setItem('userProfile', JSON.stringify(userProfile));
        } else {
          throw new Error(response.error || 'Failed to initialize Telegram');
        }
      } else {
        // For page navigation, try to use cached data temporarily
        const existingToken = localStorage.getItem('token');
        const existingProfile = localStorage.getItem('userProfile');
        
        if (existingToken && existingProfile) {
          try {
            const userProfile = JSON.parse(existingProfile);
            setUserId(userProfile.id?.toString() || '');
            setUserProfile(userProfile);
            
            // For a proper implementation, we should refresh the user data from server
            // in the background and update it once received
            const response = await api.setSession(initData);
            
            if (response.data) {
              const { token, userId, userProfile, user } = response.data;
              
              // Update user data in auth context
              setUserId(userId);
              setUserProfile(userProfile);
              setUser(user);
              
              // Update storage
              localStorage.setItem('token', token);
              localStorage.setItem('userProfile', JSON.stringify(userProfile));
            }
          } catch (error) {
            console.error('Error using cached data:', error);
            
            // If there's an error with cached data, get fresh data
            const response = await api.setSession(initData);
            
            if (response.data) {
              const { token, userId, userProfile, user } = response.data;
              
              // Set user data in auth context
              setUserId(userId);
              setUserProfile(userProfile);
              setUser(user);
              
              // Store token and profile
              localStorage.setItem('token', token);
              localStorage.setItem('userProfile', JSON.stringify(userProfile));
            } else {
              throw new Error(response.error || 'Failed to initialize Telegram');
            }
          }
        } else {
          // No valid cached data, get fresh data
          const response = await api.setSession(initData);
          
          if (response.data) {
            const { token, userId, userProfile, user } = response.data;
            
            // Set user data in auth context
            setUserId(userId);
            setUserProfile(userProfile);
            setUser(user);
            
            // Store token and profile
            localStorage.setItem('token', token);
            localStorage.setItem('userProfile', JSON.stringify(userProfile));
          } else {
            throw new Error(response.error || 'Failed to initialize Telegram');
          }
        }
      }
    } catch (error) {
      console.error('Error during Telegram initialization:', error);
      
      // If initialization fails, clear user data for security
      clearUserData();
      
      // Mark as not initialized
      hasInitialized.current = false;
      isInitializing.current = false;
      setLoading(false);
      
      if (initTimeoutRef.current) {
        clearTimeout(initTimeoutRef.current);
      }
      
      return false;
    }
    
    // Mark as initialized
    hasInitialized.current = true;
    isInitializing.current = false;
    
    // Only set loading to false after we've fully initialized
    // This ensures the loading screen remains until authentication is complete
    setTimeout(() => {
      setLoading(false);
    }, 500); // Small delay to ensure state updates properly
    
    // Clear timeout if it exists
    if (initTimeoutRef.current) {
      clearTimeout(initTimeoutRef.current);
    }
    
    return true;
  }, [setInitDataUnsafe, setUserId, setUserProfile, setUser, setLoading, isFirstLoad, clearUserData]);

  // Function to request Telegram stars purchase
  const requestStars = useCallback(async (amount: number): Promise<boolean> => {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not available');
      return false;
    }

    // Check if premium payments are available
    if (!window.Telegram.WebApp.isVersionAtLeast('6.9')) {
      showPopup({
        title: 'Update Required',
        message: 'Please update your Telegram app to use this feature.',
        buttons: [{ type: 'close' }]
      });
      return false;
    }

    try {
      // Request stars using Telegram API 
      // Amount parameter is the number of stars to purchase
      // See documentation: https://core.telegram.org/api/stars
      const result = await window.Telegram.WebApp.requestStars(amount);
      
      // If successful, return true
      if (result) {
        // Trigger haptic feedback on success
        notificationOccurred('success');
        return true;
      }
      
      // If user canceled or payment failed
      return false;
    } catch (error) {
      console.error('Error requesting stars:', error);
      
      // Trigger haptic feedback on error
      notificationOccurred('error');
      return false;
    }
  }, [showPopup, notificationOccurred]);

  // Function to get current star balance (read-only)
  const getStarsBalance = useCallback(async (): Promise<number> => {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not available');
      return 0;
    }

    // Check if stars API is available
    if (!window.Telegram.WebApp.isVersionAtLeast('6.9')) {
      return 0;
    }

    try {
      // Get current star balance
      return window.Telegram.WebApp.getStarsBalance() || 0;
    } catch (error) {
      console.error('Error getting stars balance:', error);
      return 0;
    }
  }, []);

  return {
    initDataUnsafe,
    isInvalidVersion,
    loading,
    initialize,
    showPopup,
    haptic: {
      impactOccurred,
      notificationOccurred,
      selectionChanged
    },
    requestStars,
    getStarsBalance
  };
};