import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

// Context
import { AppProviders } from './context/AppProviders';
import { useAuthContext } from './context/AuthContext';
import { useBattleContext } from './context/BattleContext';
import { ThemeProvider } from './context/ThemeContext';

// Hooks
import { useTelegram } from './hooks/useTelegram';
import useCheckBattle from './hooks/useCheckBattle';

// Components
import MainLayout from './components/layout/MainLayout';
import LoadingScreen from './components/ui/LoadingScreen';
import ThemeSwitcher from './components/ui/ThemeSwitcher';
import BackgroundEffects from './components/ui/BackgroundEffects';

// Features
import Profile from './features/user/Profile';
import Hero from './features/user/Hero';
import Battles from './features/battles/Battles';
import NewBattle from './features/battles/NewBattle';
import RobotManagement from './features/robots/RobotManagement';
import RobotDetails from './features/robots/RobotDetails';
import Wallet from './features/wallet/Wallet';
import Leaderboard from './features/leaderboard/Leaderboard';
import Market from './features/market/Market';
import Workshop from './features/workshop/Workshop';

// Styles
import GlobalStyles from './style/GlobalStyles';

// Services
import { tonConnectOptions } from './services/tonconnect';

// Logs
import LogRocket from 'logrocket';
LogRocket.init('kcy8of/robots');

// Helper components
const OldBattleRedirect: React.FC = () => {
  const { battleId } = useParams<{ battleId: string }>();
  return <Navigate to={`/new-battle/${battleId}`} replace />;
};

const AppContent: React.FC = () => {
  const { loading, isInvalidVersion, initialize } = useTelegram();
  const { isAuthenticated, userId, user } = useAuthContext();
  const { inBattle, activeBattleId, checkActiveBattle } = useBattleContext();
  const { isLoading: checkingBattle } = useCheckBattle();

  useEffect(() => {
    // Initialize on component mount
    const initApp = async () => {
      await initialize();
    };
    
    initApp();
  }, [initialize]);

  // No need to call checkActiveBattle here as useCheckBattle already does this
  // and would cause duplicate session checks

  // Show loading screen until we have fully loaded user data
  // This ensures the app's UI isn't accessible until authentication is complete
  if (loading || checkingBattle || !user || !isAuthenticated) {
    return <LoadingScreen message="Loading user data..." timeout={5000} maxTimeout={15000} />;
  }

  if (isInvalidVersion) {
    return (
      <MainLayout showHeader={false} showFooter={false}>
        <div className="text-center">
          <h1>Update Required</h1>
          <p>Please update your Telegram app to the latest version to use this app.</p>
        </div>
      </MainLayout>
    );
  }

  const ProtectedRoutes = () => {
    return (
      <>
        <ThemeSwitcher />
        <Routes>
          {inBattle && activeBattleId ? (
            <>
              {/* When in battle, only allow accessing battle routes */}
              <Route 
                path="/new-battle/:battleId" 
                element={
                  <MainLayout showFooter={false} showBackButton={false} showHeader={false}>
                    <NewBattle />
                  </MainLayout>
                } 
              />
              {/* Redirect all other routes to the active battle */}
              <Route path="*" element={<Navigate to={`/new-battle/${activeBattleId}`} replace />} />
            </>
          ) : (
            <>
              <Route path="/" element={<MainLayout><Profile /></MainLayout>} />
              <Route path="/battles" element={<MainLayout><Battles /></MainLayout>} />
              <Route 
                path="/battle/:battleId" 
                element={
                  <OldBattleRedirect />
                } 
              />
              <Route 
                path="/hero" 
                element={
                  <MainLayout>
                    <Hero profileId={userId} />
                  </MainLayout>
                } 
              />
              <Route 
                path="/robots" 
                element={
                  <MainLayout>
                    <RobotManagement />
                  </MainLayout>
                } 
              />
              <Route 
                path="/robots/:robotId" 
                element={
                  <MainLayout showBackButton={true}>
                    <RobotDetails />
                  </MainLayout>
                } 
              />
              <Route 
                path="/leaderboard" 
                element={
                  <MainLayout showBackButton={true}>
                    <Leaderboard />
                  </MainLayout>
                } 
              />
              <Route 
                path="/wallet" 
                element={
                  <MainLayout showBackButton={true}>
                    <Wallet />
                  </MainLayout>
                } 
              />
              {/* New Market route */}
              <Route 
                path="/market" 
                element={
                  <MainLayout showBackButton={true}>
                    <Market />
                  </MainLayout>
                } 
              />
              {/* New Workshop route */}
              <Route 
                path="/workshop" 
                element={
                  <MainLayout showBackButton={true}>
                    <Workshop />
                  </MainLayout>
                } 
              />
              <Route 
                path="/new-battle/:battleId?" 
                element={
                  <MainLayout showFooter={false} showBackButton={true}>
                    <NewBattle />
                  </MainLayout>
                } 
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          )}
        </Routes>
      </>
    );
  };

  return <ProtectedRoutes />;
};

const App: React.FC = () => {
  return (
    <AppProviders>
      <ThemeProvider>
        <GlobalStyles />
        <BackgroundEffects />
        <TonConnectUIProvider {...tonConnectOptions}>
          <Router>
            <AppContent />
          </Router>
        </TonConnectUIProvider>
      </ThemeProvider>
    </AppProviders>
  );
};

export default App;
