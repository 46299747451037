import React, { useState, useRef, useCallback, memo } from 'react';
import styled from 'styled-components';
import theme from '../../../style/theme';
import { Robot } from '../../../types';
import HealthBar from '../../../components/ui/HealthBar';

interface BattleArenaProps {
  playerRobots: Robot[];
  opponentRobots: Robot[];
  onAction: (sourceRobotId: string, targetRobotId: string, actionType: 'attack' | 'defend' | 'heal') => void;
}

// Calculate arena dimensions based on the viewport - with improved spacing
const ArenaContainer = styled.div`
  position: relative;
  width: 100%;
  height: 44vh;
  background-color: ${theme.colors.background.secondary};
  background-image: linear-gradient(
    to bottom,
    rgba(41, 128, 185, 0.1) 0%,
    rgba(52, 152, 219, 0.05) 50%,
    rgba(41, 128, 185, 0.1) 100%
  );
  border-radius: ${theme.borderRadius.lg};
  overflow: hidden;
  margin: ${theme.spacing.lg} 0 ${theme.spacing.md} 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
      circle at 50% 50%,
      rgba(52, 152, 219, 0.1) 0%,
      transparent 60%
    );
    z-index: 0;
  }
  
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    top: 50%;
    left: 0;
  }
`;

const RobotContainer = styled.div<{ isPlayer: boolean }>`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: ${theme.spacing.md};
  ${props => props.isPlayer ? 'bottom: 5%;' : 'top: 10%;'}
  z-index: 10;
`;

const RobotWrapper = styled.div<{ 
  isDragging: boolean; 
  isTarget: boolean;
  level?: number;
  isEmpty?: boolean;
}>`
  position: relative;
  width: 80px;
  height: 120px; 
  background-color: ${({ isTarget, isEmpty }) => 
    isEmpty ? theme.colors.background.secondary : 
    isTarget ? 'rgba(46, 204, 113, 0.3)' : theme.colors.background.tertiary};
  border-radius: ${theme.borderRadius.md};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: ${({ isDragging, isEmpty }) => isEmpty ? 'default' : isDragging ? 'grabbing' : 'pointer'};
  transition: all 0.15s ease-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: ${({ isDragging, isEmpty }) => isEmpty ? 0.5 : isDragging ? 0.8 : 1};
  transform: ${({ isDragging, isTarget }) => 
    isDragging ? 'scale(1.05)' : 
    isTarget ? 'scale(1.03)' : 'scale(1)'};
  touch-action: none; /* Disable browser touch actions */
  user-select: none; /* Prevent text selection */
  z-index: ${({ isDragging }) => isDragging ? 100 : 1};
  will-change: transform; /* Optimize performance */
  
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  &::before {
    content: "${props => props.level ? `Lv ${props.level}` : ''}";
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: ${theme.colors.text.primary};
    padding: 2px 4px;
    border-radius: 4px;
    font-weight: bold;
  }
  
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: ${props => {
      if (!props.level) return 'transparent';
      if (props.level < 2) return '#95a5a6'; // Gray
      if (props.level < 3) return '#3498db'; // Blue
      if (props.level < 4) return '#9b59b6'; // Purple
      if (props.level < 5) return '#f39c12'; // Orange
      return '#f1c40f'; // Yellow/gold
    }};
    border-radius: 0 0 ${theme.borderRadius.md} ${theme.borderRadius.md};
  }
`;

// Styles for defeated robot with animation
const DefeatedRobotWrapper = styled(RobotWrapper)`
  opacity: 0.3;
  filter: grayscale(100%);
  transform: rotate(90deg) scale(0.8);
  pointer-events: none;
  transition: all 0.8s ease-out;
  border: 1px solid rgba(255, 0, 0, 0.2);
  
  &::before {
    content: "DEFEATED";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #e74c3c;
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: 0.8;
    z-index: 5;
  }
`;

const RobotImage = styled.div<{ robotType: string; isEmpty?: boolean }>`
  width: 50px;
  height: 50px;
  background-color: ${props => {
    if (props.isEmpty) return '#6c757d';
    switch (props.robotType) {
      case 'attack': return '#e74c3c';
      case 'defense': return '#3498db';
      case 'utility': return '#2ecc71';
      default: return '#95a5a6';
    }
  }};
  border-radius: 50%;
  margin-bottom: ${theme.spacing.sm};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  opacity: ${props => props.isEmpty ? 0.5 : 1};
  
  &::before {
    content: "";
    position: absolute;
    font-size: 24px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
  }
  
  &::after {
    content: "${props => {
      if (props.isEmpty) return '➕';
      switch (props.robotType) {
        case 'attack': return '⚔️';
        case 'defense': return '🛡️';
        case 'utility': return '🔧';
        default: return '🤖';
      }
    }}";
    font-size: 20px;
  }
`;

const RobotStatus = styled.div`
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 8px;
  padding: 1px 4px;
  border-radius: 3px;
  white-space: nowrap;
`;

const RobotName = styled.div`
  font-size: ${theme.fontSizes.xs};
  color: ${theme.colors.text.primary};
  text-align: center;
  margin-bottom: ${theme.spacing.xs};
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RobotStats = styled.div`
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
`;

// Completely separate the menu into its own component for better rendering control
const ActionMenu = styled.div<{ visible: boolean; top: number; left: number }>`
  position: fixed;
  display: ${props => props.visible ? 'flex' : 'none'};
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  background-color: ${theme.colors.background.secondary};
  border-radius: ${theme.borderRadius.md};
  padding: ${theme.spacing.sm};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  transform: translate(-50%, -120%);
  pointer-events: ${props => props.visible ? 'auto' : 'none'};
`;

const ActionButton = styled.button<{ actionType: 'attack' | 'defend' | 'heal' }>`
  background-color: ${props => {
    switch (props.actionType) {
      case 'attack': return theme.colors.danger;
      case 'defend': return theme.colors.secondary;
      case 'heal': return theme.colors.success;
    }
  }};
  color: ${theme.colors.text.primary};
  border: none;
  border-radius: ${theme.borderRadius.sm};
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  margin: 0 ${theme.spacing.xs};
  cursor: pointer;
  font-size: ${theme.fontSizes.xs};
  transition: background-color 0.2s;
  
  &:hover {
    filter: brightness(1.1);
  }
  
  &:active {
    transform: scale(0.98);
  }
`;

// Removed action lines

export const BattleArena: React.FC<BattleArenaProps> = memo(({
  playerRobots,
  opponentRobots,
  onAction
}) => {
  // Filter out defeated robots (those with 0 or less HP)
  const activePlayerRobots = playerRobots.filter(robot => robot.armor > 0);
  const activeOpponentRobots = opponentRobots.filter(robot => robot.armor > 0);
  
  // Keep defeated robots but mark them for special rendering
  const defeatedPlayerRobots = playerRobots.filter(robot => robot.armor <= 0);
  const defeatedOpponentRobots = opponentRobots.filter(robot => robot.armor <= 0);
  
  console.log('BattleArena rendering with active robots:', 
    activePlayerRobots.length, 'player,', 
    activeOpponentRobots.length, 'opponent');
  
  // Use refs for values that shouldn't trigger re-renders
  const stateRef = useRef({
    draggingRobotId: null as string | null,
    targetRobotId: null as string | null,
    showActionMenu: false,
    actionMenuPosition: { top: 0, left: 0 },
    selectedSource: null as string | null,
    selectedTarget: null as string | null,
    touchStart: null as { x: number, y: number } | null
  });
  
  // Minimized state that actually affects rendering
  const [uiState, setUiState] = useState({
    showActionMenu: false,
    actionMenuPosition: { top: 0, left: 0 },
    selectedSource: null as string | null,
    selectedTarget: null as string | null
  });
  
  // Refs for robot elements
  const robotRefs = useRef<{[key: string]: HTMLDivElement | null}>({});
  
  // Remove drag behaviors for simplicity - we're using click-based selection only
  const handleDragStart = (robotId: string, event: React.MouseEvent | React.TouchEvent) => {
    // Prevent default event behavior
    event.preventDefault();
    event.stopPropagation();
    
    // No-op - we're not using drag functionality anymore
    // This is just left as a stub to avoid changing all the component props
  };
  
  // Removed drag move functionality - we're using click-based selection only
  const handleDragMove = (event: MouseEvent | TouchEvent) => {
    // No-op - not using drag functionality anymore
  };
  
  // Removed drag end functionality - we're using click-based selection only
  const handleDragEnd = (event: MouseEvent | TouchEvent) => {
    // No-op - not using drag functionality anymore
  };
  
  // Handle action selection - now we store the action type and wait for target selection
  const handleSelectAction = useCallback((type: 'attack' | 'defend' | 'heal') => {
    if (uiState.selectedSource) {
      // Store the selected action type
      actionType.current = type;
      
      // Update ref
      stateRef.current.showActionMenu = false;
      
      // Update UI state (single update)
      setUiState(prev => ({
        ...prev,
        showActionMenu: false
      }));
      
      // Show different instructions based on action type
      const targetText = type === 'attack' 
        ? 'Select an opponent robot to attack' 
        : `Select one of your robots to ${type}`;
      
      if ((window as any).showBattleSuccess) {
        (window as any).showBattleSuccess(targetText);
      }
    }
  }, [uiState]);
  
  // Handle click outside of action menu
  const handleClickOutside = useCallback((event: React.MouseEvent) => {
    // Check if the click is on an action button or a robot
    const target = event.target as HTMLElement;
    const isActionButton = target.closest('[data-action-button]');
    const isRobot = target.closest('[data-robot-id]');
    
    // Only close menu if clicking outside both menu and robots
    if (uiState.showActionMenu && !isActionButton && !isRobot) {
      // Update ref
      stateRef.current.showActionMenu = false;
      stateRef.current.selectedSource = null;
      stateRef.current.selectedTarget = null;
      
      // Single update for UI
      setUiState({
        showActionMenu: false,
        actionMenuPosition: stateRef.current.actionMenuPosition,
        selectedSource: null,
        selectedTarget: null
      });
    }
  }, [uiState]);
  
  // Robot selection and action phases - each robot can do one action per turn
  // Phase 1: Select a source robot (player's robot)
  const selectSourceRobot = useCallback((robotId: string) => {
    // Update ref first
    stateRef.current.selectedSource = robotId;
    stateRef.current.selectedTarget = null;
    stateRef.current.showActionMenu = true;
    
    // Position menu above the selected robot
    const sourceRect = robotRefs.current[robotId]?.getBoundingClientRect();
    if (sourceRect) {
      stateRef.current.actionMenuPosition = { 
        top: sourceRect.top - 10, 
        left: sourceRect.left + sourceRect.width/2 
      };
    }
    
    // Only one state update needed for UI rendering
    setUiState({
      showActionMenu: true,
      actionMenuPosition: stateRef.current.actionMenuPosition,
      selectedSource: robotId,
      selectedTarget: null
    });
  }, [robotRefs]);
  
  // Phase 2: After action type is selected, player selects a target robot
  const selectTargetRobot = useCallback((robotId: string, isPlayerRobot: boolean) => {
    // Only allow selecting a target if we have a source and action type
    if (!uiState.selectedSource || !actionType.current) return;
    
    // For attack, only allow targeting opponent robots
    // For heal and defend, only allow targeting player robots
    const isValidTarget = (actionType.current === 'attack' && !isPlayerRobot) || 
                          ((actionType.current === 'heal' || actionType.current === 'defend') && isPlayerRobot);
    
    if (!isValidTarget) {
      // Show a quick error message (if available from the parent component)
      if ((window as any).showBattleError) {
        (window as any).showBattleError(actionType.current === 'attack' 
          ? 'You can only attack opponent robots!' 
          : `You can only ${actionType.current} your own robots!`);
      }
      return;
    }
    
    // Update ref and state
    stateRef.current.selectedTarget = robotId;
    
    // Update UI state (for rendering)
    setUiState(prev => ({
      ...prev,
      selectedTarget: robotId
    }));
    
    // After target is selected, execute the action
    if (uiState.selectedSource && robotId) {
      onAction(uiState.selectedSource, robotId, actionType.current);
      
      // Reset for next turn after a short delay
      setTimeout(() => {
        // Update refs
        stateRef.current.selectedSource = null;
        stateRef.current.selectedTarget = null;
        stateRef.current.showActionMenu = false;
        actionType.current = null;
        
        // Update UI state (single update)
        setUiState({
          showActionMenu: false,
          actionMenuPosition: stateRef.current.actionMenuPosition,
          selectedSource: null,
          selectedTarget: null
        });
      }, 500);
    }
  }, [uiState, onAction]);
  
  // Store the current action type to use when target is selected
  const actionType = useRef<'attack' | 'defend' | 'heal' | null>(null);
  
  // Handle click on any robot - main entry point that routes to appropriate handler
  const handleRobotClick = useCallback((robotId: string, isPlayerRobot: boolean) => {
    // If we have a source selected and an action type, check if this is a valid target
    if (uiState.selectedSource && actionType.current) {
      selectTargetRobot(robotId, isPlayerRobot);
      return;
    }
    
    // If no robot is selected yet, and this is a player robot, select it as source
    if (!uiState.selectedSource && isPlayerRobot) {
      selectSourceRobot(robotId);
      return;
    }
    
    // If clicking the same robot, deselect
    if (uiState.selectedSource === robotId) {
      // Update ref
      stateRef.current.selectedSource = null;
      stateRef.current.showActionMenu = false;
      actionType.current = null;
      
      // Single update for UI
      setUiState({
        showActionMenu: false,
        actionMenuPosition: stateRef.current.actionMenuPosition,
        selectedSource: null,
        selectedTarget: null
      });
      return;
    }
    
    // If a different player robot is clicked and no action is in progress, switch to it
    if (isPlayerRobot && uiState.selectedSource !== robotId && !actionType.current) {
      selectSourceRobot(robotId);
    }
  }, [uiState, selectSourceRobot, selectTargetRobot]);
  
  // Create empty robot slots for missing robots - now memoized
  const getPlayerRobotsWithEmptySlots = useCallback(() => {
    const result = [...playerRobots];
    
    // Add empty slots if needed (up to 3 total)
    while (result.length < 3) {
      result.push({
        id: `empty-slot-${result.length}`,
        name: "Empty Slot",
        type: "none",
        level: 0,
        energy: 0,
        maxEnergy: 100,
        armor: 0,
        maxArmor: 100,
        characteristics: {
          power: 0,
          speed: 0,
          durability: 0,
          memory: 0
        },
        slots: []
      });
    }
    
    return result;
  }, [playerRobots]);
  
  // Use memoization to prevent unnecessary re-renders
  const MemoizedRobot = useCallback(({
    robot, 
    isEmpty, 
    isSelected
  }: {
    robot: Robot, 
    isEmpty: boolean, 
    isSelected: boolean
  }) => {
    // Determine if this robot is already used this turn
    const hasAction = uiState.selectedSource && uiState.selectedSource !== robot.id && actionType.current;
    
    // Determine if this robot is targetable for heal/defend
    const isTargetable = uiState.selectedSource && 
                         uiState.selectedSource !== robot.id && 
                         (actionType.current === 'heal' || actionType.current === 'defend') &&
                         !isEmpty;
    
    return (
      <RobotWrapper
        key={robot.id}
        ref={el => robotRefs.current[robot.id] = el}
        data-robot-id={robot.id}
        isDragging={false}
        isTarget={!!(isSelected || isTargetable)}
        level={robot.level}
        isEmpty={isEmpty}
        onClick={() => !isEmpty && handleRobotClick(robot.id, true)}
        style={{
          cursor: isEmpty ? 'default' : isTargetable ? 'pointer' : hasAction ? 'default' : 'pointer',
          opacity: hasAction && !isTargetable ? 0.6 : 1,
          position: 'relative',
          outline: isTargetable ? '2px dashed rgba(0, 255, 0, 0.5)' : 'none'
        }}
      >
        {isSelected && (
          <div style={{
            position: 'absolute',
            top: '-15px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'rgba(0,0,0,0.7)',
            color: 'white',
            padding: '2px 8px',
            borderRadius: '10px',
            fontSize: '10px',
            whiteSpace: 'nowrap'
          }}>
            {actionType.current ? `${actionType.current}ing...` : 'Selected'}
          </div>
        )}
        <RobotStats>
          {!isEmpty && (
            <>
              {/* Energy Bar */}
              <div style={{ paddingBottom: '2px' }}>
                <HealthBar 
                  currentHealth={robot.energy} 
                  maxHealth={robot.maxEnergy} 
                  size="tiny"
                  color="#3498db" // Blue for energy
                />
              </div>
              
              {/* Armor/Health Bar */}
              <HealthBar 
                currentHealth={robot.armor} 
                maxHealth={robot.maxArmor} 
                size="tiny"
                color="#e74c3c" // Red for armor/health
              />
            </>
          )}
          {/* Added small padding after health bars */}
          <div style={{ height: '4px' }} />
        </RobotStats>
        <RobotImage robotType={robot.type} isEmpty={isEmpty} />
        <RobotName>{robot.name}</RobotName>
        {!isEmpty && <RobotStatus>{robot.armor}/{robot.maxArmor} HP</RobotStatus>}
      </RobotWrapper>
    );
  }, [handleRobotClick, robotRefs, uiState, actionType]);
  
  // The actual render
  return (
    <ArenaContainer onClick={handleClickOutside}>
      {/* Section label for opponent robots */}
      <div style={{ 
        textAlign: 'center', 
        fontSize: '11px', 
        fontWeight: 'bold',
        opacity: 0.7,
        marginBottom: '4px',
        textTransform: 'uppercase',
        paddingTop: '10px'
      }}>
        Opponent's Robots
      </div>
      
      {/* Opponent robots at top - targetable only for attack */}
      <RobotContainer isPlayer={false}>
        {/* Show active opponent robots first */}
        {activeOpponentRobots.map(robot => {
          const isTarget = uiState.selectedTarget === robot.id;
          // Only show as targetable for attack action
          const isTargetable = uiState.selectedSource && actionType.current === 'attack';
          
          return (
            <RobotWrapper
              key={robot.id}
              ref={el => robotRefs.current[robot.id] = el}
              data-robot-id={robot.id}
              isDragging={false}
              isTarget={isTarget}
              level={robot.level}
              onClick={() => handleRobotClick(robot.id, false)}
              style={{
                cursor: isTargetable ? 'pointer' : 'default',
                opacity: !isTargetable && uiState.selectedSource ? 0.6 : 1,
                outline: isTargetable ? '2px dashed rgba(255, 0, 0, 0.5)' : 'none'
              }}
            >
              <RobotStats>
                {/* Energy Bar */}
                <div style={{ paddingBottom: '2px' }}>
                  <HealthBar 
                    currentHealth={robot.energy} 
                    maxHealth={robot.maxEnergy} 
                    size="tiny"
                    color="#3498db" // Blue for energy
                  />
                </div>
                
                {/* Armor/Health Bar */}
                <HealthBar 
                  currentHealth={robot.armor} 
                  maxHealth={robot.maxArmor} 
                  size="tiny"
                  color="#e74c3c" // Red for armor/health
                />
                {/* Added small padding after health bars */}
                <div style={{ height: '4px' }} />
              </RobotStats>
              <RobotImage robotType={robot.type} isEmpty={false} />
              <RobotName>{robot.name}</RobotName>
              <RobotStatus>{robot.armor}/{robot.maxArmor} HP</RobotStatus>
            </RobotWrapper>
          );
        })}
        
        {/* Show defeated opponent robots with visual indication */}
        {defeatedOpponentRobots.map(robot => (
          <DefeatedRobotWrapper
            key={`defeated-${robot.id}`}
            ref={el => robotRefs.current[robot.id] = el}
            data-robot-id={robot.id}
            isDragging={false}
            isTarget={false}
            level={robot.level}
            style={{
              cursor: 'default',
              opacity: 0.3,
              transform: 'rotate(90deg) scale(0.8)',
              filter: 'grayscale(100%)'
            }}
          >
            <RobotStats>
              {/* Energy Bar at zero */}
              <div style={{ paddingBottom: '2px' }}>
                <HealthBar 
                  currentHealth={0} 
                  maxHealth={robot.maxEnergy} 
                  size="tiny"
                  color="#3498db" // Blue for energy
                />
              </div>
              
              {/* Armor/Health Bar at zero */}
              <HealthBar 
                currentHealth={0} 
                maxHealth={robot.maxArmor} 
                size="tiny"
                color="#e74c3c" // Red for armor/health
              />
              {/* Added small padding after health bars */}
              <div style={{ height: '4px' }} />
            </RobotStats>
            <RobotImage robotType={robot.type} isEmpty={false} />
            <RobotName>{robot.name} (Defeated)</RobotName>
            <RobotStatus>0/{robot.maxArmor} HP</RobotStatus>
          </DefeatedRobotWrapper>
        ))}
      </RobotContainer>
      
      {/* Player robots at bottom */}
      <RobotContainer isPlayer={true}>
        {/* Display active player robots first */}
        {activePlayerRobots.map(robot => {
          const isSelected = uiState.selectedSource === robot.id;
          
          return (
            <MemoizedRobot 
              key={robot.id}
              robot={robot}
              isEmpty={false}
              isSelected={isSelected}
            />
          );
        })}
        
        {/* Display defeated player robots */}
        {defeatedPlayerRobots.map(robot => (
          <DefeatedRobotWrapper
            key={`defeated-${robot.id}`}
            ref={el => robotRefs.current[robot.id] = el}
            data-robot-id={robot.id}
            isDragging={false}
            isTarget={false}
            level={robot.level}
            style={{
              cursor: 'default',
              opacity: 0.3,
              transform: 'rotate(90deg) scale(0.8)',
              filter: 'grayscale(100%)'
            }}
          >
            <RobotStats>
              {/* Energy Bar at zero */}
              <div style={{ paddingBottom: '2px' }}>
                <HealthBar 
                  currentHealth={0} 
                  maxHealth={robot.maxEnergy} 
                  size="tiny"
                  color="#3498db" // Blue for energy
                />
              </div>
              
              {/* Armor/Health Bar at zero */}
              <HealthBar 
                currentHealth={0} 
                maxHealth={robot.maxArmor} 
                size="tiny"
                color="#e74c3c" // Red for armor/health
              />
              {/* Added small padding after health bars */}
              <div style={{ height: '4px' }} />
            </RobotStats>
            <RobotImage robotType={robot.type} isEmpty={false} />
            <RobotName>{robot.name} (Defeated)</RobotName>
            <RobotStatus>0/{robot.maxArmor} HP</RobotStatus>
          </DefeatedRobotWrapper>
        ))}
        
        {/* Add empty slots if needed */}
        {Array.from({ length: Math.max(0, 3 - playerRobots.length) }).map((_, i) => {
          const emptyRobot = {
            id: `empty-slot-${i}`,
            name: "Empty Slot",
            type: "none",
            level: 0,
            energy: 0,
            maxEnergy: 100,
            armor: 0,
            maxArmor: 100,
            characteristics: {
              power: 0,
              speed: 0,
              durability: 0,
              memory: 0
            },
            slots: []
          };
          
          return (
            <MemoizedRobot 
              key={emptyRobot.id}
              robot={emptyRobot}
              isEmpty={true}
              isSelected={false}
            />
          );
        })}
        {/* Section label for player robots */}
        <div style={{
          textAlign: 'center',
          fontSize: '11px',
          fontWeight: 'bold',
          opacity: 0.7,
          marginTop: '8px',
          marginBottom: '4px',
          textTransform: 'uppercase',
          position: 'absolute',
          bottom: '-15px'
        }}>
          My Robots
        </div>
      </RobotContainer>
      
      {/* Action menu - appears above selected robot */}
      <ActionMenu 
        visible={uiState.showActionMenu} 
        top={uiState.actionMenuPosition.top} 
        left={uiState.actionMenuPosition.left}
      >
        <ActionButton 
          actionType="attack" 
          onClick={() => handleSelectAction('attack')}
          data-action-button
        >
          Attack
        </ActionButton>
        <ActionButton 
          actionType="defend" 
          onClick={() => handleSelectAction('defend')}
          data-action-button
        >
          Defend
        </ActionButton>
        <ActionButton 
          actionType="heal" 
          onClick={() => handleSelectAction('heal')}
          data-action-button
        >
          Heal
        </ActionButton>
      </ActionMenu>
    </ArenaContainer>
  );
});

export default memo(BattleArena);