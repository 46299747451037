import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { api } from '../services/api';
import { useBattleContext } from '../context/BattleContext';

export const useCheckBattle = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { inBattle, setInBattle, activeBattleId, setActiveBattleId } = useBattleContext();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Add a reference to prevent infinite loading
  const initialCheckDone = useRef(false);
  const maxRetryAttempts = useRef(3);

  // Retry logic wrapper for API calls
  const fetchWithRetry = useCallback(async (apiCall: () => Promise<any>, maxRetries = 3, backoff = 500) => {
    let attempt = 0;
    
    while (attempt < maxRetries) {
      try {
        const result = await apiCall();
        return result;
      } catch (error) {
        attempt++;
        console.error(`Attempt ${attempt} failed:`, error);
        
        if (attempt >= maxRetries) {
          throw error;
        }
        
        // Exponential backoff before retry
        // eslint-disable-next-line no-loop-func
        await new Promise(resolve => setTimeout(resolve, backoff * Math.pow(2, attempt)));
      }
    }
  }, []);
  
  const checkBattle = useCallback(async () => {
    // Only set isLoading for initial check
    if (!initialCheckDone.current) {
      setIsLoading(true);
    }

    try {
      const response = await fetchWithRetry(() => api.checkActiveBattle());
      
      if (response.data) {
        const { isInBattle, battleId } = response.data;
        
        setInBattle(isInBattle);
        if (battleId) {
          setActiveBattleId(battleId);
          
          // Auto-redirect to battle if in one and not already there
          if (isInBattle && !location.pathname.startsWith(`/new-battle/${battleId}`)) {
            console.log(`Redirecting to active battle: ${battleId}`);
            navigate(`/new-battle/${battleId}`, { replace: true });
          }
        } else {
          setActiveBattleId(null);
        }
        
        return { isInBattle, battleId };
      } else if (response.error) {
        setError(response.error);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Unknown error');
    } finally {
      // Only manage loading state for initial check
      if (!initialCheckDone.current) {
        setIsLoading(false);
        initialCheckDone.current = true;
      }
    }
    
    return { isInBattle: false, battleId: null };
  }, [fetchWithRetry, setInBattle, setActiveBattleId, navigate, location.pathname]);
  
  // Track if we've already done session checking via another method
  const sessionChecked = useRef(false);

  // Add visibility tracking to avoid polling when app is in background
  const [isTabVisible, setIsTabVisible] = useState(true);

  // Setup visibility change listener
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabVisible(!document.hidden);
    };

    // Add visibility change listener
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    // Clean up event listener
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    // Skip initial check if session was already checked by BattleContext
    if (inBattle !== undefined && activeBattleId !== undefined) {
      sessionChecked.current = true;
      initialCheckDone.current = true;
      setIsLoading(false);
      return;
    }
    
    // Initial check - only do it once when the app is loaded
    const initialCheck = async () => {
      try {
        await checkBattle();
      } catch (error) {
        console.error("Initial battle check failed:", error);
      } finally {
        // Even if there's an error, mark initial check as done to prevent being stuck on loading screen
        initialCheckDone.current = true;
        sessionChecked.current = true;
        setIsLoading(false);
      }
    };
    
    if (!sessionChecked.current) {
      initialCheck();
    }
    
    // We only check when the tab becomes visible after being hidden
    // This handles the case when a user returns to the app
    const handleVisibilityChange = () => {
      if (isTabVisible && !document.hidden && initialCheckDone.current) {
        // User has returned to the app after it was in background
        checkBattle().catch(error => {
          console.error("Battle check failed on visibility change:", error);
        });
      }
    };
    
    // Add listener for tab visibility changes to check battle status when user returns
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [checkBattle, inBattle, activeBattleId, isTabVisible]);

  return { isLoading, error, inBattle, activeBattleId };
};

export default useCheckBattle;